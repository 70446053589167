import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Form, Message, Upload, Loading, Select} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList, setProjectList} from "../actions";
import Facade from "../Facade";
import runRefreshProjectList from "../controllers/runRefreshProjectList";
import UserSelect from "./UserSelect";
import {EDIT_TASK_TYPE_managerUserList, EDIT_TASK_TYPE_taskUserList} from "../consts";
import "./ProjectMemberEdit.css";
import runSearchAllUsers from "../controllers/runSearchAllUsers";

const {Row, Col} = Layout;

class ProjectMemberEdit extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {isLoading: false, isAdding: false,hasEditPermission:false};
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    let {mineId}=nextProps;
    let {projectUser}=prevState;
    let hasEditPermission = projectUser&& mineId == projectUser.id;
    return {
      hasEditPermission
    }
  }

  componentDidMount()
  {
    runSearchAllUsers(this.props.dispatch);
    this.refreshList();
  }

  render()
  {
    let {isLoading, projectUser, dailyUser, memberUserList, isAdding, isEditDaily , hasEditPermission} = this.state;
    let {allUsersList} = this.props;
    return (
      <div className="ProjectMemberEdit">
        <Loading loading={isLoading}>

          {this.renderProjectRow()}
          {this.renderDailyRow()}
          {memberUserList && memberUserList.map((li) => {
            return (<div className={"row"} key={"mem-"+li.id}><ProjectMemberLi key={li.id} removableCb={hasEditPermission?this.onReqDel.bind(this,li.id):null} user={li}/></div>)
          })}

          {hasEditPermission ? (
            isAdding ? (
              <ProjectMemberSelect onSelect={this.onUserAddSelected.bind(this)} options={allUsersList}/>
            ) : (
              <div className="createBtn" onClick={this.onAddLi.bind(this)}>
                添加成员 <img src={require("../res/1026/周报/2.0项目/2_add.png")} className="createBtnIcon"/>
              </div>
            )
          ) :null}
        </Loading>
      </div>);
  }

  renderProjectRow()
  {
    let {isLoading, projectUser, dailyUser, memberUserList, isAdding, isEditProject,hasEditPermission} = this.state;
    if (!dailyUser) dailyUser = [];
    if (hasEditPermission && isEditProject)
    {
      return (<div className={"row"}>
        <UserSelect single={true} list={ [projectUser]} onChange={this.onUserChange.bind(this, "project")}/>
        <Button size={"small"} style={{marginLeft: "3px"}} type={"primary"}
                onClick={()=>{
                  this.setState({isEditProject:false});
                }}>完成</Button>
      </div>)
    } else
    {
      return (<div className={"row"} onClick={hasEditPermission?()=>{
        this.setState({isEditProject:true});
      }:null}>
        {projectUser && <ProjectMemberLi user={projectUser}/>}
        <div className="tag-project">项目负责人</div>
      </div>);
    }
  }

  renderDailyRow()
  {
    let {isLoading, projectUser, dailyUser, memberUserList, isAdding, isEditDaily,hasEditPermission} = this.state;
    if (!dailyUser) dailyUser = [];
    if (hasEditPermission && isEditDaily)
    {
      return (<div className={"row"}>
        <UserSelect list={dailyUser || []} onChange={this.onUserChange.bind(this, "daily")}/>
        <Button size={"small"} style={{marginLeft: "3px"}} type={"primary"}
                onClick={this.onEditSubmit.bind(this)}>完成</Button>
      </div>)
    } else
    {
      return (<div className={"row"} onClick={hasEditPermission?this.onReqEditDaily.bind(this):null}>
        {dailyUser.map((li) => {
          return <ProjectMemberLi user={li} key={li.id}/>
        })}
        <div className="tag-daily">日报负责人</div>
      </div>);
    }
  }

  onReqEditDaily()
  {
    this.setState({isEditDaily: true});
  }

  /**
   * 修改负责人
   * @param type
   * @param uids
   */
  onUserChange(type, uids)
  {
    let {projectId} = this.props;
    let {isLoading, projectUser, dailyUser, memberUserList, isAdding, isEditDaily} = this.state;
    if ( type == "daily")
    {
      this.setState({isLoading: true});
      Facade.gw.editProjectDailyUsers(projectId,JSON.stringify(uids), ()=>{
        this.refreshList();
      });
    }else if (type =="project"){

      this.setState({isLoading: true});
      Facade.gw.editProjectUser(projectId,uids, ()=>{
        this.refreshList();
      });
    }
  }

  onEditSubmit()
  {
    this.setState({isEditDaily: false});

  }

  onAddLi()
  {
    this.setState({isAdding: true});
  }

  /** 修改成员增加 */
  onUserAddSelected(uid)
  {
    //提交服务器
    let {projectUser, dailyUser, memberUserList, memberUids} = this.state;
    let {projectId} = this.props;
    memberUids.push(uid);
    this.setState({isLoading: true});
    Facade.gw.editProjectMembers(projectId, JSON.stringify(memberUids), () => {
      this.setState({isAdding: false});
      this.refreshList();

    });
  }

  /** 修改成员删除 */
  onReqDel(uid)
  {
    //提交服务器

    let {projectUser, dailyUser, memberUserList, memberUids} = this.state;
    let {projectId} = this.props;
    memberUids=memberUids.filter((li)=>{
      return li!=uid;
    });
    this.setState({isLoading: true});
    Facade.gw.editProjectMembers(projectId, JSON.stringify(memberUids), () => {
      this.setState({isAdding: false});
      this.refreshList();

    });
  }

  refreshList()
  {
    this.setState({isLoading: true});
    let {projectId} = this.props;
    Facade.gw.getProjectMembers(projectId, (data) => {
      let {projectUser, dailyUser, memberUserList} = data;
      let memberUids = memberUserList.map(li => li.id);
      this.setState({projectUser, dailyUser, memberUserList, memberUids , isLoading: false});
    });
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    projectList: state.projects.projectList,
    nickName: state.login.nickName,
    avatarUrl: state.login.avatarUrl,
    mineId: state.login.id,
    allUsersList: state.users.searchedListAll
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ProjectMemberEdit);


class ProjectMemberLi extends Component
{
  static propTypes = {
    projectOwner: PropTypes.bool,
    dailyOwner: PropTypes.bool,
    removableCb: PropTypes.func,
    user: PropTypes.any
  }

  componentDidMount()
  {
  }

  render()
  {
    let {removableCb, projectOwner, dailyOwner, user} = this.props;
    let {avatarUrl, nickName, id} = user;
    return [
      <div className="ProjectMemberLi" key={"member-"+id}>
        <div className="head">
          <img src={avatarUrl} alt=""/>
        </div>
        <div className="name">{nickName}</div>
      </div>,
      removableCb!=null ? <div key={"member-del-"+id} className="actionBtn" onClick={removableCb}>移除</div> : null
  ];
  }

}

class ProjectMemberSelect extends Component
{
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    options : PropTypes.array.isRequired
  }
  state = {  value: 0};

  componentDidMount()
  {
  }

  render()
  {
    let {onSelect,options} = this.props;
    let { value} = this.state;
    return (
      <div className="ProjectMemberLi">
        <Select
          className="Select"
          placeholder="键入以搜索"
          value={value}
          multiple={false} filterable={true}

          onChange={onSelect}>
          {
            options.map(el => {
              return <Select.Option key={el.id} label={el.nickName} value={el.id}/>
            })
          }
        </Select>

      </div>);
  }



}