
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  DatePicker, MessageBox, Message
} from "element-react";
import 'element-theme-default';
import {
  loginRecovery, openProjectId, SET_WEEK_REPORT_LIST,
  setAdminList, setWeekReportList, setWeekReportListFilter, setWeekReportTestData
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import Utils from "../Utils";
import QuickTimeTf from "../components/QuickTimeTf";
import {WORK_DATE_NATURE} from "../consts";
import PageWrapper from "./PageWrapper";


const {Row,Col} =Layout;
class WeekPage extends Component
{
  constructor(props)
  {
    super(props);
    this.state={
      dialogVisible2: false,
      recentColumns: [
        {label: "周报", prop: "weeklyName"},
        {label: "所属项目", prop: "projectName"},
        {label: "最近打开", prop: "openTime"},
        {label: "",  width:80, render: this.renderActions.bind(this)}

      ]
    };
  }

  renderActions(row){

    let {hoverRow} = this.state;
    if (hoverRow==row)
    {
      return (
        <span>
          <Button type="text" size="small" onClick={this.onClickDelete.bind(this, row)}><i
            className={"fa fa-minus-circle"}/> 移除</Button>
        </span>
      )
    }
  }
  onClickDelete(row,e){
    e.stopPropagation();
    MessageBox.confirm('此操作将删除该查看记录, 是否继续?', '提示', {
      type: 'warning'
    }).then(() => {
      Facade.gw.openDelete(row.id ,(data)=>{
        Message({
          type: 'success',
          message: '移除成功!'
        });
        this.refreshData();
      });
    }).catch(() => {
    });

  }

  componentDidMount()
  {
    this.refreshData();
  }


  refreshData()
  {
    this.setState({isLoading: true});
    //按最新筛选
    Facade.gw.getOpenList( (list)=>{
      this.setState({list , isLoading: false});
    } );


  }
  onCellMouseEnter(row, column, cell, event){
    this.setState({hoverRow : row });
  }
  onCellMouseLeave(row, column, cell, event){
    this.setState({hoverRow : null });
  }

  render(){
    let {isLoading,list} = this.state;
    //创建筛选器
    return (
      <PageWrapper activeNav={"1-week"} >
        <div className="WeekPage pageWithPadding">
          <div className="pageCard">

            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                  最近周报
                </div>
              </Col>
              <Col span={12}>

              </Col>
            </Row>
            <Row>
              <Loading loading={isLoading}>
                <Table
                  border={false}
                  style={{width: '100%', marginBottom: '20px'}}
                  columns={this.state.recentColumns}
                  onCellClick={(row, column, cell, event) => {
                    if (column.swallow)
                    {
                      if (event) event.stopPropagation();
                    }
                  }}
                  data={list}
                  stripe={false}
                  highlightCurrentRow={true}
                  onCurrentChange={this.onRowWeekChange.bind(this)}
                  onCellMouseEnter={ this.onCellMouseEnter.bind(this) }
                  onCellMouseLeave={ this.onCellMouseLeave.bind(this) }
                />
              </Loading>
            </Row>



          </div>
        </div>
      </PageWrapper>
        );
  }

  onRowWeekChange(currentRow, oldCurrentRow)
  {
    let {dispatch} = this.props;
    let li = currentRow;
    if (li)
    {
      Facade.customHistory.push(`/week-${li.weeklyId}`);
    }
  }


  static propTypes = {
    weekReportList: PropTypes.array.isRequired,
  }

}


const mapStateToProps=(state)=>
{
  return {
    weekReportList: state.weekReports.weekReportList,
    weekReportFilter: state.weekReports.weekReportFilter,
    weekReportDetail: state.weekReports.weekReportDetail,
    total: state.weekReports.total,
    page: state.weekReports.page,
    pageSize: state.weekReports.pageSize,

  };
};
export default connect(
  mapStateToProps
)(WeekPage);