import * as echarts from 'echarts';
import React, {Component} from 'react';import {Button, Layout, Input, Radio, Switch, Pagination, Loading, Dialog, Table, Checkbox} from "element-react";


import {connect} from "react-redux";
class ManpowerChart extends Component
{
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          label: "Type",
          prop: "name",
          width: 180
        },
        {
          label: "Status",
          prop: "status",
          width: 180
        },
        {
          label: "desc",
          prop: "desc"
        }
      ],
      data: [{
        status :"OK",
        name: 'Risk',
        desc: '03G 20-22/ 03G 25 tenants  have  move in site and start construction.  which move in site in advance for construction before whole 3F renovation area\'s five party acceptance, may affect the acceptance of the public area or the Construction Committee to suspend construction of tenants.\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\n'
      },{
        status :"Delay",
        name: 'Schedule',
        desc: 'The schedule of FF. system , outdoor staircase, Major HVAC equipment  move in have delay, There are potential risk leading to fire acceptance and five party aceptance dealy.'
      },{
        status :"OK",
        name: 'Procurement',
        desc: 'The GC has on board by 12nd Jan. All consultants / contractors of this project have been completed.'
      }
      ]
    }
  }


  render() {
    return (
      <Table
        style={{width: '100%'}}
        columns={this.state.columns}
        rowClassName={this.rowClassName.bind(this)}
        data={this.state.data}
        stripe={false}
      />
    )
  }

  rowClassName(row, index) {
    if (index === 1) {
      return 'info-row';
    } else if (index === 3) {
      return 'positive-row';
    }

    return '';
  }




}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ManpowerChart);