import React, {Component} from 'react';
import "./StepNum.css";
import Utils from "../Utils";


export default class StepNum extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {str: ""};
  }

  componentDidMount()
  {
    let {from, to, prefix, postfix, totalFrame} = this.props;
    this.animInit(from,to,prefix,postfix,totalFrame);
  }
  componentWillUnmount()
  {
    this.disposed=true;
  }


  animInit(from,to,prefix,postfix,totalFrame )
  {
    this.from = from;
    this.to = to;
    this.currentFrame = 0;
    this.totalFrame = totalFrame;
    this.prefix = prefix;
    this.postfix = postfix;

    window.requestAnimationFrame(this.animUpdate.bind(this));
  };

  animUpdate()
  {
    if ( this.disposed){return;}
    this.currentFrame++;
    if (this.currentFrame > this.totalFrame)
    {
      this.currentFrame = this.totalFrame;
    } else
    {
      var step = (this.to - this.from) / this.totalFrame;
      var currentValue = this.from + step * this.currentFrame;
      let str = this.prefix + Utils.formatCashStr(currentValue) + this.postfix;
      this.setState({str})
      window.requestAnimationFrame(this.animUpdate.bind(this));
    }
  }

  render()
  {
    return (
      <span className="StepNum">{this.state.str}</span>);
  }

}