import {LOGIN_LOGIN } from "../actions";

export default function login(state = {
  id: 0, nickName: "",avatarUrl:""
}, action) {
  switch (action.type)
  {
    case LOGIN_LOGIN:
      let { id , nickName,avatarUrl} = action;
      return {...state,id ,nickName,avatarUrl };
  }
  return state;
}