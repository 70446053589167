import Facade from "./Facade";
import {Base64} from "js-base64";
import {CLIENT_WWW, SERVER_API} from "./consts";

export default class OAuthLoginUtil
{

  constructor()
  {
    this.recoveryLoginIfExist();
  }

  /** 客户端恢复已有会话 OAuth从本地存储取出token并写入内存gw
   * 此处检测如果没有的话也不做处理；即无登录状态；
   * 后续请求到敏感接口返回必须登录的时候跳登录 */
  recoveryLoginIfExist()
  {
    console.log("OAuthLoginUtil/recoveryLogin");
    //登录状态
    if (!Facade.gw.token)
    {
      Facade.gw.token = localStorage.getItem("token");
    }
    //
  }

  /** OAuth成功后写入本地存储 ，以及写入内存gw */
  loginSucc(token)
  {
    // console.log("OAuthLoginUtil/loginSucc",token);
    // token=Base64.encode( token );
    localStorage.setItem("token", token);
    Facade.gw.token = token;
    //
    let recoveryUrl=localStorage.getItem("expiredUrlOneShot");
    // localStorage.removeItem("expiredUrlOneShot");
    if ( recoveryUrl )
    {
      window.location.href= recoveryUrl;
    }else{
      Facade.customHistory.replace("/home");
    }
  }

  gotoOAuth()
  {
    if ( this.isGone) return;
    localStorage.clear();
    localStorage.setItem("expiredUrlOneShot",document.location);
    this.isGone=true;
    if ( Facade.larkSdkUtil.isInLark)
    {
      // console.log("OAuthLoginUtil/gotoOAuth inLark expiredUrlOneShot=",    localStorage.getItem("expiredUrlOneShot") );
      Facade.customHistory.replace("/larklogin");
    }else{
      this.gotoOAuthLogin();
    }
  }

  logout(){
    localStorage.clear();
    Facade.gw.token = "";
  }



  //region oauth登录
  /** 跳转oauth */
  gotoOAuthLogin(){
    // let redirect = encodeURIComponent(CLIENT_WWW+"/#login");
    let redirect = encodeURIComponent(CLIENT_WWW);
    let entryUrl=SERVER_API+"/login/feishuOauth?redirect="+redirect;
    // console.log("Gateway/gotoLoginEntry",entryUrl);
    // if(DEBUG)
    // {
    //   alert(entryUrl);
    // }
    window.location.href=  entryUrl;
  }
  isGone=false;

  //endregion
}