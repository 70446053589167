import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Tabs} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import runRefreshTasks from "../controllers/runRefreshTasks";

const {Row, Col} = Layout;

class WeekTasksTabBar extends Component
{
  static propTypes = {
    weeklyId: PropTypes.number.isRequired,
  }
  constructor(props)
  {
    super(props)
    this.state = {};
  }

  componentDidMount()
  {
  }

  render()
  {
    let { tasksFilter} = this.props;
    return (
      <div className="WeekTasksTabBar" style={{backgroundColor: "#fff"}}>

        <Tabs activeName={tasksFilter.type.toString()} onTabClick={this.onTabClick.bind(this)}>
          <Tabs.Pane label="我的任务" name="1"></Tabs.Pane>
          <Tabs.Pane label="全部任务" name="2"></Tabs.Pane>
        </Tabs>

      </div>);
  }

  onTabClick(tab){
    let type = tab.props.name;
    runRefreshTasks(this.props.weeklyId,type,this.props.dispatch);
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {tasksFilter: state.weekReportTasks.tasksFilter}
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(WeekTasksTabBar);