import Facade from "./Facade";
import {Base64} from "js-base64";
import {DEBUG, LARK_H5_APPID} from "./consts";
/** 飞书内 jssdk登录 */
export default class LarkSdkUtil
{

  isInLark = false;
  isSdkReady = false;
  readyRunQueue=[];

  constructor()
  {
    //测试是飞书还是网页
    if ( window.h5sdk)
    {
      if ( DEBUG && window.VConsole) var vConsole = new window.VConsole();
      //
      console.log("LarkSdkUtil/componentDidMount  在飞书环境 debug=",DEBUG);
      this.isInLark =true;
      this.initSdk();
    }else{
      console.log("LarkSdkUtil/componentDidMount 浏览器环境 debug=",DEBUG);
      this.isInLark = false;
    }
  }

  /** 初始化配置 对接飞书 */
  initSdk(){
    let {tt} = window;
/*
    // 调用config接口的当前网页url
    const url = encodeURIComponent(window.location.href.split("#")[0]);
    console.log("接入方前端将需要鉴权的url发给接入方服务端,url为:", url);
    // 向接入方服务端发起请求，获取鉴权参数（appId、timestamp、nonceStr、signature）
    fetch(`/get_config_parameters?url=${url}`)
      .then((response) =>
        response.json().then((res) => {
          console.log(
            "接入方服务端返回给接入方前端的结果(前端调用config接口的所需参数):", res
          );
          // 通过error接口处理API验证失败后的回调
          window.h5sdk.error((err) => {
            throw ("h5sdk error:", JSON.stringify(err));
          });
          // // 调用config接口进行鉴权
          // window.h5sdk.config({
          //   appId: res.appid,
          //   timestamp: res.timestamp,
          //   nonceStr: res.noncestr,
          //   signature: res.signature,
          //   jsApiList: [],
          //   //鉴权成功回调
          //   onSuccess: (res) => {
          //     console.log(`config success: ${JSON.stringify(res)}`);
          //   },
          //   //鉴权失败回调
          //   onFail: (err) => {
          //     throw `config failed: ${JSON.stringify(err)}`;
          //   },
          // });
          // 完成鉴权后，便可在 window.h5sdk.ready 里调用 JSAPI
          window.h5sdk.ready(this._onReady.bind(this));
        })
      )
      .catch(function (e) {
        console.error(e);
      });
*/
    // 直接 ready 目前不需要其他api的话就直接不走config
    window.h5sdk.ready(this._onReady.bind(this));

  }

  _onReady(){
    console.log("LarkSdkUtil/_onReady");
    this.isSdkReady=true;
    this.flushRunQueue();
  }

  loginLark(cbWithToken){
    let {tt}=window;
    // window.h5sdk.ready回调函数在环境准备就绪时触发
    tt.requestAuthCode({
      appId: LARK_H5_APPID,
      // 获取成功后的回调
      success(res) {
        console.log("getAuthCode succeed",res);
        //authorization code 存储在 res.code
        // 此处通过 fetch 把 code 传递给接入方服务端 Route: callback，并获得user_info
        // 服务端 Route: callback 的具体内容请参阅服务端模块 server.py 的callback() 函数
        // Facadw.gw.换取token，然后
        Facade.gw.loginByCode(res.code , ( token )=>{
          if ( !token )
          {
            Facade.gw.gwAlert(res.code,"服务端未返回token");
          }else{
            cbWithToken(token);
          }
        });
        // let token="085ceb16-106c-4a6f-a04a-011d40eb431e";
        // cbWithToken(token);
      },
      // 获取失败后的回调
      fail(err) {
        Facade.gw.gwAlert(JSON.stringify(err),"授权失败");
        console.log(`getAuthCode failed, err:`, JSON.stringify(err));
      }
    });
  }

  /** 在飞书sdk ready后调度，自动判定延迟调度或立即调度 */
  runAsap(cb){
    if ( this.isSdkReady )
    {
      cb ();
    }else{
      this.readyRunQueue.push(cb);
    }
  }

  flushRunQueue(){

    //
    let tmpQueue = this.readyRunQueue.map ((li)=>li);
    this.readyRunQueue=[];
    for (const readyRunQueueElement of tmpQueue)
    {
      readyRunQueueElement();
    }
  }
}