
import React, {Component} from 'react';
import {Menu, Icon, Dropdown, Message, MessageBox, Dialog} from "element-react";
import {connect} from "react-redux";
import Facade from "../Facade";
import {loginOut, setProjectList} from "../actions";
import TopSearch from "./TopSearch";
import "./NavMenu.css";
import runRefreshProjectList from "../controllers/runRefreshProjectList";
import {EDIT_TASK_TYPE_ADDNODE, VERSION} from "../consts";
import runRefreshTasks from "../controllers/runRefreshTasks";
import ProjectCreate from "./ProjectCreate";
import runRefreshMine from "../controllers/runRefreshMine";
import ProjectEdit from "./ProjectEdit";

class NavMenu extends Component
{

  constructor(props)
  {
    super(props)
    this.state = { dialogProj: false };
  }


  componentDidMount()
  {
    runRefreshProjectList(this.props.dispatch);
    runRefreshMine(this.props.dispatch);
  }

  render()
  {
    let {active} = this.props;
    // 如果传入了active ，则采用传入的 ，入dashboard页面等根页面
    // 如果没传active，基本上就是各种子页面，采用openedProjectId 计算
    // console.log("NavMenu/render",this.props);
    // let {token} = this.props;
    // if (!token)
    // {
    //   console.log(this.props);
    //   Facade.customHistory.push("/");
    //   return null;
    // }
    let projectList = this.props.projectList;
    return (
      <div className="NavMenu" >
        <div className="container">
          <div className="logo">
            <img src={require("../res/logo.png")}/>
          </div>

          <div className={"navMenuLi "+(active=="home"?" is-active":"")} index="home" onClick={this.onClickMenuLi.bind(this)}>
              <i className={"fa fa-home"}/> 首页<div className="deco" /></div>

          {/*<div className="navMenuGroup">菜单</div>*/}

          {/*  <div className={"navMenuLi "+(active=="1-dashboard2"?" is-active":"")} index="1-dashboard2" onClick={this.onClickMenuLi.bind(this)}><i className="fa fa-file-pdf-o"/> 周报总览<div className="deco" /></div>*/}
          {/*  <div className={"navMenuLi "+(active=="1-dashboard"?" is-active":"")} index="1-dashboard" onClick={this.onClickMenuLi.bind(this)}><i className="fa fa-bar-chart"/> 项目看板<div className="deco" /></div>*/}
          {/*  <div className={"navMenuLi "+(active=="1-week"?" is-active":"")} index="1-week" onClick={this.onClickMenuLi.bind(this)}><i className="fa fa-calendar-check-o"/> 最近周报<div className="deco" /></div>*/}


          <div className="navMenuGroup">项目
            <div className="navMenuGroupBtn" onClick={this.onClickAddProj.bind(this)}>
              <img src={require("../res/1026/周报/2.0/2_0_add.png")}/>
            </div>
          </div>
          <div className="scrollable">
            {projectList.map(this.renderProjectMenuItem.bind(this))}
          </div>


          <div className="pushbottom">

            <div index="9-archived" onClick={this.onClickMenuLi.bind(this)} className={"navMenuLi bottombtn1"+(active=="9-archived"?" is-active":"")}>已归档项目</div>
            <div index="9-trashcan" onClick={this.onClickMenuLi.bind(this)} className={"navMenuLi bottombtn1"+(active=="9-trashcan"?" is-active":"")}>回收站</div>

          </div>

          <div className="version">build {VERSION}</div>

        </div>

        <Dialog
          title="新建项目"
          visible={this.state.dialogProj}
          onCancel={() => {
            this.setState({dialogProj: false});
          }}
        >
          <Dialog.Body>
            {this.state.dialogProj && <ProjectCreate onFinish={(newId) => {
              this.setState({dialogProj: false});
              this.onClickProjectLi(newId);
            }}/>}
          </Dialog.Body>
        </Dialog>

        <Dialog
          title="编辑项目"
          visible={this.state.dialogProjEdit}
          onCancel={() => {
            this.setState({dialogProjEdit: false});
          }}
        >
          <Dialog.Body>
            {this.state.dialogProjEdit &&
              <ProjectEdit projectName={this.state.editProjectName} projectId={this.state.editProjectId}
                           onFinish={() => {
                             this.setState({dialogProjEdit: false});
                           }}/>
            }
          </Dialog.Body>
        </Dialog>
      </div>
    );
  }
  calcProjIndex(projectId){
      return "proj-" + projectId;
  }

  renderProjectMenuItem(projectVO)
  {
    let {active,openedProjectId,mineId} = this.props;
    // 如果没传active，基本上就是各种子页面，采用openedProjectId 计算
    let projId = projectVO.id;
    let isProjOwner = projectVO.uid==mineId;
    let mergedActive =(!active && openedProjectId==projId);
    let extraCss=(mergedActive?" is-active":"");
    return (
      <div className={"navMenuLi "+extraCss}  onClick={this.onClickProjectLi.bind(this,projId)} key={projectVO.id}><div className="deco" />{projectVO.name}
        {isProjOwner && <div className={"dropDownWrapper"} onClick={this.onStopP.bind(this)}>
          <Dropdown  menuAlign="end" onCommand={this.onCommand.bind(this)} menu={
            <Dropdown.Menu className={"equipmentDropdownMenu"}>
              <Dropdown.Item command={"edit-" + projectVO.id + "-" + projectVO.name} className="dropDownLi">
                <img src={require("../res/1026/周报/2.0项目/2_edit.png")} className="dropDownLiIcon"/>
                编辑项目</Dropdown.Item>
              <Dropdown.Item command={"archive-" + projectVO.id} className="dropDownLi">
                <img src={require("../res/1026/周报/2.0项目/2_file.png")} className="dropDownLiIcon"/>
                归档项目</Dropdown.Item>
              <Dropdown.Item command={"del-" + projectVO.id} className="dropDownLi">
                <img src={require("../res/1026/周报/2.0项目/2_delete.png")} className="dropDownLiIcon"/>
                删除项目</Dropdown.Item>
            </Dropdown.Menu>
          }>
            <img className="navMenuLiMoreBtn" src={require("../res/1026/周报/2.0/2_0_more.png")}/>
            {/*<Icon name="menu" />*/}
          </Dropdown>
        </div>}
      </div>
    );
  }
  onStopP(e)
  {
    e.stopPropagation();
  }
  onCommand(command)
  {
    let [cmd,id,name] = command.split("-");
    if ( cmd == "archive")
    {
      MessageBox.confirm('归档后可在「已归档」中重新激活', '确认要归档此项目吗？', {
        type: 'warning'
      }).then(() => {
        Facade.gw.archiveProject(id,()=>{
          runRefreshProjectList(this.props.dispatch);
        });
      }).catch(() => {
      });
    }else if ( cmd =="del")
    {
      MessageBox.confirm('删除的项目可在回收站中恢复', '确认要删除此项目吗？', {
        type: 'warning'
      }).then(() => {
        Facade.gw.deleteProject(id, () => {
          runRefreshProjectList(this.props.dispatch);
        });
      }).catch(() => {
      });
    }else if (cmd=="edit"){
      this.setState({dialogProjEdit:true,editProjectId:id,editProjectName:name});
    }else {
      Message.warning("暂未实现");
    }
    console.log("NavMenu/onCommand",command);
  }

  onClickMenuLi(e){
    let liIndex=e.currentTarget.getAttribute("index");
    this.onSelect(liIndex);
  }

  onSelect(index,indexPath)
  {
    let path = "/home";
    switch (index)
    {
      case 'home':
        path = "/home";
        break;
      case '1-dashboard':
        path = "/dashboard";
        break;
      case '1-dashboard2':
        path = "/dashboard2";
        break;
      case '1-week':
        path = "/week";
        break;
      case '3':
        path = "/stats";
        break;
      case '4':
        path = "/exchange";
        break;
      case '9-trashcan':
        path = "/trashcan"
        break;
      case '9-archived':
        path = "/archived"
        break;
    }
    // console.log("NavMenu/onSelect", index, path);
    Facade.customHistory.push(path);
  }

  onClickProjectLi(projId){
    let path = "/project-"+projId;
    Facade.customHistory.push(path);
  }

  onClickAddProj(){
    //新建项目
    this.setState({dialogProj:true});
  }
}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    projectList : state.projects.projectList,
    openedProjectId: state.projects.openedProjectId,
    projectDetail : state.projects.projectDetail,

    mineId : state.login.id,

  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(NavMenu);