import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme,Rect
  , VictoryAxis,VictoryTooltip
} from "victory";
import {VCHART_THEME} from "../consts";

const {Row, Col} = Layout;

class ChartRfi extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      data: [//如仕	桩基	总包	消防	机电	幕墙	精装
        [
          {x: "如仕", y: 1},
          {x: "桩基", y: 3},
          {x: "总包", y: 4},
          {x: "消防", y: 1},
          {x: "机电", y: 0},
          {x: "幕墙", y: 0},
          {x: "精装", y: 0}
        ],
        [
          {x: "如仕", y: 2},
          {x: "桩基", y: 5},
          {x: "总包", y: 5},
          {x: "消防", y: 2},
          {x: "机电", y: 0},
          {x: "幕墙", y: 0},
          {x: "精装", y: 0}
        ]
      ],
      tuli:[{x:"截止上月",color:VCHART_THEME.stack.colorScale[0]},
        {x:"新增",color:VCHART_THEME.stack.colorScale[1]}]
    };
  }

  componentDidMount()
  {
  }


  render()
  {

    let {data,tuli} = this.state;

    return (
      <div className={"chart"}>
        <VictoryChart domainPadding={{x: 30}}
                      padding={{top:30,bottom:50,left:24,right:24}}
                      width={400} height={204}
                      animate={{duration: 400}}
                      theme={VCHART_THEME}
        >
          <VictoryAxis
            style={{
              tickLabels: {fontSize:10},//x轴刻度label
              ticks: {size: 0,stroke:"transparent"},
              axis: {strokeWidth:1,stroke:"rgba(225, 225, 225, 1)"},//x轴
              grid :{stroke:"transparent"}
            }}/>
          <VictoryAxis dependentAxis
                       style={{
                         axis: {stroke: "transparent"},//y轴
                         // axisLabel: {fill: "#757c88"},

                         ticks: {size: 0,stroke:"transparent"},

                         tickLabels: {fill: "#757c88", fontSize: 10, padding: 5},//y轴刻度label
                         grid: {stroke: "#e1e1e1", strokeDasharray: "5 5"},//网格线 横线
                       }}/>
          <VictoryStack
                        // colorScale={['#ff0000','#00ff00']}
          >
            {data.map((data, index) => {
              return <VictoryBar key={index} data={data}
                                 labels={({datum}) => `${datum.y}`}
                                 // labelComponent={<VictoryLabel/>}
              />;
            })}
          </VictoryStack>


          {tuli.map(this.renderTuli.bind(this,400,204))}

        </VictoryChart>

      </div>
    );
  }

  renderTuli(svgW,svgH, li, index,arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 90;
    let x = (svgW-arr.length* liW)/2 + index * liW;
    let x2 = x+12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartRfi);