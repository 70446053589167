
import React, {Component} from 'react';


export default class BodyFooter extends Component
{


  componentDidMount()
  {
  }
  render(){
    return (
      <div className="BodyFooter" style={{textAlign:"center",marginTop:"50px"}}>
        {/*<img src={require("../res/bdbdc.png")} style={{width:"300px",height:"auto"}}/>*/}
      </div>);
  }

}