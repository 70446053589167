
import React, {Component} from 'react';
import {Button, DatePicker} from "element-react";
import TimeUtil from "../TimeUtil";


export default class QuickTimeTf extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {btnMode:true}
  }


  componentDidMount()
  {
  }
  render(){

    let {datetime , onChange} = this.props;
    let {btnMode } = this.state;
    return (
      <div className="QuickTimeTf">
        {btnMode ? (<div type={"text"} onClick={()=>{
        this.setState({btnMode:false})}
          }>{TimeUtil.formatDateOrTime(datetime)}(点击修改)</div>)
        :(
            <DatePicker
              format='yyyy-MM-dd HH:mm'
              isShowTime={true}
              ref={e=>{this.datepicker2 = e;
              if(e)e.togglePickerVisible();
              }}
              value={datetime}
              align="right"
              placeholder="选择日期"
              onChange={date=>{
                if(date && onChange!=null) onChange( date);
                this.setState({btnMode:true});
              }}
              shortcuts={[{
                text: '今天',
                onClick: (picker)=> {
                  if(onChange!=null) onChange( new Date());
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              }, {
                text: '明天',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              },{
                text: '3天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24*3);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              },{
                text: '4天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24*4);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              }, {
                text: '5天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24*5);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              },{
                text: '6天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24*6);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              }, {
                text: '一周后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              },{
                text: '12天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24 * 12);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              },{
                text: '30天后',
                onClick: (picker)=> {
                  const date = new Date();
                  date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                  if(onChange!=null) onChange( date);
                  this.setState({btnMode:true});
                  this.datepicker2.togglePickerVisible()
                }
              }]}
            />
          )

        }

      </div>);
  }

}