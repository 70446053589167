
import React, {Component} from 'react';
import {Button, Layout, Input, Radio, Switch, Pagination, Loading, Dialog, Table, Checkbox ,Card} from "element-react";
import 'element-theme-default';
import * as V from 'victory';
import NavMenu from "../components/NavMenu";
import {
  customerUpdate,
  customerOpen,
  setAdminList,
  setCustomerFilter,
  setCustomerList,
  loginRecovery
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import HseAuditResultChart from "../components/hse/HseAuditResultChart";
import ManpowerChart from "../components/manpower/ManpowerChart";
import TopSearch from "../components/TopSearch";
import TopBar from "../components/TopBar";



const {Row,Col} =Layout;


class DashboardPage extends Component
{

  constructor(props)
  {
    super(props);
    this.state={
      dialogVisible2: false,
      isLoading: true,
    };
  }




  componentDidMount()
  {


  }


  render(){
    let {activeNav} = this.props;
    return (
      <div className="main-wrapper">
        <div className="nav-wrapper">
          <NavMenu active={activeNav}/>
        </div>

        <div className="right-wrapper">
          <div className="top-bar-wrapper">
            <TopBar/>
          </div>

          <div className="main-content-wrapper">
            {this.props.children}



          </div>


        </div>

      </div>
      );
  }


  static propTypes = {
    activeNav: PropTypes.string
  }


}



const mapStateToProps=(state)=>
{
  return {
  };
};
export default connect(
  mapStateToProps
)(DashboardPage)