
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  DatePicker,
  Tabs,
  Icon, Message, Badge, Select,Tag,Dropdown
} from "element-react";
import 'element-theme-default';
import {
  customerOpen,
  loginRecovery, openProjectId,
  setAdminList,
  setWeekReportDetail,
  setWeekReportListFilter, setWeekReportTaskDetail,
  setWeekReportTaskFold, setWeekReportTaskList,
  setWeekReportTaskListFilter,
  setWeekReportTestData, updateWeekReportTaskDetail
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import Utils from "../Utils";
import QuickTimeTf from "../components/QuickTimeTf";
import {
  EDIT_TASK_TYPE_DEPARTMENT, EDIT_TASK_TYPE_description, EDIT_TASK_TYPE_managerUserList,
  EDIT_TASK_TYPE_planRealDateFinish, EDIT_TASK_TYPE_remark,
  EDIT_TASK_TYPE_taskFinishDate, EDIT_TASK_TYPE_taskUserList, WEB_URL,
  WORK_DATE_NATURE
} from "../consts";
import PageWrapper from "./PageWrapper";
import "./WeekDetailPage.css";
import UserSelect from "../components/UserSelect";
import runRefreshTaskDetail from "../controllers/runRefreshTaskVO";
import runRefreshWeekly from "../controllers/runRefreshWeekly";
import runRefreshTasks from "../controllers/runRefreshTasks";
import WeekTasksTable from "../components/WeekTasksTable";
import WeekTasksTabBar from "../components/WeekTasksTabBar";
import BodyFooter from "../components/BodyFooter";
import runRefreshProjectMembers from "../controllers/runRefreshProjectMembers";


const {Row,Col} =Layout;
class WeekDetailPage extends Component
{

  constructor(props)
  {
    super(props);
    this.state={
      testData :[],
    };
  }

  componentWillReceiveProps(nextProps, nextContext)
  {
    let weeklyId = parseInt(nextProps.match.params.weeklyId);
    if ( weeklyId!= this.weeklyId )
    {
      this.weeklyId = weeklyId;
      runRefreshTasks(this.weeklyId,1,this.props.dispatch);
      runRefreshWeekly(this.weeklyId,this.props.dispatch);
    }
    //首次构建不会触发到这里，但是因为nextProps.openedProjectId是被动触发的所以这里才会进入，否则就要在didMount里调用一次
    if ( nextProps.openedProjectId >0 && nextProps.openedProjectId != this.openedProjectId)
    {
      this.openedProjectId= nextProps.openedProjectId;
      runRefreshProjectMembers(this.openedProjectId,this.props.dispatch);
    }
  }

  componentDidMount()
  {
    this.weeklyId = parseInt(this.props.match.params.weeklyId);
    runRefreshTasks(this.weeklyId,1,this.props.dispatch);
    runRefreshWeekly(this.weeklyId,this.props.dispatch);

  }

  componentWillUnmount()
  {
  }



  render(){
    let {dispatch, weekReportDetail, projectDetail,typeNum} = this.props;
    let { } = this.state;
    //创建筛选器
    return (
      <PageWrapper>
        <div className="WeekDetailPage pageWithPadding">

          <div className="pageCard">
            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                  {projectDetail? projectDetail.name:""} 周报 {weekReportDetail?weekReportDetail.dateBucket:""}
                </div>
              </Col>
              <Col span={12}>
              </Col>
            </Row>
           <div className="secondRow">

                <div className="tuli-row">
                  <div className="tuli tuli1">正常:{typeNum["1"]||0}</div>
                  <div className="tuli tuli2">延期:{typeNum["2"]||0}</div>
                  <div className="tuli tuli3">紧急:{typeNum["3"]||0}</div>
                  <div className="tuli tuli4">完成:{typeNum["4"]||0}</div>

                </div>
                <div  >
                  <Dropdown
                    onCommand={this.onDropDownCommand.bind(this)}
                    menu={(
                    <Dropdown.Menu>
                      <Dropdown.Item command="share">分享链接</Dropdown.Item>
                      <Dropdown.Item command={"pdf"}>下载PDF</Dropdown.Item>
                    </Dropdown.Menu>
                  )}>
                    <Button >
                      更多操作
                    </Button>
                  </Dropdown>

                </div>
           </div>

            <div className="cardWithPadding">
            {this.weeklyId && <WeekTasksTabBar weeklyId={this.weeklyId} />}
            </div>

            <Row style={{marginLeft:"24px"}}>
              {this.weeklyId && <WeekTasksTable weeklyId={this.weeklyId} editable={true}/>}
            </Row>


          </div>


          <Row>
            <BodyFooter/>
          </Row>

        </div>
      </PageWrapper>
        );
  }

  onDropDownCommand(command)
  {
    if(command=="share")
    {
      this.onShare();
    }else if ( command == "pdf")
    {
      Facade.customHistory.push("weekpdf-"+this.weeklyId);
    }
  }

  onShare(){
    Utils.copyTXT(`${WEB_URL}/shared-${this.weeklyId}`);
    Message.success("链接已复制到剪贴板");
  }

  static propTypes = {
  }

}


const mapStateToProps=(state)=>
{
  return {
    openedProjectId: state.projects.openedProjectId,
    projectDetail: state.projects.projectDetail,

    weekReportDetail: state.weekReports.weekReportDetail,
    typeNum: state.weekReportTasks.typeNum

  };
};
export default connect(
  mapStateToProps
)(WeekDetailPage);