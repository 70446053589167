import * as echarts from 'echarts';
import React, {Component} from 'react';

import {connect} from "react-redux";
class HseAuditResultChart extends Component
{


  componentDidMount()
  {


    var chartDom = document.getElementById('hse-audit-result-chart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      xAxis: {
        type: 'category',
        data: ['4-1', '4-2', '4-3', '4-4', '4-5', '4-6', '4-7']
      },
      yAxis: {
        type: 'value',
        min :78,
        max: 80,
        axisLabel:{
          formatter: '{value} %'
        }
      },
      series: [
        {
          data: [78.87,78.93,79.03,79.13,79.23,79.33,79.43],
          type: 'line'
        }
      ]
    };

    option && myChart.setOption(option);

  }

  render()
  {
    return (

      <div className="hse-audit-result-chart">
        <div className="hse-audit-result-chart-content" style={{height: 300}}>
          <div id="hse-audit-result-chart" style={{width: '100%', height: '100%'}}></div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {vo: state.customers.openCustomer};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(HseAuditResultChart);