import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {
  Button,
  Layout,
  Input,
  Loading,
  Table,
  Tag,
  DatePicker,
  Message,
  Select,
  Icon,
  MessageBox,
  Dropdown, Dialog
} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {
  customerUpdate,
  loginLogin,
  setAdminList,
  setWeekReportTaskDetail,
  updateWeekReportTaskDetail
} from "../actions";
import Facade from "../Facade";
import runRefreshTasks from "../controllers/runRefreshTasks";
import TimeUtil from "../TimeUtil";
import {
  EDIT_TASK_TYPE_DEPARTMENT,
  EDIT_TASK_TYPE_description,
  EDIT_TASK_TYPE_managerUserList,
  EDIT_TASK_TYPE_planRealDateFinish,
  EDIT_TASK_TYPE_remark,
  EDIT_TASK_TYPE_taskFinishDate,
  EDIT_TASK_TYPE_taskName,
  EDIT_TASK_TYPE_taskUserList,
  ROOT_TASK_ID, TASK_EDIT_PERMISSION_MANAGE_USER,
  TASK_EDIT_PERMISSION_PROJECT_OWNER, TASK_EDIT_PERMISSION_TASK_USER
} from "../consts";
import UserSelect from "./UserSelect";
import "./WeekTasksTable.css";
import ProjectCreate from "./ProjectCreate";
import OperationPanel from "./OperationPanel";
import TaskAddPanel from "./TaskAddPanel";

const {Row, Col} = Layout;

/**
 * 周报的任务表格
 */
class WeekTasksTable extends Component
{
  static propTypes = {
    pdf: PropTypes.bool,
    editable: PropTypes.bool,
    weeklyId: PropTypes.number.isRequired
  }


  constructor(props)
  {
    super(props);
    let weeklyType = props.tasksFilter.type;
    let enableAddTask =  weeklyType  > 0;
    let pdf = props.pdf;
    this.state = {
      enableAddTask,
      columnsConf: [
        // {label:" ",fixed:"left", fit:true, render : this.renderExpandBtn.bind(this)},
        {label: "计划任务节点", prop: "taskName", "fixed": pdf?false :"left", width: 330, render: this.renderTaskName.bind(this,TASK_EDIT_PERMISSION_PROJECT_OWNER)},
        {label: "计划完成时间", prop: "taskFinishDate",width: 160, render: this.renderDate.bind(this,TASK_EDIT_PERMISSION_PROJECT_OWNER)},
        {label: "节点责任人", prop: "managerUserList",prop2:"managerUid", width: 130, render: this.renderFuzeren.bind(this,TASK_EDIT_PERMISSION_PROJECT_OWNER)},
        {label: "任务责任人", prop: "taskUserList",prop2:"taskUid", width: 130, render: this.renderFuzeren.bind(this,TASK_EDIT_PERMISSION_MANAGE_USER)},
        {
          label: "项管/ 设计院/ QS责任人",
          prop: "responsibleDepartmentUserList",
          prop2:"responsibleDepartmentUid",
          width: 130,
          render: this.renderFuzeren.bind(this,TASK_EDIT_PERMISSION_MANAGE_USER)
        },
        {label: "实际完成时间", prop: "planRealFinishDate", width: 160, render: this.renderDate.bind(this,TASK_EDIT_PERMISSION_TASK_USER)},
        {label: "任务周进展", prop: "description", minWidth: 350, render: this.renderMiaoshu.bind(this,TASK_EDIT_PERMISSION_TASK_USER)},
        {label: "备注/工作成果", prop: "remark", minWidth: 300, render: this.renderMiaoshu.bind(this,TASK_EDIT_PERMISSION_TASK_USER)},
      ]
    };
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    let weeklyType = nextProps.tasksFilter.type;
    let enableAddTask =  weeklyType  > 0;
    return {
      enableAddTask: enableAddTask
    }
  }
  componentDidMount()
  {
    this.listenerBodyKeyup = this.onBodyKeyup.bind(this);
    document.body.addEventListener("keyup", this.listenerBodyKeyup);
  }

  componentWillUnmount()
  {
    document.body.removeEventListener("keyup", this.listenerBodyKeyup);

  }

  onBodyKeyup(e)
  {
    e.stopPropagation();
    if (e.keyCode == 27)
    {
      this.props.dispatch(setWeekReportTaskDetail(null));
    }
  }


  renderCat(row, column, index)
  {
    let cat = row[column.prop];
    return <Tag type="primary">{cat}</Tag>
  }


  renderDate(requirePermission,row, column, index)
  {
    let {editRowPermission}=this.state;
    let hasEditPermission=editRowPermission>=requirePermission;
    let defaultValue = row[column.prop];
    let { editable,taskVO} = this.props;
    if (editable &&  taskVO && taskVO.id == row.id && hasEditPermission)
    {
      let valueAsDate = defaultValue ? TimeUtil.parseTimeString(defaultValue) : null;
      // 如果当前正在编辑 则显示选择框
      return <DatePicker
        value={valueAsDate}
        placeholder="选择日期"
        onChange={this.onDateChange.bind(this, row, column)}
      />
    } else
    {
      // 如果不在编辑，则显示tag
      return defaultValue || "";
    }
  }

  onDateChange(row, column, value)
  {
    if (value == null) return;
    let editType = EDIT_TASK_TYPE_planRealDateFinish;
    if (column.prop == "taskFinishDate")
    {
      editType = EDIT_TASK_TYPE_taskFinishDate;
    }
    this.reqTaskEdit(row.id, editType, TimeUtil.formatDBDate2(value));
  }

  //修改任务节点的字段
  reqTaskEdit(taskId, editType, value, closeTask = false)
  {
    Facade.gw.editTask( editType, taskId, value, (newVO) => {
      this.props.dispatch(updateWeekReportTaskDetail(newVO))
      // runRefreshTaskDetail(taskId,this.props.dispatch);
      if (closeTask) this.props.dispatch(setWeekReportTaskDetail(null));
    });
  }

  reqTaskDelete(taskId){
    let {tasksFilter,weeklyId} = this.props;
    let weeklyType = tasksFilter.type;
    Facade.gw.deleteTask(taskId,()=>{
      Message.success("删除任务成功");
      runRefreshTasks(weeklyId,weeklyType,this.props.dispatch);
    });
  }

  renderMiaoshu(requirePermission,row, column, index)
  {
    let {editRowPermission}=this.state;
    let hasEditPermission=editRowPermission>=requirePermission;
    let defaultValue = row[column.prop];
    let {editable,taskVO} = this.props;
    if (editable && taskVO && taskVO.id == row.id && hasEditPermission)
    {
      // 如果当前正在编辑 则显示选择框
      return <Input type={"textarea"} autosize={{minRows: 3}} defaultValue={defaultValue}
                    onBlur={this.onTextBlur.bind(this, row, column)}/>
    } else
    {
      // 如果不在编辑，则显示tag
      return defaultValue ? defaultValue : "";
    }
  }

  onTextBlur(row, column, textRef)
  {
    let textValue = textRef.target.value;
    let editType = (column.prop == 'description') ? EDIT_TASK_TYPE_description : (column.prop == 'taskName'?EDIT_TASK_TYPE_taskName:EDIT_TASK_TYPE_remark);
    this.reqTaskEdit(row.id, editType, textValue);
  }

  renderFuzeren(requirePermission,row, column)
  {
    let {editable,taskVO} = this.props;
    let defaultValue = row[column.prop];
    let {editRowPermission}=this.state;
    let hasEditPermission=editRowPermission>=requirePermission;
    if (editable && taskVO && taskVO.id == row.id && hasEditPermission)
    {
      // 如果当前正在编辑 则显示选择框
      return <div><UserSelect list={defaultValue || []} onChange={this.onUserChange.bind(this, row, column)}/>
      </div>
    } else
    {
      // 如果不在编辑，则显示tag
      return (defaultValue && defaultValue.length) ? defaultValue.map((li, i) => <Tag className="userTag" type="primary"
                                                                                      key={i}><img
          src={li.avatarUrl}/>{li.nickName}</Tag>)
        : "";
    }
  }

  onUserChange(row, column, uids)
  {
    let editType = (column.prop == 'managerUserList') ? EDIT_TASK_TYPE_managerUserList : EDIT_TASK_TYPE_taskUserList;
    if (column.prop=='responsibleDepartmentUserList')
    {
      editType= EDIT_TASK_TYPE_DEPARTMENT;
    }
    //拦截重复请求
    let oldValue = row[column.prop2];
    let newValue=JSON.stringify(uids);
    // console.log("WeekTasksTable/onUserChange",editType,oldValue,newValue);
    if ( oldValue != newValue)
    {
      this.reqTaskEdit(row.id, editType, JSON.stringify(uids));
    }else
    {
      // console.log("WeekTasksTable/onUserChange 未修改 拦截");
    }
  }


  renderTaskName(requirePermission,row, column, index)
  {
    let defaultValue = row[column.prop];
    let {editable,taskVO,tasksFilter} = this.props;
    let weeklyType = tasksFilter.type;
    let indent = 0;
    let extra="";
    if ( weeklyType!=1)
    {//全部任务 显示层级缩进
      indent =((row.cTaskLevel-1) * 2);
      if(row.cTaskLevel>1)
      {
        indent+=8;
      }
      if (row.isSubtask==1 && row.taskParentId!=1){indent +=8}
      if (row.isSubtask==1){extra ="⌙"}else{extra="↘︎"}
      // extra = row.cTaskGenTree+" ";
    }
    let end=(      <div style={{display: "inline-block", marginLeft: (indent+12) + "px",textIndent:"-12px", marginRight: "10px"}}>
      {extra}{defaultValue}
      </div>
    );
    let {editRowPermission}=this.state;
    let isEditingMe=false;
    if (editable && taskVO && taskVO.id == row.id && editRowPermission>=requirePermission)
    {
      // 如果当前正在编辑 则显示选择框
      end= <Input defaultValue={defaultValue}
                    onBlur={this.onTextBlur.bind(this, row, column)}/>
      isEditingMe=true;
    }else{
    }
    return <div className="taskNameCell">
      {this.renderStatus(row)}
      {end}
      {isEditingMe || this.renderRowActionBtns(row)}
    </div>;
  }
  renderRowActionBtns(row){
    let {editable} = this.props;
    let {hoverRow} = this.state;
    if (hoverRow == row && editable )
    {
      let isOwner=this.calcIsProjectOwner();
      return (
        <div className="taskNameDropDownWrapper" onClick={this.onClickEmpty}>
          <Dropdown  className="taskNameDropDown" menuAlign="end" onCommand={this.onDropDownCommand.bind(this)}
                     menu={
                       <Dropdown.Menu className={"taskNameDropDownMenu"}>
                         {isOwner && <Dropdown.Item command={"addtask-" + row.taskId} className="dropDownLi">
                           <img src={require("../res/1026/周报/2.3/2_3_add.png")} className="dropDownLiIcon"/>
                           添加子任务</Dropdown.Item>}
                         <Dropdown.Item command={"log-" + row.id} className="dropDownLi">
                           <img src={require("../res/1026/周报/2.3/2_3_add.png")} className="dropDownLiIcon"/>
                           操作记录</Dropdown.Item>
                         {isOwner && <Dropdown.Item command={"del-" + row.id} className="dropDownLi">
                           <img src={require("../res/1026/周报/2.3/2_3_add.png")} className="dropDownLiIcon"/>
                           删除任务</Dropdown.Item>}
                       </Dropdown.Menu>
                     }>
            <img src={require("../res/1026/周报/2.3/2_3_more.png")} className="dropDownIcon"/>
          </Dropdown>
        </div>);
    }
  }

  onClickEmpty(e){e.stopPropagation()}
  onDropDownCommand(command,domEle){
    // domEle
    let [cmd,id] = command.split("-");
    if ( cmd == "del")
    {
      this.deleteTask(id);
    }else if ( cmd == "addtask")
    {
      //此时id代表 taskParentId
      this.addSubTask(id);
    }else if ( cmd =="log")
    {
      let {hoverRow} = this.state;
      this.setState({dialogOperation : true , operationTaskId : id,oprTaskName:hoverRow.taskName});
    }
  }
  onDeleteTask(row,e){
    e.stopPropagation();
    this.deleteTask(row.id);
  }

  deleteTask(id)
  {
    MessageBox.confirm('此操作将删除本条任务, 是否继续?', '提示', {
      type: 'warning'
    }).then(() => {
      this.reqTaskDelete(id);
    }).catch(()=>{});
  }

  // 为有延误的任务做出警报
  renderDelayWarning(row, children)
  {
    if (row.cTaskDelayDays > 0)
    {
      return <div style={{color: "red", position: "absolute", right: 2, top: 2}}><Icon name="information"/></div>
    }
  }

  renderStatus(row)
  {
    return(        <div className={"tu tu"+row.status}></div>);
  }

  renderActions()
  {
  }

  onRowChange(row)
  {
    // if ( this.props.taskVO!=null)
    // {
    //   this.props.dispatch(setWeekReportTaskDetail(null));
    // }else{
    //   this.props.dispatch (setWeekReportTaskDetail(row));
    //   // event.stopPropagation();
    // }
  }

  onCellClick(row, column, cell, event)
  {
    // if (column.prop == "taskName") return;

    if (this.props.taskVO != null && this.props.taskVO.id != row.id)
    {//正在编辑某一行时 切换到其它行，则先取消编辑
      this.props.dispatch(setWeekReportTaskDetail(null));
    } else
    {
      //不在编辑状态，则编辑该行
      this.props.dispatch(setWeekReportTaskDetail(row));
      //计算编辑权限
      let editRowPermission=this.calcMyPermission(row);
      this.setState({editRowPermission});
    }
    event.stopPropagation();
  }


  rowClassName(row, index)
  {
    return row.taskCritical == 1 ? "taskCriticalRow" : "";
  }

  summaryMethod(columns, data)
  {
    return [<div>
      <Button type={"text"} icon="plus" onClick={this.onAddParentTask.bind(this)}>添加任务</Button>

    </div>, ""];
  }

  onAddParentTask(e)
  {
    e.stopPropagation();
    this.addParentTask();
  }

  renderAddTaskPanel(){
    let {showAddTask,addTaskParentId}=this.state;
    if ( false==showAddTask)return null;
    else{
      let {weekReportDetail}=this.props;
      let isSubTask=addTaskParentId!=ROOT_TASK_ID;
      return (<Dialog
        title={isSubTask?"添加子任务":"添加任务"}
        customClass={""}
        visible={this.state.showAddTask}
        onCancel={() => {
          this.setState({showAddTask: false});
        }}
      >
        <Dialog.Body>
          {this.state.showAddTask && <TaskAddPanel
            onSaveCb={this.onAddTaskSubmit.bind(this)}
            isSubTask={isSubTask}
            weekDateStart={weekReportDetail.dateStart}
          />}
        </Dialog.Body>
      </Dialog>);
    }
  }

  onAddTaskSubmit(formData){
    let {name,dbDate}=formData;
    let {tasksFilter,weeklyId,openedProjectId} = this.props;
    let weeklyType = tasksFilter.type;
    let {addTaskParentId}=this.state;
    let isSubTask=addTaskParentId!=ROOT_TASK_ID;
    Facade.gw.addTask(openedProjectId,weeklyId, addTaskParentId, name,dbDate, () => {
      Message.success(isSubTask?"添加子任务成功":"添加任务成功");
      runRefreshTasks(weeklyId, weeklyType, this.props.dispatch);
    });
  }
  /**
   * 添加母任务,是顶级的 隐含条件：taskParentId=1
   */
  addParentTask(){
    this.setState({showAddTask:true ,addTaskParentId:ROOT_TASK_ID});
  }

  /**
   * 添加子任务,如果是顶级的则taskParentId=1
   * @param taskParentId
   */
  addSubTask ( taskParentId )
  {
    this.setState({showAddTask:true ,addTaskParentId:taskParentId});
  }

  onAddSubTask(row,e)
  {
    e.stopPropagation();
    this.addSubTask(row.taskId);
  }

  onCellMouseEnter(row, column, cell, event){
    this.setState({hoverRow : row });
  }
  onCellMouseLeave(row, column, cell, event){
    this.setState({hoverRow : null });
  }
  
  /**************** 权限判断 ******************/
  //region 权限判断
  /** 返回权限 0:只读  3：全部可编辑  2：只能编辑任务负责人～备注  1：只能编辑实际完成时间～备注 */
  calcMyPermission(taskRow){
    let { mineId ,projectUser,  } = this.props;
    //项目负责人可编辑全部
    if ( mineId == projectUser.id) return 3;
    /*
                "taskUid": "[1]",//任务责任人
                "managerUid": null,//节点责任人
                "responsibleDepartmentUid": null,//QS责任人
     */
    //节点负责人只能编辑任务负责人～备注2
    if ( taskRow.managerUserList && taskRow.managerUserList.find(({id})=>id==mineId) )
    {
      return 2;
    }
    //任务负责人，项管负责人1
    if ( taskRow.taskUserList && taskRow.taskUserList.find(({id})=>id==mineId) )
    {
      return 1;
    }
    if ( taskRow.responsibleDepartmentUserList && taskRow.responsibleDepartmentUserList.includes(({id})=>id==mineId) )
    {
      return 1;
    }


    return 0;
  }

  calcIsProjectOwner(){
    let { mineId ,projectUser,  } = this.props;
    //项目负责人可编辑全部
    return ( projectUser && mineId == projectUser.id) ;
  }
  //endregion

  render()
  {
    let {dispatch, isLoadingTaskList, editable,tasksFilter,taskList,pdf} = this.props;
    let {enableAddTask} = this.state;
    let isOwner=this.calcIsProjectOwner();
    return (
      <div className="tasksTable">
        <Loading loading={isLoadingTaskList}>
          <Table
            border={true}
            style={pdf?{}:{ width: "100%"}}
            columns={this.state.columnsConf}
            data={taskList}
            stripe={false}
            highlightCurrentRow={true}
            rowClassName={this.rowClassName.bind(this)}
            onCurrentChange={this.onRowChange.bind(this)}
            onCellClick={editable ? this.onCellClick.bind(this) : null}
            onCellMouseEnter={editable ? this.onCellMouseEnter.bind(this) : null}
            onCellMouseLeave={editable ? this.onCellMouseLeave.bind(this) : null}
            showSummary={editable && enableAddTask && isOwner}
            summaryMethod={(editable && enableAddTask && isOwner) ? this.summaryMethod.bind(this) : null}
          />
        </Loading>
        <Dialog
          title=""
          customClass={"OperationPanelDialog"}
          visible={this.state.dialogOperation}
          onCancel={() => {
            this.setState({dialogOperation: false});
          }}
        >
          <Dialog.Body>
            {this.state.dialogOperation && <OperationPanel
              id={this.state.operationTaskId}
              taskName={this.state.oprTaskName}
            />}
          </Dialog.Body>
        </Dialog>
        {this.renderAddTaskPanel()}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    openedProjectId: state.projects.openedProjectId,
    projectDetail: state.projects.projectDetail,

    taskList: state.weekReportTasks.list,
    taskVO: state.weekReportTasks.taskVO,
    tasksFilter: state.weekReportTasks.tasksFilter,

    weekReportDetail: state.weekReports.weekReportDetail,
    isLoadingTaskList: state.general.isLoading.taskList,

    mineId : state.login.id,
    projectUser : state.projectMembers.projectUser,
    dailyUserList : state.projectMembers.dailyUserList,
    memberUserList : state.projectMembers.memberUserList,
  }
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(WeekTasksTable);