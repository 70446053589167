
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Form, Message, Upload,Loading} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList, setProjectList} from "../actions";
import Facade from "../Facade";
import runRefreshProjectList from "../controllers/runRefreshProjectList";
import UserSelect from "./UserSelect";
import {EDIT_TASK_TYPE_managerUserList, EDIT_TASK_TYPE_taskUserList} from "../consts";

const {Row,Col} =Layout;
class ProjectCreate extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      fullscreenLoading:false,
      form: {
        name: "",
      },
      formRules: {
        name: {required: true, message: "请输入项目名称", trigger: "blur,change"}
        ,filePath: {required: true, trigger: "change,blur", message: "请完成文件上传"}
        ,uid:{required:true,message: "必须设置负责人"}
        ,dailyUids:{required:true,message: "必须设置日报负责人"}
      }
    };
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    let { mineId,nickName } = nextProps;
    //首次获得mineId的时候 设置uid的值，并且在此之前不允许显示用户交互
    if ( !prevState.form.uid && mineId && nickName)
    {
      return {loadReady:true,form :{...prevState.form,uid : mineId}}
    }
  }

  componentDidMount()
  {
  }
  render(){
    let {filePath} = this.state.form;
    let {loadReady,fullscreenLoading} = this.state;
    let { mineId,nickName } = this.props;
    if ( !loadReady)
      return <Loading/>;// 需要确保一开始有数据在userselect组建construct的时候就有值

    return (
      <div className="ProjectCreate" style={{width: "80%",margin:"auto"}}>
        <Loading loading={fullscreenLoading}>

        <Form ref="form" model={this.state.form} rules={this.state.formRules} labelPosition="top">
          <Form.Item label="项目名称" prop="name">
            <Input type="text" name="name" value={this.state.form.name} onChange={this.onChange.bind(this,"name")} placeholder="输入项目名称"/>
          </Form.Item>
          <Form.Item label="项目负责人" prop="uid">
            <UserSelect single={true} list={ [{id:mineId,nickName}]} onChange={this.onUserChange.bind(this, "uid")}/>
          </Form.Item>
          <Form.Item label="日报负责人" prop="dailyUids">
            <UserSelect list={ []} onChange={this.onUserChange.bind(this, "dailyUids")}/>
          </Form.Item>

          <Form.Item label="上传文件" prop="filePath">
            <Upload ref={"upload"}
                    className="form-upload"
                    drag
                    action={Facade.gw.getUploadGateway()}
                    headers={Facade.gw.getUploadHeaders()}
                    name={"uploadFile"}
                    multiple={false}
                    tip={<div className="el-upload__tip">只能上传project文件</div>}
                    onSuccess={this.onUploadSucc.bind(this)}
            >
              {!filePath && <i className="el-icon-upload"></i>}
              {filePath && <i className="el-icon-circle-check icon"></i>}
              {!filePath && <div className="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>}
              {filePath && <div className="el-upload__text">已收到您上传的文件，如需更改，则将文件拖到此处，或点击上传</div>}
            </Upload>

          </Form.Item>

          <Button onClick={this.onSubmit.bind(this)} type={"primary"}>提交</Button>
        </Form>
        </Loading>
      </div>);

  }

  onChange(key ,val)
  {
    this.state.form[key] = val;
    this.forceUpdate();
  }

  onUploadSucc(res, file, fileList)
  {
    // console.log("ProjectCreate/onUploadSucc",res);
    if ( res.error!=0)
    {
      Facade.gw.gwAlert(res.error +":"+res.message)
      return;
    }
    this.onChange("filePath", res.data);
    this.refs.form.validateField("filePath", () => {});
  }
  onUserChange( field, uids)
  {
    this.onChange(field,uids);
    // console.log("ProjectCreate/onUserChange",field,uids);
  }


  onSubmit()
  {
    this.refs.form.validate((valid) => {
      if ( valid ){
        
        let {name,filePath,uid,dailyUids} = this.state.form;
        if (!filePath)
        {
          Message.warning("请上传文件");
        }
        this.setState({fullscreenLoading:true});
        Facade.gw.createProject(name, uid,JSON.stringify(dailyUids),filePath,({id}) => {
          this.setState({fullscreenLoading:false});
          Message.success("提交成功");
          this.resetForm();
          //刷新
          runRefreshProjectList(this.props.dispatch);

          //
          this.props.onFinish != null && this.props.onFinish(id);
        });
      }
    });
  }
  resetForm(){
    this.refs.form.resetFields();
    this.refs.upload.clearFiles();
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    projectList : state.projects.projectList,
    nickName: state.login.nickName,
    avatarUrl: state.login.avatarUrl,
    mineId : state.login.id
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ProjectCreate);