import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Dropdown, Message} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import PageWrapper from "./PageWrapper";
import "./HomePage.css";
import Utils from "../Utils";
import {WEB_URL} from "../consts";

const {Row, Col} = Layout;

class HomePage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {task:[],typeNum:{}};
  }

  componentDidMount()
  {
    this.refreshData();
  }
  refreshData(){
    Facade.gw.getMyTask(({dateStart,dateEnd,task,typeNum})=>{
      this.setState({dateStart,dateEnd,task: task||[],typeNum})
    });
    Facade.gw.getMyDailyList(({unFinishDailyNum,dailyList})=>{
      this.setState({unFinishDailyNum,dailyList});
    });
  }

  render()
  {
    let { dateStart,dateEnd,task,typeNum ,unFinishDailyNum,dailyList} = this.state;
    return (
      <PageWrapper activeNav={"home"}>
        <div className="HomePage pageWithPadding">
          <Row gutter={20} className="HomeRow">

            <Col span={6}>
              <div className="left-card">
                <div className="title">今日日报</div>
                {unFinishDailyNum>0 && <div className="alert">您有{unFinishDailyNum}份日报待填写！</div>}

                <div className="list">
                  {dailyList && dailyList.map((li,i)=>{
                    return (<HomePageDailyLi key={i} li={li} />)
                  })}


                </div>
              </div>
            </Col>
            <Col span={18}>

              <div className="right-card">
                <div className="title">周报 我的任务</div>
                <div className={"zbtitle"}>双周 {dateStart}-{dateEnd}</div>

                <div className="tuli-row">
                  <div className="tuli tuli1">正常:{typeNum["1"]||0}</div>
                  <div className="tuli tuli2">延期:{typeNum["2"]||0}</div>
                  <div className="tuli tuli3">紧急:{typeNum["3"]||0}</div>
                  <div className="tuli tuli4">完成:{typeNum["4"]||0}</div>

                </div>
<div className="zbTableWrap">
                <table className={"zbTable"}>
                  <thead>
                  <th>任务计划节点</th>
                  <th>计划完成时间</th>
                  <th>所属项目</th>
                  </thead>
                  {task.map((li)=>{
return (                  <HomePageTaskLi color={li.status} name={li.taskName} finishDate={li.taskFinishDate}
                                          projectName={li.projectName}
                                          weeklyId={li.weeklyId}
                          />);
                  })}

                  {(!task || task.length==0)?(
                    <tr ><td colSpan={3} className={"empty"}> 暂无内容 </td></tr>
                  ):null}

                </table>
</div>

              </div>
            </Col>
          </Row>


        </div>
      </PageWrapper>);
  }

}
class HomePageTaskLi extends Component{

  render(){
    let {color,name,finishDate,projectName} = this.props;
    return (<tr className={"taskLi"} onClick={this.onClickLi.bind(this)}>
      <td className="taskname">
        <div className={"tu tu"+color}></div>
        {name}</td>
      <td>{finishDate}</td>
      <td>{projectName}</td>
    </tr>);
  }

  onClickLi(){
    let {weeklyId} = this.props;
    let path = "/week-"+weeklyId;
    Facade.customHistory.push(path);
  }
}

class HomePageDailyLi extends Component{
  render(){
    let {projectName,status,id} = this.props.li;
    return (
      <div className="li" onClick={this.onClickDaily.bind(this)}>
        {status==0 && <div className={"remindDot"}></div>}
        <div className="icon" ><img src={require("../res/1026/周报/1.1/1_1_report.png")}/></div>
        <div className="text" >{projectName}</div>
        <div onClick={(e)=>{e.stopPropagation()}}>
          <Dropdown className="btn" menuAlign="end"  onCommand={this.onDropDownCommand.bind(this)}
                    menu={
                      <Dropdown.Menu className={"taskNameDropDownMenu"}>
                        <Dropdown.Item command={"share"} className="dropDownLi">
                          分享日报</Dropdown.Item>
                        <Dropdown.Item command={"download"} className="dropDownLi">
                          下载PDF</Dropdown.Item>
                      </Dropdown.Menu>
                    }>
            <img src={require("../res/1026/周报/2.3/2_3_more.png")} className="dropDownIcon" />
          </Dropdown>
        </div>

      </div>);
  }
  onClickDaily( ){
      let{projectAddress,id} = this.props.li;
      //日报编辑页，基础信息未填写的进入基础信息，填写基础信息的默认切换到日报编辑
      if ( projectAddress )
        Facade.customHistory.push(`/daytable-${id}`);
      else
        Facade.customHistory.push(`/day-${id}`);
  }

  onDropDownCommand(cmd,inst)
  {
    console.log("HomePageDailyLi/onDropDownCommand",inst);
    let { id} = this.props.li;
    if (cmd == "share")
    {
      Utils.copyTXT(`${WEB_URL}/sharedday-${id}`);
      Message.success("链接已复制到剪贴板");
    } else if (cmd == "download")
    {

    }
  }
}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList: state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(HomePage);