import {
  UPDATE_CUSTOMER, SET_CUSTOMER_LIST, CUSTOMER_OPEN,
  SET_CUSTOMER_FILTER, SET_WORK_FILTER, SET_WORK_NUM, SET_SEARCH_LIST
} from "../actions";

export default function customers(state = {
  searchList:[]
  , total: 0, page: 1, totalPage: 0
  , customerList: []
  , openCustomer: null
  , customerFilter: {uid: -1, label: -1, keyCustomer: 0, criticalCustomer: 0}
  , workFilter : { month:-1 , date:'' ,overdue:0 , undistributed:0 }
  , workNumVO :null
}, action) {
  switch (action.type)
  {
    case SET_SEARCH_LIST:
      return {...state , searchList : action.voArr.map(pollyfillCustomer)};
    case SET_CUSTOMER_LIST:
      let {total, page, totalPage, voArr} = action;
      return {...state, customerList: voArr.map(pollyfillCustomer), total, page, totalPage};
    case UPDATE_CUSTOMER:
      //
      let fillVO =pollyfillCustomer(action.vo);
      // 维护到open的
      let {openCustomer}=state;
      if ( state.openCustomer!=null && state.openCustomer.id == action.vo.id )
      {
        openCustomer = fillVO;
      }
      //维护到本地列表中
      let found=state.customerList.findIndex((li)=> li.id==action.vo.id);
      if ( found>-1 )
      {
        let newList = [
          ...state.customerList.slice(0,found),
          fillVO,
          ...state.customerList.slice(found+1)
        ];
        return {...state,customerList: newList ,openCustomer};
      }else{
        return {...state,openCustomer};
      }
    case CUSTOMER_OPEN:
      return {...state , openCustomer:action.vo};
    case SET_CUSTOMER_FILTER:
      return {...state, customerFilter : action.vo};
    case SET_WORK_FILTER:
      return {...state, workFilter: action.vo};
    case SET_WORK_NUM:
      return {...state, workNumVO: action.vo};
    default :return state;
  }
}

function pollyfillCustomer(li, i)
{
  li.certificationStr = fillCert(li);
  li.labelStr = fillLabel(li);
  li.subjectivityVO = fillPingfen(li);
  li.dausGraph=fillDausGraph(li);
  li.surveyVO=fillSurvey(li);
  return li;
}

function fillCert(li)
{
  //"certification": "1",   //认证状态，0未认证 1已认证
  let {certification} = li;
  if (certification == 1) return '已认证';
  if (certification == 0) return '未认证';
  return '非法值';
}

function fillLabel(li)
{

  let {label} = li;
  if (label == 0) return '新客户';
  if (label == 1) return '未联系上';
  if (label == 2) return '需转化';
  if (label == 3) return '已转化';
  if (label == 4) return '无效客户';
  return '非法值';
}

function fillPingfen(li)
{
  //byMyself是保留字段，如果是1则为主观评分， actionScore为行为分数，dataScore为数据分数， 这3个字段保留吧，剩下的选项字段你来定好了
  let end = { byMyself: 0, actionScore: 0, dataScore: 0
  ,feishuUsing:0,feishuThoughts:0,feishuContact:0};
  let {subjectivity} = li;
  if (subjectivity)
  {
    let sVO = JSON.parse(subjectivity);
    end = Object.assign(end, sVO);
    // if ( li.id==8) console.log("fillPingfen/fillPingfen",subjectivity,sVO,end);
  }
  return end;
}

function fillDausGraph ( li )
{
  let {dau_initial,last_month_dau,last_week_dau,daus,
    allocate_date,last_month_date,last_week_date,dates } = li;
  let end= [
    {x:formatDausXaxies(allocate_date),y:parseInt(dau_initial )||0    ,          label1:(allocate_date+": "+(dau_initial ||0 ))   },
    {x:formatDausXaxies(last_month_date),y:parseInt(last_month_dau)||0  ,          label1:(last_month_date+": "+(last_month_dau ||0 ))  },
    {x:formatDausXaxies(last_week_date),y:parseInt(last_week_dau)||0 ,        label1:(last_week_date+": "+(last_week_dau ||0 ))  }
    ];
  if(daus)
  {
    let dausArr=daus.split(",");
    let datesArr = dates.split(",");
    for (let i = dausArr.length-1; i >=0; i--)
    {
      let dateStr = datesArr[i];
      let dateShort = formatDausXaxies(dateStr);
      end.push({x: dateShort, y: parseInt(dausArr[i]) ||0   ,
        label1:(dateStr+": "+(dausArr[i] ||0 ))
      });
    }
  }
  return end;
}
function formatDausXaxies(dateStr)
{
  if ( !dateStr) return '00-00';
  if (dateStr.indexOf(" "))
  {
    dateStr=dateStr.split(" ")[0];
  }
  let [y,m,d] = dateStr.split("-");
  return m+"."+d;

}

function fillSurvey(li)
{
  let { survey } = li;
  if ( survey )
  {
    return JSON.parse(survey);
  }
  return null;
}