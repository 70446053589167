
import React, {Component} from 'react';
import {Button, DatePicker, Input} from "element-react";
import {EDIT_TASK_TYPE_description, EDIT_TASK_TYPE_planRealDateFinish, EDIT_TASK_TYPE_remark} from "../consts";
import PropTypes from "prop-types";
import TimeUtil, {MONTH2, WEEK2,DAY} from "../TimeUtil";
import "./TaskAddPanel.css"

export default class TaskAddPanel extends Component
{
  static propTypes = {
    onSaveCb : PropTypes.func.isRequired,
    isSubTask : PropTypes.bool.isRequired,
    weekDateStart : PropTypes.any.isRequired
  };

  constructor(props)
  {
    super(props);
    let {weekDateStart ,isSubTask}=this.props;
    let weekDateStartVal = TimeUtil.parseTimeString(weekDateStart);
    let  dateMin = weekDateStartVal;
    let  dateMax = new Date(weekDateStartVal.valueOf()+ (WEEK2-DAY));
    this.state={name :"",date:new Date() ,dateMin,dateMax};
  }
  componentDidMount()
  {
  }
  render(){
    let {name,date,dateMin,dateMax}=this.state;
    return (
      <div className="TaskAddPanel">

        <div className="form">

          <div className="kvp">
            <div className="label">任务名称</div>
            <div className="value">

              <Input className={"full-width"} type={"text"}  value={name}
                     onChange={this.onNameChange.bind(this)}
              />

            </div>
          </div>


          <div className="kvp">
            <div className="label">计划完成时间</div>
            <div className="value">

              <DatePicker
                firstDayOfWeek={1}
                className={"full-width"}
                placeholder="选择日期"
                value={date}
                disabledDate={(d)=>{
                  return d<dateMin || d > dateMax;
                }}
                onChange={this.onDateChange.bind(this)}
              />

            </div>
          </div>

        </div>

        {name && date && <div className="saveBtn" onClick={this.onClickSave.bind(this)}>
          提交
        </div>}

      </div>);
  }
  onDateChange(val)
  {
    this.setState({date: val});
  }
  onNameChange (val)
  {
    this.setState({name:val});
  }


  onClickSave (){
    let {name,date}=this.state;
    let dbDate = TimeUtil.formatDBDate2(date);

    this.props.onSaveCb({name,date,dbDate});
  }

}