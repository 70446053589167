import {openProjectId, setLoading, setProjectList, setRecentResultList, setWeekReportDetail} from "../actions";
import Facade from "../Facade";

/** 加载周报基础信息 ，同时会打开所属项目 */
export default function runRefreshRecentResults(weeklyId, dispatch) {
  dispatch(setLoading("recentList", true));
  Facade.gw.getRecentList(weeklyId, 1,2,(recentList1) => {
    dispatch (setRecentResultList(1,recentList1));
    Facade.gw.getRecentList(weeklyId, 2,2,(recentList2) => {
      dispatch (setRecentResultList(2,recentList2));
      dispatch(setLoading("recentList", false));
    });
  });

}
