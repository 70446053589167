import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Select, Dialog, Icon, Dropdown} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerOpen, customerUpdate, loginLogin, setAdminList, setSearchList} from "../actions";
import Facade from "../Facade";

import "./TopBar.css";
import TopSearch from "./TopSearch";
import TimeUtil from "../TimeUtil";


const {Row, Col} = Layout;
class TopBar extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {isLoading: false, value: null, dialogProj: false,dialogReport:false};
  }

  componentDidMount()
  {
  }

  render()
  {
    let {list,nickName,avatarUrl, dispatch} = this.props;
    let time = new Date();
    let displayTime= TimeUtil.formatYmd(time);
    let amPm = time.getHours()<12?"早上":"下午";
    return (
      <div className="TopBar ">
        <img className="foldIcon" src={require("../res/1026/周报/2.0/2_0_menu.png")}/>

        <TopSearch/>


        <div className="gap"></div>
        <div className="welcomeTf">
          {displayTime} {amPm}好！ <div className="nickname">{nickName || "未登录"}</div>
        </div>

        <div className="userbref">
          <img className="usericon" src={avatarUrl || "https://wwwcenter-10019656.cos.ap-shanghai.myqcloud.com/bdbim-demo/gentelella-master/production/images/img.jpg"}/>
          {/*<div className="rightCol">*/}
          {/*  <span className="username">{nickName || "未登录"}</span>*/}
          {/*  <span className="userrole">{nickName?"用户" : "未登录"}</span>*/}
          {/*</div>*/}
        </div>





      </div>);
  }
  onCommand(command)
  {
    if ( command == "proj")
    {
      //新建项目
      this.setState({dialogProj:true});
    }else if ( command == "report")
    {
      this.setState({dialogReport:true});
    }

  }

  onSearch(query)
  {
    this.setState({isLoading: true});
    Facade.gw.getCustomerSearch(query, 1, (data) => {
      this.setState({list: data.list});
      this.setState({isLoading: false});
      this.props.dispatch(setSearchList(data.list));
    })
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    nickName: state.login.nickName,
    avatarUrl: state.login.avatarUrl,
    list: state.customers.searchList
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(TopBar);