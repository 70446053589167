import React, {Component} from "react";

/** 人员设备 */
export default class DailyPersonLi extends Component
{

  render()
  {
    let {organization, department, personNum} = this.props.data;
    return (<tr>
      <td>
        <div className="inputTfRow">
          <input className={"val"} value={organization} />
        </div>
      </td>
      <td>
        <div className="inputTfRow">
          <input className={"val"} value={department}/>
        </div>
      </td>
      <td>
        <div className="inputTfRow">
          <input className={"val"} value={personNum}/>
        </div>
      </td>
    </tr>);
  }

}