import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme, Rect
  , VictoryAxis, VictoryTooltip
} from "victory";
import {VCHART_THEME} from "../consts";
import TimeUtil from "../TimeUtil";
import MBScrollViewHelper from "../MBScrollViewHelper";

const {Row, Col} = Layout;

class ChartBars3 extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      bar1Data: [
        // {x: "项目阶段", y: "2022-08-14", y0: '2022-07-01', color: "#4376ff"},
      ],
      bar2Data: [
        // {x: "项目阶段", y: "2022-08-13", y0: '2022-07-02', color: "#4376ff"},
      ],
      nameStyleDic:{}


    };
  }

  static propTypes = {
    data: PropTypes.array,
  }

  componentDidMount()
  {
    this.scrollViewHelper = new MBScrollViewHelper(this.ref, this.onReveal.bind(this));

    // //给一点 domain
    // let {data} = this.props;
    // let parsedData = this.parseData(data);
    // this.setState({domainDate:parsedData.domainDate});

  }

  componentWillUnmount()
  {
    this.scrollViewHelper.dispose();
  }

  onReveal(){
    this.scrollViewHelper.dispose();
    let {data} = this.props;
    let parsedData = this.parseData(data);
    this.setState({...parsedData});
  }

  parseData(rawData)
  {
    let bar1Data = [];
    let bar2Data = [];
    let nameStyleDic = {};
    let domainDate =[TimeUtil.parseTimeString("2022-05-01"), TimeUtil.parseTimeString("2022-09-14")];
    rawData.forEach((item, index) => {
      let {start, end, baselineStart, baselineEnd, name,bold} = item;
      bar1Data.unshift({x: name, y: TimeUtil.parseTimeString(end), y0: TimeUtil.parseTimeString(start)});
      bar2Data.unshift({
        x: name,
        y: TimeUtil.parseTimeString(baselineEnd),
        y0: TimeUtil.parseTimeString(baselineStart)
      });
      nameStyleDic[name] = {fontWeight:bold?800:400};
      if ( index == 0)
      {
        nameStyleDic[name].fill="#245dff";
      }
      //

    });

    return {bar1Data, bar2Data,nameStyleDic,domainDate};
  }

  render()
  {

    let {bar1Data, bar2Data,nameStyleDic,domainDate} = this.state;

    return (
      <div ref={(ref) => {this.ref = ref}}
        // style={{width: "442px",height:"162px",background:"red"}}
      >
        <VictoryChart
          domainPadding={{x:50,y:20}}
                      width={835} height={701}
                      padding={{top: 22, bottom: 50, left: 75, right: 50}}
                      animate={{duration: 1000}}
          // theme={VCHART_THEME}
        >
          <VictoryAxis dependentAxis
                       domain={domainDate}
                       style={{


                         axis: {stroke: "transparent"},//x轴
                         // axisLabel: {fill: "#757c88"},

                         // ticks: {stroke: "red", size: 0,fill:"red"},

                         tickLabels: {fill: "#757c88", fontSize: 10, padding: 5},//x轴刻度label
                         grid: {stroke: "#e1e1e1", strokeDasharray: "5 5"},//网格线 竖线
                       }}
                       tickFormat={(t) => {
                         return TimeUtil.formatDBDate2(new Date(t));
                       }}
          />
          <VictoryAxis
            style={{
              axis: {stroke: "#e1e1e1"},//y轴
              grid: {stroke: "#e1e1e1", strokeDasharray: "5 5"},//网格线 竖线
              tickLabels: {
                fill: (   ({text})=>{
                  if ( nameStyleDic[text] ) return nameStyleDic[text].fill;
                  return "#8e9093";
                }  )
                ,fontWeight:( ({text})=> {
                  if ( nameStyleDic[text] ) return nameStyleDic[text].fontWeight;
                return 400}), fontSize: 10, padding: 5},//y轴刻度label
            }}
          />

          <VictoryGroup horizontal
                        offset={13}
                        style={{data: {width: 6}}}
                        colorScale={["brown", "tomato", "gold"]}
          >
            <VictoryBar horizontal
                        style={{
                          data: {fill: "#2be7b1", width: 10},
                          labels: {fill: "#2be7b1", fontSize: 10, padding: 5}
                        }}
                        data={bar1Data}
                        // labels={({datum}) => TimeUtil.formatBrefDate(new Date(datum.y))}
            />
            <VictoryBar horizontal
                        style={{
                          data: {fill: "#245dff", width: 10},
                          labels: {fill: "#245dff", fontSize: 10, padding: 5}
                        }}
                        data={bar2Data}
            />
          </VictoryGroup>

          {/*{data.map(this.renderTuli.bind(this,442,248))}*/}
          {/*{data && data.length && (this.renderTuli(442, 248, {...data[0], x: "完成度"}, 0, [data[0]]))}*/}
          {this.renderTuli(835, 701, {x:"基线",color:"#245dff"}, 0, [1,2])}
          {this.renderTuli(835, 701, {x:"实际",color:"#2be7b1"}, 1, [1,2])}

        </VictoryChart>

      </div>
    );
  }

  renderTuli(svgW, svgH, li, index, arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 50;
    let x = (svgW - arr.length * liW) / 2 + index * liW;
    let x2 = x + 12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }
}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartBars3);