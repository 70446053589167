
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Dialog,Icon} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import "./GalleryPanel.css";

const {Row,Col} =Layout;
class GalleryPanel extends Component
{
  static propTypes = {
    /*"pictures": "[1.jpg,2.jpg]"*/
    pictureList : PropTypes.array,
    picture : PropTypes.string.isRequired,
    onCancel: PropTypes.func
  };


  constructor(props)
  {
    super(props)
    this.state={pictureList :[] , curImg:"",curImgIndex :0,hasNext:false,hasPrev:false};
  }


  static getDerivedStateFromProps(nextProps, prevState)
  {
    if ( nextProps.pictureList && nextProps.pictureList != prevState.pictureList)
    {
      let curImg = nextProps.picture;
      let curImgIndex = nextProps.pictureList.indexOf(curImg);
      return {pictureList: nextProps.pictureList , curImg,curImgIndex , hasNext:curImgIndex<nextProps.pictureList.length-1, hasPrev: curImgIndex>0};
    }
  }

  componentDidMount()
  {
  }
  componentWillMount()
  {
  }

  componentWillUnmount()
  {
    Facade.removeListener()
  }

  render(){

    let {curImg,hasNext,hasPrev} = this.state;
    return <Dialog
      title="查看图片"
      visible={true}
      onCancel={this.props.onCancel}
    >
      <div className="GalleryPanel" >
        <img className={"dialogImg"}   src={curImg} alt="" />
        {hasPrev && <div className="pageBtn" onClick={this.onClickPrev.bind(this)}><Icon name={"arrow-left"}/> </div>}
        {hasNext && <div className="pageBtn right" onClick={this.onClickNext.bind(this)}><Icon name={"arrow-right"}/></div>}
      </div>
    </Dialog>

  }

  onClickNext(){
    let {pictureList} = this.props;
    let {curImgIndex}= this.state;
    curImgIndex++;
    let curImg = pictureList[curImgIndex];
    this.setState({pictureList: pictureList , curImg,curImgIndex , hasNext:curImgIndex<pictureList.length-1, hasPrev: curImgIndex>0})
  }
  onClickPrev(){
    let {pictureList} = this.props;
    let {curImgIndex}= this.state;
    curImgIndex--;
    let curImg = pictureList[curImgIndex];
    this.setState({pictureList: pictureList , curImg,curImgIndex , hasNext:curImgIndex<pictureList.length-1, hasPrev: curImgIndex>0})
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList:state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(GalleryPanel);