import * as echarts from 'echarts';
import React, {Component} from 'react';

import {connect} from "react-redux";
class ManpowerChart extends Component
{


  componentDidMount()
  {

this.initChart2();

  }

  render()
  {
    return (

      <div className="manpower-chart">
        <div className="manpower-chart-content" style={{height: 300}}>
          <div id="contractsnag-chart" style={{width: '100%', height: '100%'}}></div>
        </div>
      </div>
    );
  }


  initChart2()
  {

    var chartDom = document.getElementById('contractsnag-chart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      legend: {
        data: ['Total', 'Completed', 'Completed rate']
      },
      xAxis: [
        {
          type: 'category',
          data: ['Feb', 'Mar','Apr', 'May' ],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Total',
          // min: 0,
          // max: 700,
          // interval: 100,
          axisLabel: {
            formatter: '{value}'
          }
        },
        {
          type: 'value',
          name: 'Completed rate',
          // min: 99,
          max: 100,
          axisLabel: {
            formatter: '{value}%'
          }
        }
      ],
      series: [
        {
          name: 'Total',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + '';
            }
          },
          data: [
            20,45,36,48
          ]
        },
        {
          name: 'Completed',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + '';
            }
          },
          data: [
            18,40,34,46
          ]
        },
        {
          name: 'Completed rate',
          type: 'line',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + '%';
            }
          },
          data: [95,89,94.9,95.83]
        }
      ]
    };

    option && myChart.setOption(option);
  }
}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {vo: state.customers.openCustomer};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ManpowerChart);