/**
 * 近期成果
 */
import {SET_RECENT_RESULT_LIST} from "../actions";

export default function recentResults(state = {
  list1:[],
  list2:[]
}, action) {
  switch (action.type) {
    case SET_RECENT_RESULT_LIST:
      if ( action.listType == 1  )
      {
        return {...state, list1: action.voArr.map(pollyfillVO)};
      }else {
        return {...state, list2: action.voArr.map(pollyfillVO)};
      }
    default:
      return state;
  }
}
function pollyfillVO(li , i)
{
  return li;
}