import Notify from "./Notify";
import Facade from "./Facade";
import {CLIENT_WWW,DEBUG,SERVER_API} from "./consts";

/**
 * Created by rhett on 2019/9/23.
 */
// const SERVER_API = "/api";
// const SERVER_API = "";



export default class Gateway
{

  //region 基本请求
  token = "";

  getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++)
    {
      var pair = vars[i].split("=");
      if (pair[0] == variable)
      {
        return pair[1];
      }
    }
    return (false);
  }


  //
  gwAlert = console.debug;
  gwToast = console.debug;
  needloginCb= ()=>{this.gwToast('正在从飞书同步登录状态...','未登录');Facade.oauth.gotoOAuth()};

  gwSimplePost(api, extraArg, cb, errCb)
  {
    let formData = new FormData();
    for (let eachKey in extraArg)
    {
      formData.append(eachKey, extraArg[eachKey]);
    }

    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    if(DEBUG)console.log("POST", api, extraArg);
    fetch(SERVER_API + api, {headers, method: "post", credentials: "include", body: formData})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if(DEBUG)console.log(">POST", api, resp);
        if (resp.error == 0)
        {
          cb(resp.data);
        } else if ( resp.error==20003 ){
          this.needloginCb();
        } else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.error +":"+resp.message,resp.error==20001?api:null);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message,api);
    });

  }

  gwSimpleGet(api, extraArg, cb,errCb)
  {
    let url = SERVER_API + api;
    if (extraArg)
    {
      let args = [];
      for (let eachKey in extraArg)
      {
        args.push(eachKey + "=" + extraArg[eachKey]);
      }
      if (args.length)
        url = url + "?" + args.join("&");
    }
    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    if(DEBUG)console.log("GET", api, extraArg,this.token);
    fetch(url, {headers, method: "get", credentials: "include"})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if(DEBUG)console.log(">GET", api, resp);
        if (resp.error == 0)
        {
          cb(resp.data);
        } else if ( resp.error==20003 ){
          this.needloginCb();
        }else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.error +":"+resp.message,resp.error==20001?api:null);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message,api);
    })
  }
  //endregion

  /**************** 上传COS ******************/
  //region 上传COS
  /**
   * 获取临时秘钥
   * get：/cos/getCredential
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "credentials": {
   *             "tempSecretId": "AKIDO_iV8Xj4Dx2M657KgKU4D5Tnv-bk01HCzJMH7ot1PqdT8mDR79xKQng5eUQCR_W5",
   *             "tmpSecretKey": "XFK4c8u4q/UXZ44Db1xkBObNTbYvkHMLnGlep+nsakI=",
   *             "sessionToken": "txL120yrUSZDXujN1hyHmM33FtPZJkCa4e79ecee48a2a75e0c16594c69768353oE0cSeRhCp0OZp71O9SqbMOXzXlN0y8Mpkzp0TzqUxgxMV_FNbO2uKFSr3CS7UfC3kSG1Jqw_G_U8j2DQutf4mSCRKPi742Em8P1q7Wqqu_nMkNiCgGGf4YFrupxZgT4c_Pg3Hr0ZXlyYTzo_3deO_miyhPp2ma1cmjPmC4kdruOuGgWVyjLuzmSkZGZm7URcbOMrsT1zvRh8XY0lP8S8GGQd2H8yZ1P1co0QhVQwEGArDw2e26HmD-pSOIk6FLTbOcW1WmKMgBQp0-Wnq52L0NGbprZOjBXoP9FX-0Za8yeefbKhwqo17a7tvBVeCEbc02axebZu_y86kL6G6RKhosX0-31j5Yu0UpAx15nPfNEGH42oY13vzn9pzgYTnAwsskDnGHXEqrkfABKk0M2nErmrKqiP01AIY7AuWGPCankYbZpDAPbD18FstRHe9wc7TMud0Bf3WYp-c6autYyoiTexCy8WtmUrCIskkT3AOGc_hgvqqjqtVeIX3_FtK6cND25wiyvHX7MFVpMFLgbDSia-Cdies5V_Af7Ywdxpz-AhhgARoTPaUMVdx45PRa5"
   *         },
   *         "startTime": "2022-11-29 09:35:40",
   *         "expiredTime": "2022-11-29 10:05:40"
   *     }
   * }
   */
  getStsAuth(cb){
    this.gwSimpleGet("/cos/getCredential",{},cb);
  }
  //endregion


  /**************** MPP文件处理 ******************/

  //region mpp文件处理
  /** 上传 */
  getUploadGateway(){
    return SERVER_API + "/file/upload";
  }
  getUploadHeaders(){
    return {rest:'rest',token: this.token}
  }

  //endregion


  /**************** 周报/任务 ******************/


  //region 周报/任务

  /**
   * 手动创建周报（新）
   * post：/weekly/post
   * 参数：
   * - projectId：项目id
   * - dateStart：开始日期，只能是周一 结束日期自动为双周周日
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  weeklyPost(projectId , dateStart,cb,errCb){
    this.gwSimplePost("/weekly/post",{projectId,dateStart},cb,errCb);
  }


  /**
   * 周报详情
   * get：/weekly/detail
   * 参数：
   * id：周报id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 1,   //周报id
   *         "projectId": 1,   //项目id
   *         "dateStart": "2022-05-22",   //开始日期
   *         "dateEnd": "2022-07-22",   //结束日期
   *         "isDelete": 0,
   *         "createTime": "2022-07-29 10:54:09"   //创建时间
   *     }
   * }
   */
  getWeeklyDetail(id, cb)
  {
    this.gwSimpleGet('/weekly/detail',{id},cb);
  }

  /**
   *  我的任务列表 - 不分级（新）√
   * get：/task/myTask
   *
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
     *      "dateStart": 2022-11-14,
     *      "dateEnd": 2022-11-27,
   *         "task": [
   *             {
   *                 "id": 23,
   *                 "projectId": 22,
   *                 "taskId": 24,
   *                 "taskUniqueId": 653,
   *                 "taskParentId": 2,
   *                 "taskType": "42FS",
   *                 "taskLineCategory": null,
   *                 "taskOutLineNumber": "1.1.6",
   *                 "taskOutLineLevel": 3,
   *                 "taskName": "厨房深化设计",
   *                 "taskPercentageComplete": "0.0%",
   *                 "taskDuration": 30,
   *                 "taskBaseLineStart": "2022-10-22",
   *                 "taskBaseLineFinish": "2022-11-20",
   *                 "taskStartDate": "2022-10-22",
   *                 "taskFinishDate": "2022-11-20",
   *                 "taskPredecessors": "42FS",
   *                 "taskSuccessors": "631FS",
   *                 "taskCritical": 0,
   *                 "taskLight": null,
   *                 "description": null,
   *                 "remark": null,
   *                 "taskUid": "[1]",
   *                 "managerUid": null,
   *                 "responsibleDepartmentUid": null,
   *                 "planRealFinishDate": null,
   *                 "isSubtask": 1,
   *                 "isDelete": 0,
   *                 "createTime": "2022-11-14 15:06:22",
   *                 "taskUserList": null,
   *                 "managerUserList": null,
   *                 "responsibleDepartmentUserList": null,
   *                 "projectName": "测试项目",  //项目名
   *                 "status": 1,
   *                  "weeklyId": 22  // 周报id
   *             }
   *         ],
   *         "typeNum": {
   *             "1": 1,  //正常
   *             "2": 0,  //延期7天以内
   *             "3": 0,  //延期7天（含）以上
   *             "4": 0  //完成
   *         }
   *     }
   * }
   */
  getMyTask(cb){
    /*cb({
        "dateStart": "2022-11-14",
        "dateEnd": "2022-11-27",
        "task": [
          {
            "id": 23,
            "projectId": 22,
            "taskId": 24,
            "taskUniqueId": 653,
            "taskParentId": 2,
            "taskType": "42FS",
            "taskLineCategory": null,
            "taskOutLineNumber": "1.1.6",
            "taskOutLineLevel": 3,
            "taskName": "厨房深化设计厨房深化设计",
            "taskPercentageComplete": "0.0%",
            "taskDuration": 30,
            "taskBaseLineStart": "2022-10-22",
            "taskBaseLineFinish": "2022-11-20",
            "taskStartDate": "2022-10-22",
            "taskFinishDate": "2022-11-20",
            "taskPredecessors": "42FS",
            "taskSuccessors": "631FS",
            "taskCritical": 0,
            "taskLight": null,
            "description": null,
            "remark": null,
            "taskUid": "[1]",
            "managerUid": null,
            "responsibleDepartmentUid": null,
            "planRealFinishDate": null,
            "isSubtask": 1,
            "isDelete": 0,
            "createTime": "2022-11-14 15:06:22",
            "taskUserList": null,
            "managerUserList": null,
            "responsibleDepartmentUserList": null,
            "projectName": "测试项目目目",  //项目名
            "status": 1,
            "weeklyId": 22  // 周报id
          }
        ],
        "typeNum": {
          "1": 1,  //正常
          "2": 0,  //延期7天以内
          "3": 0,  //延期7天（含）以上
          "4": 0  //完成
        }
      })*/
    this.gwSimpleGet("/task/myTask",{},cb);
  }


  /**
   * todo 指定项目任务列表（新）
   * 自动获取当前项目，今日所在的周报
   * get：/task/projectTask
   * 参数：
   * - weeklyId：周报id
   * - Type: 默认2，1 我的任务（不分层级）， 2 全部任务（分层级）
   * 返回：
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "task": [
   *             {
   *                 "id": 23,
   *                 "projectId": 22,
   *                 "taskId": 24,
   *                 "taskUniqueId": 653,
   *                 "taskParentId": 2,
   *                 "taskType": "42FS",
   *                 "taskLineCategory": null,
   *                 "taskOutLineNumber": "1.1.6",
   *                 "taskOutLineLevel": 3,  //该task层级，最高级别为2
   *                 "taskName": "厨房深化设计",
   *                 "taskPercentageComplete": "0.0%",
   *                 "taskDuration": 30,
   *                 "taskBaseLineStart": "2022-10-22",
   *                 "taskBaseLineFinish": "2022-11-20",
   *                 "taskStartDate": "2022-10-22",
   *                 "taskFinishDate": "2022-11-20",
   *                 "taskPredecessors": "42FS",
   *                 "taskSuccessors": "631FS",
   *                 "taskCritical": 0,
   *                 "taskLight": null,
   *                 "description": null,
   *                 "remark": null,
   *                 "taskUid": "[1]",//任务责任人
   *                 "managerUid": null,//节点责任人
   *                 "responsibleDepartmentUid": null,//项管/QS责任人
   *                 "planRealFinishDate": null,
   *                 "isSubtask": 1,
   *                 "isDelete": 0,
   *                 "createTime": "2022-11-14 15:06:22",
   *                 "taskUserList": null,
   *                 "managerUserList": null,
   *                 "responsibleDepartmentUserList": null,
   *                 "projectName": "测试项目",  //项目名
   *                 "status": 1
   *             }
   *         ],
   *         "typeNum": {
   *             "1": 1,  //正常
   *             "2": 0,  //延期7天以内
   *             "3": 0,  //延期7天（含）以上
   *             "4": 0  //完成
   *         },
   *   "weekly": {
   *           "id": 1,   //周报id
   *           "projectId": 22,   //项目id
   *           "dateStart": "2022-05-22",   //开始日期
   *           "dateEnd": "2022-07-22",   //结束日期
   *           "isDelete": 0,
   *           "createTime": "2022-07-29 10:54:09"   //创建时间
   *       }
   *     }
   * }
   */
  getProjectTasks(weeklyId , type,cb){
    this.gwSimpleGet("/task/projectTask",{weeklyId,type,Type:type},cb);
  }



  /**
   *  周报任务编辑（新）
   * post：/task/editTask
   * 参数：
   * - taskType：4节点负责人，5任务负责人，6项管/设计院/QS责任人，7进度描述，8完成时间，9计划/实际完成时间，10备注，11修改任务名
   * - taskId：所在task的id
   * - taskEdit：修改的内容，taskType为4，5，6需要传json数组[1,2,3]
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 2,
   *         "weeklyId": 1,
   *         "taskId": 3,
   *         "taskUniqueId": 3,
   *         "taskParentId": 2,
   *         "taskType": "",
   *         "taskLineCategory": "土地",
   *         "taskOutLineNumber": "1.1.1",
   *         "taskOutLineLevel": 3,
   *         "taskName": "土地摘牌",
   *         "taskPercentageComplete": "96.0%",
   *         "taskDuration": 26,
   *         "taskBaseLineStart": "2021-09-01",
   *         "taskBaseLineFinish": "2021-09-26",
   *         "taskStartDate": "2021-09-01",
   *         "taskFinishDate": "2021-09-26",
   *         "taskPredecessors": "",
   *         "taskSuccessors": "5FS,56FS+22 DAYS,57FS+-30 DAYS,58FS+-30 DAYS,60FS+22 DAYS",
   *         "taskCritical": 0,
   *         "taskLight": 4,
   *         "professionalId": 1,
   *         "professionalName": "土地",
   *         "description": null,
   *         "remark": null,
   *         "taskUid": "[1,2]",
   *         "managerUid": "[1]",
   *         "responsibleDepartment": null,
   *         "planRealFinishDate": null,
   *         "isDelete": 0,
   *         "createTime": "2022-07-29 02:23:51",
   *         "taskUserList": null,
   *         "managerUserList": null
   *     }
   * }
   */
  /** 修改任务字段 4~11  调用字段有改动 */
  editTask(taskType,taskId,taskEdit,cb){
    this.gwSimplePost('/task/editTask',{taskType,taskId,taskEdit},cb);
  }

  /**
   *  创建新任务（新）
   * post：/task/addTask
   * 参数：
   * - projectId：项目id
   * - weeklyId：周报id
   * - taskName：任务名
   * - taskParentId：父任务id，如果是顶级则传1
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 2,
   *         "weeklyId": 1,
   *         "taskId": 3,
   *         "taskUniqueId": 3,
   *         "taskParentId": 2,
   *         "taskType": "",
   *         "taskLineCategory": "土地",
   *         "taskOutLineNumber": "1.1.1",
   *         "taskOutLineLevel": 3,
   *         "taskName": "土地摘牌",
   *         "taskPercentageComplete": "96.0%",
   *         "taskDuration": 26,
   *         "taskBaseLineStart": "2021-09-01",
   *         "taskBaseLineFinish": "2021-09-26",
   *         "taskStartDate": "2021-09-01",
   *         "taskFinishDate": "2021-09-26",
   *         "taskPredecessors": "",
   *         "taskSuccessors": "5FS,56FS+22 DAYS,57FS+-30 DAYS,58FS+-30 DAYS,60FS+22 DAYS",
   *         "taskCritical": 0,
   *         "taskLight": 4,
   *         "professionalId": 1,
   *         "professionalName": "土地",
   *         "description": null,
   *         "remark": null,
   *         "taskUid": "[1,2]",
   *         "managerUid": "[1]",
   *         "responsibleDepartment": null,
   *         "planRealFinishDate": null,
   *         "isDelete": 0,
   *         "createTime": "2022-07-29 02:23:51",
   *         "taskUserList": null,
   *         "managerUserList": null
   *     }
   * }
   */
  addTask(projectId,weeklyId,taskParentId,taskName,taskFinishDate,cb)
  {
    this.gwSimplePost('/task/addTask',{projectId,weeklyId,taskName,taskParentId,taskFinishDate},cb);
  }

  /**
   * 删除任务（task）
   * post：/task/delete
   * 参数：
   * - taskId：task的id（注意不是task.taskId）
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  deleteTask(taskId ,cb ){
    this.gwSimplePost("/task/delete",{taskId},cb);
  }

  getTaskDetail(taskId,cb)
  {
    this.gwSimpleGet('/task/detail',{taskId},cb);
  }

  /**
   * 删除周报（新）
   * post：/weekly/delete
   * 参数：
   * weeklyId：周报id
   * - isDelete：默认1，1删除，2找回
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  deleteWeekly(weeklyId,cb)
  {
    this.gwSimplePost('/weekly/delete',{weeklyId,isDelete:1},cb);
  }
  restoreWeekly(weeklyId,cb){
    this.gwSimplePost('/weekly/delete',{weeklyId,isDelete:2},cb);
  }

  /**
   * 任务操作记录
   * get：/taskOperatingRecord/list
   * 参数：
   * - taskId：任务id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "parentTaskName": "设计及深化设计",   //母任务名
   *         "taskOperatingRecord": [  //操作记录
   *             {
   *                 "id": 1,
   *                 "taskId": 1,
   *                 "userName": "柳闽泉",
   *                 "content": "编辑了任务周进展",
   *                 "createTime": "2022-12-14 14:11:28"
   *             }
   *         ],
   *         "taskName": "设计及深化设计"  //任务名
*         }
   * }
   */
  getTaskOperatingRecord(taskId , cb ){
    this.gwSimpleGet("/taskOperatingRecord/list",{taskId},cb);
  }

  /**
   * 任务分配记录
   * get：/taskAllocatingRecord/list
   * 参数：
   * - projectId：项目id
   * 返回：
   * {
   *    "error": 0,
   *    "message": "success",
   *    "data": {
   *       "weeklyName": "2022-12-12 - 2022-12-25",
   *       "taskAllocatingRecord": [
   *          {
   *             "id": 1,
   *             "taskId": 5,
   *             "projectId": 2,
   *             "taskName": "办公区域精装修深化设计及报审",  //任务名
   *             "managerUid": "[85]",
   *             "allocatingManagerUid": 85,
   *             "allocatingManagerTime": "2022-12-17 16:10:54",   //分配节点负责人时间
   *             "taskUid": "[85,86]",
   *             "allocatingTaskUid": 85,
   *             "allocatingTaskTime": "2022-12-17 16:38:10",  //分配任务责任人时间
   *             "responsibleDepartmentUid": "[85]",
   *             "allocatingResponsibleDepartmentUid": 85,
   *             "allocatingResponsibleDepartmentTime": "2022-12-17 16:38:40",  //分配项管/设计院/QS时间
   *             "createTime": "2022-12-15 14:01:30",
   *             "allocatingManagerUser": {  //分配节点责任人的用户（项目负责人）
   *                "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                "nickName": "柳闽泉"
   *             },
   *             "allocatingTaskUser": {  //分配任务责任人的用户（节点负责人）
   *                "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                "nickName": "柳闽泉"
   *             },
   *             "allocatingResponsibleDepartmentUser": {  //分配项管/设计院/QS责任人的用户（节点负责人）
   *                "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                "nickName": "柳闽泉"
   *             },
   *    "managerUserList": [   //节点负责人
   *                {
   *                   "avatar_url": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                   "nickName": "柳闽泉"
   *                }
   *             ],
   *             "taskUserList": [  //任务责任人
   *                {
   *                   "avatar_url": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                   "nickName": "柳闽泉"
   *                }
   *             ],
   *             "responsibleDepartmentUserList": [  //项管/设计院/QS责任人
   *                {
   *                   "avatar_url": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                   "nickName": "柳闽泉"
   *                }
   *             ]
   *          }
   *       ],
   *       "projectName": "测试项目1"  //项目名
   *    }
   * }
   */
  getTaskAllocatingRecord(projectId,cb){
    this.gwSimpleGet("/taskAllocatingRecord/list",{projectId},cb);
  }

  /**
   * 飞书内任务编辑页
   * post：/task/editPage
   * 参数：
   * - taskId：任务id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "taskFinishDate": "2022-10-18",
   *         "weeklyName": "2022-12-12 - 2022-12-25",
   *         "taskName": "办公区域精装修深化设计及报审",   //任务名
   *   "parentTaskName": "合约采购",  //母任务名
   *         "projectName": "测试项目1",
   *         "responsibleDepartmentUser": [  //项管，设计院，QS责任人
   *             {
   *                 "id": 85,
   *                 "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *                 "tenantKey": "102b9a0ead6b975e",
   *                 "nickName": "柳闽泉",
   *                 "nickNamePinyin": "",
   *                 "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                 "gender": 0,
   *                 "phone": "",
   *                 "isResponsiblePerson": 0,
   *                 "registerTime": "2022-11-17 15:32:13"
   *             }
   *         ],
   *         "taskId": 5,
   *         "taskUser": [   //任务责任人
   *             {
   *                 "id": 85,
   *                 "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *                 "tenantKey": "102b9a0ead6b975e",
   *                 "nickName": "柳闽泉",
   *                 "nickNamePinyin": "",
   *                 "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                 "gender": 0,
   *                 "phone": "",
   *                 "isResponsiblePerson": 0,
   *                 "registerTime": "2022-11-17 15:32:13"
   *             }
   *         ],
   *         "status": 3
   *     }
   * }
   */
  getTaskEditInfo(taskId,cb){
    this.gwSimpleGet("/task/editPage",{taskId},cb);
  }

  //endregion

  /**************** 飞书内登录 ******************/
  //region 飞书内登录
  /**
   * 飞书内授权登陆
   * get：/login/byCode
   * 参数：
   * code：临时授权码
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": "0bccef9f-e286-42f2-9c9c-33dc8a10b594"  //token
   * }
   */
  loginByCode(code ,cb ){
    this.gwSimpleGet("/login/byCode",{code}, cb );
  }
  //endregion

  /**************** 用户 ******************/
  //region 用户
  /**
   * 用户搜索
   * get：/user/search
   * 参数：
   * search：搜索匹配用户名
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "openid": "ou_7c71eb56c6dfaee5f74fc150235e8adb",
   *             "tenantKey": "2d5150c6258f175d",
   *             "nickName": "柳闽泉",
   *             "nickNamePinyin": "liuminquan",
   *             "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_662f5bab-737c-4867-b13e-f71f00f07c5g~?image_size=72x72&cut_type=&quality=&",
   *             "gender": 1,
   *             "phone": "",
   *             "isResponsiblePerson": 1,
   *             "registerTime": "2021-05-13 16:41:56"
   *         }
   *     ]
   * }
   *
   */
  getUserSearch(search ,cb){
    // console.log("Gateway/getUserSearch",query);
    // cb(require('./testdata/testUserSearch.json'));
    this.gwSimplePost('/user/search', {search},cb);
  }

  /**
   * 获取当前登录者自己的用户信息
   * 获取用户信息
   * get：/user/getUserInfo
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 83,
   *         "openid": "ou_92d8a7006d5fe99f2242d3f295d5a9cd",
   *         "tenantKey": "2c3aae1e824d975d",
   *         "nickName": "柳闽泉",
   *         "nickNamePinyin": "Season Liu",
   *         "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/8ee7fc3d-3c2a-47c7-8cf5-4af6b72dfb2g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *         "gender": 0,
   *         "phone": "",
   *         "isResponsiblePerson": 0,
   *         "registerTime": "2022-09-27 17:57:28"
   *     }
   * }
   */
  getMine(cb)
  {
    this.gwSimpleGet("/user/getUserInfo",{},cb);
    // setTimeout(()=>{
    //   cb({id : 1,nickName:"陈冠宇",avatarUrl:"https://wwwcenter-10019656.cos.ap-shanghai.myqcloud.com/bdbim-demo/gentelella-master/production/images/img.jpg"});
    // },100);
  }
  //endregion


  /**************** 项目成员 ******************/
  //region 项目成员

  /**
   * 获取项目成员
   * get：/project/getMembers
   * 参数：
   * - projectId：项目id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "memberUserList": [ //项目成员
   *             {
   *                 "id": 88,
   *                 "openid": "ou_ddd9cea261c8020c4616bad82e60e786",
   *                 "tenantKey": "102b9a0ead6b975e",
   *                 "nickName": "测瑞德",
   *                 "nickNamePinyin": "测瑞德",
   *                 "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_2a5781e9-68cc-4759-9267-af4e8f7d3b5g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                 "gender": 0,
   *                 "phone": "",
   *                 "isResponsiblePerson": 0,
   *                 "registerTime": "2022-11-16 13:27:06"
   *             }
   *         ],
   *         "dailyUser": [  //日报负责人
   *             {
   *                 "id": 85,
   *                 "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *                 "tenantKey": "102b9a0ead6b975e",
   *                 "nickName": "柳闽泉",
   *                 "nickNamePinyin": "",
   *                 "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *                 "gender": 0,
   *                 "phone": "",
   *                 "isResponsiblePerson": 0,
   *                 "registerTime": "2022-11-17 15:32:13"
   *             }
   *         ],
   *         "projectUser": {  //项目负责人
   *             "id": 85,
   *             "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *             "tenantKey": "102b9a0ead6b975e",
   *             "nickName": "柳闽泉",
   *             "nickNamePinyin": "",
   *             "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *             "gender": 0,
   *             "phone": "",
   *             "isResponsiblePerson": 0,
   *             "registerTime": "2022-11-17 15:32:13"
   *         }
   *     }
   * }
   */
  getProjectMembers(projectId , cb){
    this.gwSimpleGet("/project/getMembers",{projectId}, cb );
  }

  /**
   * 添加/删除项目成员
   * POST：/project/editMembers
   * 参数：
   * - projectId：项目id
   * - memberUids：成员uid json数组[1,2,3]
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 85,
   *             "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *             "tenantKey": "102b9a0ead6b975e",
   *             "nickName": "柳闽泉",
   *             "nickNamePinyin": "",
   *             "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *             "gender": 0,
   *             "phone": "",
   *             "isResponsiblePerson": 0,
   *             "registerTime": "2022-11-17 15:32:13"
   *         }
   *     ]
   * }
   */
  editProjectMembers(projectId , memberUids ,cb){
    this.gwSimplePost("/project/editMembers",{projectId,memberUids}, cb);
  }

  /**
   * 编辑项目负责人
   * POST：/project/editProjectUser
   * 参数：
   * - projectId：项目id
   * - projectUid：项目负责人uid
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *             "id": 85,
   *             "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *             "tenantKey": "102b9a0ead6b975e",
   *             "nickName": "柳闽泉",
   *             "nickNamePinyin": "",
   *             "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *             "gender": 0,
   *             "phone": "",
   *             "isResponsiblePerson": 0,
   *             "registerTime": "2022-11-17 15:32:13"
   *         }
   * }
   *
   */
  editProjectUser(projectId , projectUid ,cb){
    this.gwSimplePost("/project/editProjectUser",{projectId,projectUid}, cb);
  }

  /**
   *
   * 编辑日报负责人
   * POST：/project/editDailyUsers
   * 参数：
   * - projectId：项目id
   * - dailyUids：日报日报负责人 uid json数组[1,2,3]
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 85,
   *             "openid": "ou_1e3b087087175e8cbbc98d45af64c904",
   *             "tenantKey": "102b9a0ead6b975e",
   *             "nickName": "柳闽泉",
   *             "nickNamePinyin": "",
   *             "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_d4d898bf-a01e-4db1-9bf4-3bf11e73727g~?image_size=72x72&cut_type=&quality=&format=png&sticker_format=.webp",
   *             "gender": 0,
   *             "phone": "",
   *             "isResponsiblePerson": 0,
   *             "registerTime": "2022-11-17 15:32:13"
   *         }
   *     ]
   * }
   */
  editProjectDailyUsers(projectId , dailyUids ,cb){
    this.gwSimplePost("/project/editDailyUsers",{projectId,dailyUids}, cb);
  }
  //endregion

  /**************** 项目 ******************/

  //region 项目
  /**
   * 添加项目
   * @deprecated
   * post：/project/post
   * 参数：
   * name：项目名称
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  postProject(name, cb)
  {
    this.gwSimplePost('/project/post',{name},cb);
  }

  /**
   * todo 添加项目（新）
   * post：/project/post
   * 参数：
   * - projectName：项目名
   * - uid：项目负责人，默认为创建者本人
   * - dailyUids：日报负责人，json数组
   * - filePath：文件地址：file/upload接口返回的地址
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 19,
   *         "name": "测试项目",
   *         "status": 0,
   *         "uid": 1,
   *         "dailyUids": "[1,2]",
   *         "isDelete": 0,
   *         "createTime": "2022-11-14 14:07:49"
   *     }
   * }
   */
  createProject(projectName,uid,dailyUids,filePath,cb){
    this.gwSimplePost("/project/post",{projectName,uid,dailyUids,filePath},cb);

  }

  /**
   *  修改项目（新）
   * post：/project/rePost
   * 参数：
   * - projectId：项目id
   * - projectName：项目名
   * - filePath：文件地址：file/upload接口返回的地址
   */
  recreateProject(projectId,projectName,filePath,cb){
    if (filePath)
      this.gwSimplePost("/project/rePost",{projectId,projectName,filePath},cb);
    else
      this.gwSimplePost("/project/rePost",{projectId,projectName},cb);
  }

  /**
   * 编辑项目
   * 包含项目归档、项目激活、删除项目，找回项目
   * post：/project/edit
   * 参数：
   * - id：项目id
   * - status：1归档，2激活，3删除，4找回
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  editProject(id,status ,cb){
    this.gwSimplePost('/project/edit',{id,status},cb);
  }
  deleteProject(id,cb) { this.editProject(id,3,cb);}
  restoreProject(id,cb){ this.editProject(id,4,cb);}
  archiveProject(id,cb){ this.editProject(id,1,cb);}
  unarchiveProject(id,cb){ this.editProject(id,2,cb);}

  /**
   * 已归档项目列表
   * get：/project/archive
   *
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "name": "上海滨江中心",
   *   "status": "1", //1为已归档
   *   "is_delete":0,
   *             "createTime": "2022-07-13 16:31:44"
   *         }
   *     ]
   * }
   */
  getArchiveProjectList(cb){
    this.gwSimpleGet('/project/archive',{},cb);
  }

  /**
   * 项目列表
   * get：/project/list
   *
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "name": "上海滨江中心",
   *             "createTime": "2022-07-13 16:31:44"
   *         }
   *     ]
   * }
   */
  getProjectList(cb)
  {
    this.gwSimpleGet('/project/list',{},cb);
  }

  /**
   * 当前项目周报列表（新）
   * get：/project/weeklyList
   * 参数：
   * projectId：项目id
   * dateStart：开始日期，月份第一天 格式 2022-05-01
   * dateEnd：结束日期， 月份最后一天 格式 2022-05-31
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "updateTime": "2022-07-22 21:36:04",   //最后更新时间
   *             "weekly_name": "2022/05/22-2022/07/22"    //周报名
   *         }
   *     ]
   * }
   */
  getProjectWeeklyList(projectId,dateStart,dateEnd,cb)
  {
    this.gwSimpleGet('/project/weeklyList',{projectId,dateStart,dateEnd},cb);
  }
  //endregion



  /**************** 近期成果和近期计划 ******************/
  //region 近期成果和近期计划
  /**
   * 添加近期成果或计划 （新）
   * post：/recent/post
   * 参数：
   * weeklyId：周报id
   * type：1为近期成果，2为近期计划
   * resultPlan：内容
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  postRecent(weeklyId,type,resultPlan,cb)
  {
    this.gwSimplePost('/recent/post',{weeklyId,type,resultPlan} ,cb);
  }

  /**
   * 编辑近期成果或计划 （新）
   *
   * 数据库已对tick值取反操作，0代表不显示，1代表显示，对应前端的✔
   *
   * post：/recent/edit
   * 参数：
   * id：成果或计划id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  recentEdit(id,cb)
  {
    this.gwSimplePost('/recent/edit',{id},cb);
  }

  /**
   * 删除近期成果或计划（新）
   * post：/recent/delete
   * 参数：
   * id：成果或计划id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  recentDelete(id,cb)
  {
    this.gwSimplePost("/recent/delete",{id},cb);
  }


  /**
   * 获取近期成果或计划 （新）
   * get：/recent/list
   * 参数：
   * weeklyId：项目iprojectId：项目id
   * type（可选，默认1）：1为近期成果，2为近期计划
   * tick（可选，默认2）：2为全部， 1为显示（对应前端✔），0为不显示（对应前端不打勾）
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,   //近期成果或计划id
   * "weekly_id": 1,   //周报id
   *             "type": 1,   //1为近期成果，2为近期计划
   *             "resultPlan": "完成建筑方案设计满足上会要求",
   *             "tick": 1,   //1为打勾，0为不打勾
   *             "createTime": "2022-07-21 15:55:11"
   *         }
   *     ]
   * }
   */
  getRecentList(weeklyId,type,tick,cb)
  {
    this.gwSimpleGet('/recent/list',{weeklyId,type,tick},cb);
  }
  //endregion

  /**************** 最近打开 ******************/

  //region 最近打开
  /**
   * 最近打开周报列表
   * 返回最新10条数据
   * get：/open/list
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "uid": 1,
   *             "weeklyId": 1,   //周报id
   *             "weeklyName": "2022-05-22-2022-07-22",   //周报名
   *             "projectName": "上海滨江中心",   //项目名
   *             "openTime": "2022-08-01 16:12:31"   //最近打开时间
   *         }
   *     ]
   * }
   */
  getOpenList(cb)
  {
    this.gwSimpleGet('/open/list',{},cb);
  }

  /**
   * 删除记录
   * post：/open/delete
   * 参数：
   * id：记录id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   * }
   */
  openDelete(id,cb)
  {
    this.gwSimplePost('/open/delete',{id},cb);
  }
  //endregion

  /**************** 回收站 ******************/

  //region 回收站
  /**
   * 项目列表
   * get：/recycle/project
   *
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "name": "上海新江湾城",
   *             "status": 0,
   *             "isDelete": 1,
   *             "createTime": "2022-08-05 09:44:04"
   *         }
   *     ]
   * }
   */
  getRecycleProject(cb){
    this.gwSimpleGet('/recycle/project',{},cb);

  }

  /**
   * 周报列表
   * get：/recycle/weekly
   *
   * - page：默认1
   * - limit：默认10
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "total": 15,
   *         "data": [
   *             {
   *                 "id": 21,
   *                 "projectId": 1,
   *                 "dateStart": "2022-06-20",
   *                 "dateEnd": "2022-06-30",
   *                 "isDelete": 1,
   *                 "createTime": "2022-08-25 14:43:59",
   *                 "projectName": "上海新江湾城"
   *             }
   *         ]
   *     }
   * }
   */
  getRecycleWeekly(page,limit,cb){
    this.gwSimpleGet('/recycle/weekly',{page,limit},cb);

  }
  //endregion




  /**************** 日报 ******************/
  //region 日报

  /**
   * 首页我的日报
   * get：/daily/myDailyList
   *
   * 返回："error": 0,
   *     "message": "success",
   *     "data": {
   *         "dailyList": [
   *             {
   *                 "id": 5,
   *                 "projectId": 58,
   *                 "uids": "[85]",
   *                 "date": "2022-12-01",
   *                 "organization": "上海沪风/开古房地产开发有限公司新江湾城F区F1-A/B地块商办项目",
   *                 "projectName": "新江湾城F区F1-A/B地块商办项目",
   *                 "projectNumber": "60489416/60489510",
   *                 "projectAddress": "上海市杨浦区民府路602号/478号",
   *                 "reportNumber": "SHA-GF01-AECOM-DR-F1AB-113-20221016",
   *                 "weather": "{tempMax=20℃, textDay=阴, windScaleDay=3-4级, fxDate=2022-11-25, windDirDay=南风, tempMin=14℃}",
   *                 "isWeatherInfluence": 1,
   *                 "safetyManagement": "",
   *                 "createTime": "2022-11-29 14:46:37",
   *                 "updateTime": null,
   *                 "type": null,
   *                 "status": 0  //0未填写，1已填写
   *             }
   *         ],
   *         "unFinishDailyNum": 1  //待填写日报数量
   *     }
   */
  getMyDailyList(cb){
    this.gwSimpleGet("/daily/myDailyList",{},cb);
  }

  /**
   * 日报列表
   * get：/daily/list
   * 参数：
   * - projectId：项目id
   * - month：查看月份，格式：2022-11
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 5,
   *             "projectId": 58,
   *             "date": "2022-11-24",
   *             "organization": "上海沪风/开古房地产开发有限公司",
   *             "projectName": "新江湾城F区F1-A/B地块商办项目",
   *             "projectNumber": "60489416/60489510",
   *             "projectAddress": "上海市杨浦区民府路602号/478号",
   *             "reportNumber": "SHA-GF01-AECOM-DR-F1AB-113-20221016",
   *             "weather": null,
   *             "isWeatherInfluence": null,
   *             "safetyManagement": null,
   *             "createTime": null,
   *             "updateTime": "2022-11-24 16:54:27",
   *             "type": 1  //可根据该字段进行跳转，1为跳转日报编辑页，2为跳转基础信息编辑页，3为跳转分享页
   *         }
   *     ]
   * }
   */
  getDailyList(projectId,month,cb)
  {
    this.gwSimpleGet("/daily/list",{projectId,month},cb);
  }


  /**
   * 获取天气
   * get：/daily/getWeather
   * 参数：
   * - dailyId：日报id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "tempMax": "20℃",  //最高温
   *         "textDay": "阴",  //天气情况
   *         "windScaleDay": "3-4级",  //风力等级
   *         "fxDate": "2022-11-25",  //日期
   *         "windDirDay": "南风",  //风向
   *         "tempMin": "14℃"  //风向
   *     }
   * }
   */
  getDailyWeather(dailyId ,cb){
    this.gwSimpleGet("/daily/getWeather",{dailyId},cb);
  }

  /**
   * 获取人员信息
   * get：/daily/getDailyPerson
   * 参数：
   * - dailyId：日报id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "dailyId": 5,
   *             "organization": "总包",
   *             "department": "文明施工",
   *             "personNum": 56
   *         }
   *     ]
   * }
   */
  getDailyPerson(dailyId,cb){
    this.gwSimpleGet("/daily/getDailyPerson",{dailyId} ,cb);
  }

  /**
   * 获取设备信息
   * get：/daily/getDailyEquipment
   * 参数：
   * - dailyId：日报id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "dailyId": 5,
   *             "name": "曲臂式登高车",
   *             "model": "18M",
   *             "amount": 2
   *         }
   *     ]
   * }
   */
  getDailyEquipment(dailyId ,cb)
  {
    this.gwSimpleGet("/daily/getDailyEquipment",{dailyId},cb);

  }

  /**
   * 获取施工内容
   * get：/daily/getDailyConstructionContent
   * 参数：
   * - dailyId：日报id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "dailyId": 5,
   *             "dailyConstructionTypeId": 1,
   *             "dailyConstructionTypeName": "土建结构",
   *             "content": "1.F1A：T4钢结构防火涂料喷涂修补。。。",
   *             "pictures": "[1.jpg,2.jpg]"
   *         }
   *     ]
   * }
   */
  getDailyConstructionContent(dailyId ,cb){
    this.gwSimpleGet("/daily/getDailyConstructionContent",{dailyId},cb);
  }

  /**
   * 获取日报基础信息
   * get：/daily/getBasicInformation
   * 参数：
   * - dailyId：必填，日报id
   * 返回：
   *     {
   *             "id": 5,
   *             "projectId": 58,
   *             "date": "2022-11-24",
   *             "organization": "上海沪风/开古房地产开发有限公司",
   *             "projectName": "新江湾城F区F1-A/B地块商办项目",
   *             "projectNumber": "60489416/60489510",
   *             "projectAddress": "上海市杨浦区民府路602号/478号",
   *             "reportNumber": "SHA-GF01-AECOM-DR-F1AB-113-20221016",
   *             "createTime": "2022-11-25 12:00:58"
   *           }
   */
  getDailyBasicInfo(dailyId,cb)
  {
    this.gwSimpleGet("/daily/getBasicInformation",{dailyId},cb);
  }

  /**
   * 日报基础信息填写/编辑
   * post：/daily/postBasicInformation
   * 参数：
   * - dailyId：必填，日报id
   * - organization：非必填， 建设单位
   * - projectName：非必填， 项目名称
   * - projectNumber：非必填， 项目编号
   * - projectAddress：非必填， 项目地址
   * - reportNumber：：非必填， 报告编号
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyPostBasicInformation(dailyId,organization,projectName,projectNumber,projectAddress,reportNumber,cb){
    this.gwSimplePost("/daily/postBasicInformation",{dailyId,organization,projectName,projectNumber,projectAddress,reportNumber},cb);
  }


  /**
   * 添加设备信息
   * post：/daily/postEquipment
   * 参数：
   * - dailyId：日报id
   * - name：设备名
   * - model：设备型号
   * - amount：数量
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 2,
   *         "dailyId": 5,
   *         "name": "设备1",
   *         "model": "型号1",
   *         "amount": 3
   *     }
   * }
   */
  dailyPostEquipment(dailyId,name,model,amount,cb)
  {
    this.gwSimplePost("/daily/postEquipment",{dailyId,name,model,amount},cb);
  }

  /**
   * 编辑设备信息
   * post：/daily/postEquipment
   * 参数：
   * - dailyEquipmentId：必填，设备id
   * - name：非必填，设备名
   * - model：非必填，设备型号
   * - amount：非必填，数量
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyEditEquipment(dailyEquipmentId,name,model,amount,cb)
  {
    this.gwSimplePost("/daily/editEquipment",{dailyEquipmentId,name,model,amount},cb);
  }

  /**
   * 删除设备信息
   * post：/daily/editEquipment
   * 参数：
   * - dailyEquipmentId：设备id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyDelEquipment(dailyEquipmentId,cb)
  {
    this.gwSimplePost("/daily/delEquipment",{dailyEquipmentId},cb);
  }

  /**
   * 添加安全管理信息
   * post：/daily/postSafetyManagement
   * 参数：
   * - dailyId：日报id
   * - content：内容
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyEditSafetyMgmt(dailyId, content, cb)
  {
    this.gwSimplePost("/daily/postSafetyManagement",{content,dailyId},cb);
  }

  getDailySafetyMgmt(dailyId , cb ){
    this.gwSimpleGet("/daily/getSafetyManagement",{dailyId},cb);
  }

  /**
   * 施工类型列表
   * get：/daily/getDailyConstructionTypeList
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": 1,
   *             "name": "土建结构"
   *         }
   *     ]
   * }
   */
  getDailyConstructionTypeList(cb){
    this.gwSimpleGet("/daily/getDailyConstructionTypeList",{},cb);
  }

  /**
   * 添加施工类型
   * post：/daily/postConstructionType
   * 参数：
   * - name：类型名称
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyPostConstructionType(name,cb)
  {
    this.gwSimplePost("/daily/postConstructionType",{name},cb);
  }

  /**
   * 添加施工内容
   * post：/daily/postDailyConstructionContent
   * 参数：
   * - dailyId：日报id
   * - dailyConstructionTypeId：施工类型id
   * - content：施工内容
   * - pictures：图片json数组
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 2,
   *         "dailyId": 5,
   *         "dailyConstructionTypeId": 1,
   *         "dailyConstructionTypeName": "土建结构",
   *         "content": "内容。。。",
   *         "pictures": "[1.jpg,2.jpg]"
   *     }
   * }
   */
  dailyPostDailyConstructionContent(dailyId,dailyConstructionTypeId,content,pictures,cb)
  {
    this.gwSimplePost("/daily/postDailyConstructionContent",{dailyId,dailyConstructionTypeId,content,pictures},cb);
  }

  /**
   * 编辑施工内容
   * post：/daily/editDailyConstructionContent
   * 参数：
   * - dailyConstructionContentId： 必填，日报id
   * - dailyConstructionTypeId：非必填，施工类型id
   * - content：非必填，施工内容
   * - pictures：非必填，图片json数组
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "id": 2,
   *         "dailyId": 5,
   *         "dailyConstructionTypeId": 1,
   *         "dailyConstructionTypeName": "土建结构",
   *         "content": "内容。。。",
   *         "pictures": "[1.jpg,2.jpg]"
   *     }
   * }
   */
  dailyEditDailyConstructionContent(dailyConstructionContentId,dailyConstructionTypeId,content,pictures,cb)
  {
    this.gwSimplePost("/daily/editDailyConstructionContent",{dailyConstructionContentId,dailyConstructionTypeId,content,pictures},cb);
  }


  /**
   * 删除施工内容
   * post：/daily/delDailyConstructionContent
   * 参数：
   * - dailyConstructionContentId：施工内容id
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyDelDailyConstructionContent(dailyConstructionContentId,cb)
  {
    this.gwSimplePost("/daily/delDailyConstructionContent",{dailyConstructionContentId},cb);
  }


  /**
   * 是否受天气影响
   * post：/daily/isInfluence
   * 参数：
   * - dailyId：日报id
   * - isInfluence：1是0否
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success"
   * }
   */
  dailyIsInfluence(dailyId,isInfluence,cb)
  {
    this.gwSimplePost("/daily/isInfluence",{dailyId,isInfluence},cb);
  }

  //endregion

}