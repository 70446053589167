import {
  RESET_PROJECT_MEMBERS,
  SET_PROJECT_MEMBERS
} from "../actions";

/** 当前打开的项目 的成员 */
export default function projectMembers(state = {
  projectUser: 0,
  dailyUserList: [],
  memberUserList: [],
}, action) {
  switch (action.type)
  {
    case SET_PROJECT_MEMBERS:
      return {
        ...state,
        projectUser: action.projectUser,
        dailyUserList: action.dailyUserList,
        memberUserList: action.memberUserList
      };
    case RESET_PROJECT_MEMBERS:
      return {
        ...state, projectUser: 0,
        dailyUserList: [],
        memberUserList: []
      };

    default :
      return state;
  }
}
