import {
  OPEN_PROJECT_ID, SET_ARCHIVED_PROJECT_LIST, SET_DELETED_PROJECT_LIST,
  SET_PROJECT_DETAIL, SET_PROJECT_LIST
} from "../actions";

export default function projects(state = {
  projectList:[]
  , openedProjectId: 0
  , projectDetail: null
  , deletedProjectList:[]
  , archivedProjectList:[]
}, action) {
  switch (action.type)
  {
    case SET_PROJECT_LIST:
      let voArr = action.voArr.map(pollyfillProject);
      return {...state, projectList: voArr , projectDetail: matchOpenProject(voArr,state.openedProjectId)};
    case SET_PROJECT_DETAIL:
      return {...state , projectDetail:action.vo};
    case OPEN_PROJECT_ID:
      return {...state, openedProjectId: action.id,projectDetail: matchOpenProject(state.projectList,action.id)};
    case SET_DELETED_PROJECT_LIST:
      return {...state, deletedProjectList: action.voArr.map(pollyfillProject) };
    case SET_ARCHIVED_PROJECT_LIST:
      return {...state, archivedProjectList: action.voArr.map(pollyfillProject) };

    default :return state;
  }
}

function pollyfillProject(li, i)
{
  li.p = true;
  return li;
}
function matchOpenProject(projectList , openedProjectId){
  if ( openedProjectId ){
    let project = projectList.find(p => parseInt(p.id) === parseInt(openedProjectId));
    if ( project ){
      return project;
    }else{
    }
  }

}