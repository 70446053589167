import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, DatePicker} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList, updateWeekReportTaskDetail} from "../actions";
import Facade from "../Facade";
import "./LarkTaskEditPage.css";
import TimeUtil from "../TimeUtil";
import {EDIT_TASK_TYPE_description, EDIT_TASK_TYPE_planRealDateFinish, EDIT_TASK_TYPE_remark} from "../consts";

const {Row, Col} = Layout;

class LarkTaskEditPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {};
  }

  componentDidMount()
  {
    this.taskId = this.props.match.params.taskId;
    this.refreshData(this.taskId);
  }

  refreshData(taskId)
  {

    this.setState({data: null});
    Facade.gw.getTaskEditInfo(taskId, (data) => {
      this.setState({data});
      Facade.gw.getTaskOperatingRecord(taskId, ({parentTaskName, taskOperatingRecord, taskName}) => {
        this.setState({parentTaskName, taskOperatingRecord, taskName});
        Facade.gw.getTaskDetail(taskId, ({remark, description, planRealFinishDate}) => {
          this.modifiedKvp[EDIT_TASK_TYPE_planRealDateFinish] = planRealFinishDate;
          this.modifiedKvp[EDIT_TASK_TYPE_description] = description || "";
          this.modifiedKvp[EDIT_TASK_TYPE_remark] = remark || "";
          this.setState({remark, description, planRealFinishDate});
        })
      })
    });
  }


  //修改任务节点的字段
  reqTaskEdit(editType, value, cb)
  {
    Facade.gw.editTask(editType, this.taskId, value, (newVO) => {
      this.props.dispatch(updateWeekReportTaskDetail(newVO));
      if (cb != null) cb();
    });
  }

  render()
  {
    let {avatarUrl} = this.props;
    let {parentTaskName, taskOperatingRecord, taskName, data, remark, description, planRealFinishDate} = this.state;
    if (!data) return null;
    let {weeklyName, taskUser, responsibleDepartmentUser, status, taskFinishDate} = data;
    let planRealFinishDateAsDate = planRealFinishDate ? TimeUtil.parseTimeString(planRealFinishDate) : null;
    return (
      <div className="TaskEditPage">
        <div className="headline">
          {status == 1 && <div className="status status1">正常</div>}
          {status == 2 && <div className="status status2">延期</div>}
          {status == 3 && <div className="status status3">紧急</div>}
          {status == 4 && <div className="status status4">完成</div>}
          <div className="right">
            {parentTaskName && <div className="headtitle1">{parentTaskName}</div>}
            <div className="headtitle2">{taskName}</div>
          </div>
        </div>


        <div className="basicinfo">
          <div className="kvp">
            <div className="label">所属项目</div>
            <div className="value">{data.projectName}<br/>({weeklyName}）</div>
          </div>
          <div className="kvp">
            <div className="label">计划完成时间</div>
            <div className="value">{taskFinishDate}</div>
          </div>
          <div className="kvp">
            <div className="label">任务责任人</div>
            <div className="value">
              {taskUser && taskUser.map((li) => {
                return [
                  <div className="head" key={"user-avatar-" + li.id}>
                    <img src={li.avatarUrl} alt=""/>
                  </div>,
                  <div className="name" key={"user-nick-" + li.id}>{li.nickName}</div>]
              })}
            </div>
          </div>
          <div className="kvp">
            <div className="label">项管/设计院/<br/>QS责任人</div>
            <div className="value">
              {responsibleDepartmentUser && responsibleDepartmentUser.map((li) => {
                return [
                  <div className="head" key={"respon-avatar-" + li.id}>
                    <img src={li.avatarUrl} alt=""/>
                  </div>,
                  <div className="name" key={"respon-nick-" + li.id}>{li.nickName}</div>]
              })}
            </div>
          </div>
        </div>

        <div className="hr"></div>

        <div className="form">
          <div className="kvp">
            <div className="label">实际完成时间</div>
            <div className="value">

              <DatePicker
                className={"full-width"}
                value={planRealFinishDateAsDate}
                placeholder="选择日期"
                onChange={this.onDateChange.bind(this, EDIT_TASK_TYPE_planRealDateFinish)}
              />

            </div>
          </div>
          <div className="kvp">
            <div className="label">任务工作进度</div>
            <div className="value">

              <Input className={"full-width"} type={"textarea"} autosize={{minRows: 3}} defaultValue={description}
                     onChange={this.onTextChange.bind(this, EDIT_TASK_TYPE_description)}
              />

            </div>
          </div>
          <div className="kvp">
            <div className="label">备注/工作成果</div>
            <div className="value">

              <Input className={"full-width"} type={"textarea"} autosize={{minRows: 3}} defaultValue={remark}
                     onChange={this.onTextChange.bind(this, EDIT_TASK_TYPE_remark)}
              />

            </div>
          </div>
        </div>

        <div className="saveBtn" onClick={this.onClickSave.bind(this)}>
          提交
        </div>

        <div className="hr"></div>


        <div className="oprTitle">操作记录</div>
        <div className="oprList">
          {taskOperatingRecord && taskOperatingRecord.map((li) => {
            let t = TimeUtil.formatStringTime(li.createTime);
            return <div className="oprLi" key={"opr-" + li.id}><span>{li.userName}</span> {li.content} {t}</div>
          })}
        </div>


      </div>);
  }

  onDateChange(type, value)
  {
    this.modifiedKvp[type] = TimeUtil.formatDBDate2(value);

  }

  onTextChange(type, value)
  {
    this.modifiedKvp[type] = value;
  }

  modifiedKvp = {};

  onClickSave()
  {
    this.save1(()=>{
      this.save2( ()=>{
        this.save3( ()=>{
          this.refreshData(this.taskId);
        }) ;
      })
    })
  }

  save1(cb){
    let { description} = this.state;
    if (this.modifiedKvp[EDIT_TASK_TYPE_description] && description!=this.modifiedKvp[EDIT_TASK_TYPE_description])
      this.reqTaskEdit(EDIT_TASK_TYPE_description, this.modifiedKvp[EDIT_TASK_TYPE_description], cb );
    else cb();
  }
  save2(cb){
    let {  planRealFinishDate} = this.state;
    if ( this.modifiedKvp[EDIT_TASK_TYPE_planRealDateFinish] && this.modifiedKvp[EDIT_TASK_TYPE_planRealDateFinish] != planRealFinishDate)
      this.reqTaskEdit(EDIT_TASK_TYPE_planRealDateFinish, this.modifiedKvp[EDIT_TASK_TYPE_planRealDateFinish], cb);
    else cb();
  }
  save3(cb){
    let {  remark,} = this.state;
    if (this.modifiedKvp[EDIT_TASK_TYPE_remark] && remark!=this.modifiedKvp[EDIT_TASK_TYPE_remark])
      this.reqTaskEdit(EDIT_TASK_TYPE_remark, this.modifiedKvp[EDIT_TASK_TYPE_remark],cb);
    else cb();
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {    avatarUrl: state.login.avatarUrl,
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(LarkTaskEditPage);