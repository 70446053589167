
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default class PdfHelper{
  static exportPdf(selector ,cb)
  {

    if (!selector) selector = "#root";
//       注意：生成的pdf只有页面窗口可见的区域，有滚动条的下面没有生成出来（要注意这里是一个坑）
// 坑：如果截取是body的这个层级，而刚好body设置了overflow: hidden;那超出的部分是永远截取不到的，因为这个节点的dom高就是窗口可见的高度，并不包含滚动条多出来的部分。
// 解决办法：只需要在导出之前将overflow:auto设置成visible(默认即可)；
//         导出pdf后再设置回去。

    // 导出之前将dom的overflow：auto设置成visible
    // this.$('#boardPdf').css({'overflow-y': 'visible', 'height': 'auto'})
    // this.$('#app').css({'overflow-y': 'visible', 'height': 'auto'})
    // this.$('body').css({'overflow-y': 'visible', 'height': 'auto'})
    document.querySelectorAll('img').forEach(image => { image.crossOrigin = 'anonymous'; image.src += ' '; })
    // eslint-disable-next-line
    html2canvas(document.querySelector(selector),{
      allowTaint:false,
      useCORS:true
    }).then(function (canvas) {
        console.log("/onrendered", selector);
        var contentWidth = canvas.width
        var contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        var pageHeight = contentWidth / 592.28 * 841.89
        // 未生成pdf的html页面高度
        var leftHeight = contentHeight
        // pdf页面偏移
        var position = 0
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28
        var imgHeight = 592.28 / contentWidth * contentHeight
        var pageData = canvas.toDataURL('image/jpeg', 1.0)
        // eslint-disable-next-line
        var pdf = new jsPDF('', 'pt', 'a4')
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight)
        {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else
        {
          while (leftHeight > 0)
          {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            // 避免添加空白页
            if (leftHeight > 0)
            {
              pdf.addPage()
            }
          }
        }
        // 导出pdf文件命名
        pdf.save('report_pdf_' + new Date().getTime() + '.pdf')
        if (cb!=null) cb();

      }
    )
    // 导出pdf后，将dom原本的属性设置回去
    // this.$('#boardPdf').css({'overflow-y': 'auto', 'height': '100%'})
    // this.$('#app').css({'overflow-y': 'auto', 'height': '100%'})
    // this.$('body').css({'overflow-y': 'auto', 'height': '100%'})
  }

}