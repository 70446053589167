import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory ,createHashHistory } from 'history';
import configureStore from "./stores";
import {Provider} from "react-redux";
import {Message, Notification} from "element-react";
import {createStore} from "redux";
import reducer from "./reducers";
import LoginPage from "./pages/LoginPage";
import Facade from "./Facade";
import DashboardPage from "./pages/DashboardPage";
import PageWrapper from "./pages/PageWrapper";
import WeekPage from "./pages/WeekPage";
import WeekDetailPage from "./pages/WeekDetailPage";
import WeekGraphPage from "./pages/WeekGraphPage";
import ProjectPage from "./pages/ProjectPage";
import WeekSharedPage from "./pages/WeekSharedPage";
import DashboardChartPage from "./pages/DashboardChartPage";
import Dashboard2Page from "./pages/Dashboard2Page";
import TrashPage from "./pages/TrashPage";
import ArchivePage from "./pages/ArchivePage";
import HomePage from "./pages/HomePage";
import DailyBasicPage from "./pages/DailyBasicPage";
import DailyTablePage from "./pages/DailyTablePage";
import DailySharedPage from "./pages/DailySharedPage";
import LarkLoginPage from "./pages/LarkLoginPage";
import LarkTaskEditPage from "./pages/LarkTaskEditPage";
import LarkTaskAllocListPage from "./pages/LarkTaskAllocListPage";
require("font-awesome/css/font-awesome.css");

const ROUTE_ROOTPATH ="(/bdbim)?/";  // 本地是根目录，兼容服务器上带前缀目录

const store = configureStore();
// const customHistory = createHashHistory();
const customHistory = createBrowserHistory();
Facade.customHistory = customHistory;
const Reducer=()=> {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={customHistory}>

        <Switch>
          {/*<Route path={ROUTE_ROOTPATH+"dashboard"} component={DashboardPage} />*/}
          {/*<Route path={ROUTE_ROOTPATH+"dashboard2"} component={Dashboard2Page} />*/}
          {/*<Route path={ROUTE_ROOTPATH+"dashboard-chart"} component={DashboardChartPage} />*/}
          <Route path={ROUTE_ROOTPATH+"project-:id"} component={ProjectPage} />

          <Route path={ROUTE_ROOTPATH+"week-:weeklyId"} component={WeekDetailPage} />
          {/*<Route path={ROUTE_ROOTPATH+"weekgraph-:weeklyId"} component={WeekGraphPage} />*/}
          <Route path={ROUTE_ROOTPATH+"shared-:weeklyId"} component={WeekSharedPage} />
          <Route path={ROUTE_ROOTPATH+"weekpdf-:weeklyId"} component={(props)=><WeekSharedPage pdf={true} {...props} />} />

          <Route path={ROUTE_ROOTPATH+"lark-taskedit-:taskId"} component={LarkTaskEditPage} />
          <Route path={ROUTE_ROOTPATH+"lark-alloc1-:projectId"} component={(props)=><LarkTaskAllocListPage alloc={1} {...props} />} />
          <Route path={ROUTE_ROOTPATH+"lark-alloc2-:projectId"} component={(props)=><LarkTaskAllocListPage alloc={2} {...props} />} />

          <Route path={ROUTE_ROOTPATH+"day-:dailyId"} component={DailyBasicPage} />
          <Route path={ROUTE_ROOTPATH+"daytable-:dailyId"} component={DailyTablePage} />
          <Route path={ROUTE_ROOTPATH+"sharedday-:dailyId"} component={DailySharedPage} />
          <Route path={ROUTE_ROOTPATH+"daypdf-:dailyId"} component={(props)=><DailySharedPage  pdf={true} {...props} />} />

          {/*<Route path={ROUTE_ROOTPATH+"week"} component={WeekPage} />*/}
          <Route path={ROUTE_ROOTPATH+"trashcan"} component={TrashPage} />
          <Route path={ROUTE_ROOTPATH+"archived"} component={ArchivePage} />

          <Route path={ROUTE_ROOTPATH+"larklogin"} component={LarkLoginPage}/>
          <Route path={ROUTE_ROOTPATH+"login"} component={LoginPage}/>
          <Route path={ROUTE_ROOTPATH+"home"} component={HomePage}/>
          <Route path={ROUTE_ROOTPATH} component={LoginPage}/>
          {/*<Route path={ROUTE_ROOTPATH} component={HomePage} />*/}
        </Switch>

      </Router>
    </Provider>
    , document.getElementById('root'));
};

Reducer();

Facade.gw.gwAlert =(message,title)=>{
  Notification.error({
    title: title || "提示",
    message,
    duration: 0
  });
};
Facade.gw.gwToast=(message,title)=>{
  // Message.warning ({message:(title?title :"") +" "+message ,customClass:"topWarning"});

  Notification.info({
    title: title || "提示",
    message,
    duration: 0
  });
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
