
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

const {Row,Col} =Layout;
/** 飞书内登录跳板页 */
class LarkLoginPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state={prompt:""};
  }

  componentDidMount()
  {
    if ( Facade.larkSdkUtil.isInLark)
    {
      let toUrl=localStorage.getItem("expiredUrlOneShot");
      // console.log("LarkLoginPage/componentDidMount expiredUrlOneShot=",    localStorage.getItem("expiredUrlOneShot") );
      Facade.larkSdkUtil.runAsap(
        ()=>{Facade.larkSdkUtil.loginLark((token)=>{
          Facade.oauth.loginSucc(token);
        });}
      );
      this.setState({prompt:"正在授权"+toUrl});
    }else{
      this.setState({prompt:"仅可在飞书内打开"});
    }

  }
  render(){
    let {prompt} = this.state;
    return (
      <div className="LarkLoginPage">{prompt}</div>);
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList:state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(LarkLoginPage);