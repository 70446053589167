import {
  SET_WEEK_REPORT_TASK_DETAIL,
  SET_WEEK_REPORT_TASK_FOLD,
  SET_WEEK_REPORT_TASK_LIST,
  SET_WEEK_REPORT_TASK_LIST_FILTER, SET_WEEK_REPORT_TYPENUM, UPDATE_WEEK_REPORT_TASK_DETAIL
} from "../actions";
import TimeUtil from "../TimeUtil";

export default function weekReportTasks(state = {
  // 被层级扁平化排序过的列表
  list:[  ]
  //当前打开/选中的任务
  , taskVO: null
  //筛选当前tab（我的任务1｜全部任务2） 从之前的条线改来的
  , tasksFilter: { type :"1"}
  //当前延期等情况数量
  , typeNum:{"1":0,"2":0,"3":0,"4":0}
}, action) {
  switch (action.type)
  {
    case SET_WEEK_REPORT_TASK_LIST:
      return {...state, list: state.tasksFilter.type==1? flatList(action.voArr):organizeList(action.voArr)};
    case SET_WEEK_REPORT_TASK_DETAIL:
      return {...state , taskVO:action.vo};
    case UPDATE_WEEK_REPORT_TASK_DETAIL:
      // 当有task项发生数据变更时，需要同时维护到列表数组中的引用
      let fillVO =fillTaskVO(action.vo);
      // 维护到open的
      let {taskVO}=state;
      if ( state.taskVO!=null && state.taskVO.id == action.vo.id )
      {
        Object.assign(taskVO,fillVO);
        fillVO = taskVO;// 合并并让他俩等价
      }
      //维护到本地列表中
      let found=state.list.findIndex((li)=> li.id==action.vo.id);
      if ( found>-1 )
      {
        let newList = [
          ...state.list.slice(0,found),
          fillVO,
          ...state.list.slice(found+1)
        ];
        // 本地列表改动时候增量更新typeNum
        let typeNumNew=recalcTypeNum(newList);
        // console.log("default/weekReportTasks",newList);
        return {...state,list: newList,typeNum: typeNumNew ,taskVO};
      }else{
        return {...state,taskVO};
      }
    case SET_WEEK_REPORT_TASK_LIST_FILTER:
      return {...state, tasksFilter : action.vo};

    case SET_WEEK_REPORT_TYPENUM: //<- 写入最新的 本周4种颜色任务的数量
      return {...state , typeNum: action.vo};
    default :return state;
  }
}
/** 本地列表改动时候增量更新typeNum */
function recalcTypeNum(taskList){
  let typeNum = {"1":0,"2":0,"3":0,"4":0};
  for (const taskLi of taskList)
  {
    typeNum[taskLi.status]++;
  }
  return typeNum;
}

//region 整理层级
/** 整理列表 为规整的顺序 */
function organizeList ( taskList )
{
  let end =[];
  let curSearchParentId = 1;// 根级是1
  let curLevel = 1;// 根级是0
  let curGenTree = [curSearchParentId];
  organizeNextLv(curSearchParentId,curLevel,curGenTree,taskList,end);
  end = end.map ( fillTaskVO );
  // 处理好之后排序
  end.sort(sortByGenTree);
  return end;
}

// 显示我的任务，扁平全显示
function flatList( taskList)
{
  let end = taskList.map ( fillTaskVO );
  return end;
}

function sortByGenTree(a,b){
  return a.cTaskGenTree > b.cTaskGenTree;
}
//显示全部任务，带层级缩进显示
function organizeNextLv (curSearchParentId,curLevel,curGenTree, taskList,end){
  for (const taskLi of taskList)
  {
    // 根据parentId 计算层级 cLevel ,cGenTree
    let curId = taskLi.taskId;
    let parentId = taskLi.taskParentId;
    if ( parentId == curSearchParentId)
    {
      // 是当前要找的
      taskLi.cTaskLevel = curLevel;
      let nextGenTree = curGenTree.concat(curId);
      taskLi.cTaskGenTree = nextGenTree.join(">");
      end.push ( taskLi);
      organizeNextLv(curId,curLevel+1,nextGenTree,taskList ,end);
    }
  }

}
//endregion

/** 补充字段 */
function fillTaskVO(vo)
{
  // 延误的处理和计算 =>cTaskDelayDays
  if ( vo.taskFinishDate &&  vo.planRealFinishDate )
  {//有实际时间才能比较
    let planRealFinishDate = TimeUtil.parseTimeString(vo.planRealFinishDate);
    let taskFinishDate = TimeUtil.parseTimeString(vo.taskFinishDate);
    vo.cTaskDelayDays = TimeUtil.getDayDiff(planRealFinishDate,taskFinishDate);
  }else
  {//无法判断，看作无delay
    vo.cTaskDelayDays =0;
  }
  return vo;
}