
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Select, Loading, Message, Dialog,MessageBox} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, openProjectId, setAdminList} from "../actions";
import Facade from "../Facade";
import DailyTabBar from "../components/DailyTabBar";
import PageWrapper from "./PageWrapper";
import {Editor, EditorState} from 'draft-js';
import "./DailyPage.css";
import RtfEditor from "../components/RtfEditor";
import {
  addDailyEquipment, resetDailyDetail,
  setDailyBasic, setDailyConstructionList, setDailyConstructionTypeList,
  setDailyEquipmentList,
  setDailyPersonList, setDailySafety,
  setDailyWeather
} from "../reducers/dailyReportDetails";
import SgnrPanel from "../components/SgnrPanel";
import GalleryPanel from "../components/GalleryPanel";
import DailyEquipmentLi from "../components/DailyEquipmentLi";
import DailyPersonLi from "../components/DailyPersonLi";
import Utils from "../Utils";
import {WEB_URL} from "../consts";

const {Row,Col} =Layout;
class DailyTablePage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {dailyId: 0,isDailyDetailFullLoading:true,
      isShowSgnr:false,
      editSgnrData:null,//<-修改时用
    };
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    let dailyId = parseInt(nextProps.match.params.dailyId);
    return {
      dailyId,
    }
  }

  componentDidMount()
  {
    this.refreshByDailyId(this.state.dailyId);
  }

  refreshByDailyId(dailyId)
  {
    let {dispatch} = this.props;
    dispatch (resetDailyDetail());
    this.setState({isDailyDetailFullLoading:true});
    Facade.gw.getDailyBasicInfo(dailyId,(vo)=>{
      dispatch(setDailyBasic(vo));
      if ( !vo.projectAddress){
        //未填写的 不允许进入本页面
        this.setState({isDailyDetailFullLoading:false});
        MessageBox.alert("必须先填写完基础信息，才可以进入日报编辑","提示",{
          type: 'warning'
        }).then(()=>{
          Facade.customHistory.replace(`/day-${dailyId}`);
        }).catch(()=>{
          Facade.customHistory.replace(`/day-${dailyId}`);
        });
      }else{
        dispatch(openProjectId(vo.projectId));
        Facade.gw.getDailyWeather(dailyId,(weather)=>{
          dispatch(setDailyWeather(weather));
          Facade.gw.getDailyPerson(dailyId,(personList)=>{
            dispatch(setDailyPersonList(personList));
            Facade.gw.getDailyEquipment(dailyId,(equipList)=>{
              dispatch(setDailyEquipmentList(equipList));
              this.setState({dailySafetyReady:false});
              Facade.gw.getDailySafetyMgmt(dailyId,(safety)=>{
                dispatch(setDailySafety(safety));
                this.setState({dailySafetyReady:true});
                Facade.gw.getDailyConstructionContent(dailyId,(conList)=>{
                  dispatch(setDailyConstructionList(conList));
                  Facade.gw.getDailyConstructionTypeList( (conTypeList)=>{
                    dispatch(setDailyConstructionTypeList(conTypeList));
                    this.setState({isDailyDetailFullLoading:false});
                  });
                });

              });
            })
          });
        });
      }
    });

  }

  render(){
    let {dailyId,isDailyDetailFullLoading,editSgnrData,dailySafetyReady,} = this.state;
    let {dispatch,dailyBasic,dailyWeather,dailyPersonList,dailyPersonSum,dailyEquipmentList,dailyConstructionContentList,dailySafety} = this.props;
    return (
      <PageWrapper>
        <div className="DailyPage pageWithPadding">

          <div className="pageCard">
              <Row className="tableTitleRow">
                <Col span={24}>
                  <div className="tableTitle">
                    {dailyBasic.projectName} 施工日报 {dailyBasic.date}
                  </div>
                </Col>
              </Row>


              <div className="cardWithPadding">
                {dailyId && <DailyTabBar dailyId={dailyId} activeIndex={2}/>}
              </div>

            <Loading loading={isDailyDetailFullLoading}  >
              <div className={"logo"}>
                <img src={require("../res/1026/日报/logo.png")}/>
              </div>

              <div className="rbTableWrap">
                <table className={"rbTable"}>
                  <tr>
                    <td className="sectionHead first" colSpan={4}>2-天气情况</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="inputTfRow">
                        <div className={"label"}>天气：</div>
                        <input className={"val"} value={dailyWeather.textDay}/>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"label"}>最高温度：</div>
                        <input className={"val"} value={dailyWeather.tempMax}/>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"label"}>最低温度：</div>
                        <input className={"val"} value={dailyWeather.tempMin}/>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"label"}>风力：</div>
                        <input className={"val"} value={dailyWeather.windScaleDay}/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <div className="inputTfRow">
                        <div className={"label"}>受天气影响工作：</div>
                        <select value={dailyWeather.isWeatherInfluence} className={"valSel"} onChange={this.onChangeInfluence.bind(this)}>
                          <option value={0}>无</option>
                          <option value={1}>有</option>
                        </select>

                      </div>
                    </td>
                  </tr>

                </table>

                <table className={"rbTable"}>
                  <tbody>
                  <tr>
                    <td className="sectionHead" colSpan={3}>3-人员设备</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="th">
                        单位
                      </div>
                    </td>
                    <td>
                      <div className="th">
                        工种
                      </div>
                    </td>
                    <td>
                      <div className="th">
                        人员数量
                      </div>
                    </td>
                  </tr>
                  {dailyPersonList.map( li=> <DailyPersonLi key={li.id} data={li}/>)}
                  <tr>
                    <td colSpan={2} className={"th"}>
                      <div className="sum">
                        现场总人数
                      </div>
                    </td>
                    <td>
                      <div className="sum">
                        上述汇总：{dailyPersonSum}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="th">
                        常驻大型机械设备
                      </div>
                    </td>
                    <td>
                      <div className="th">
                        型号
                      </div>
                    </td>
                    <td>
                      <div className="th">
                        数量
                      </div>
                    </td>
                  </tr>
                  {dailyEquipmentList.map( li=> <DailyEquipmentLi key={li.id} isNew={false} onSave={this.onSaveEquipment.bind(this)}
                                                                  onDel={this.onDelEquipment.bind(this)} data={li}/>)}
                  <tr>
                    <td colSpan={3}>
                      <div className="addRysbBtn" onClick={this.onClickAddEquipment.bind(this)}>
                        <img src={require("../res/1026/日报/add.png")} alt=""/>
                        添加设备
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>

                {/*安全管理：*/}
                <table className={"rbTable"}>
                  <tbody>
                  <tr>
                    <td className="sectionHead">4-安全管理</td>
                  </tr>
                  <tr>
                    <td>
                      {/*<textarea className={""} value={dailySafety}*/}
                      {/*          ref={(safetyTf)=>{this.safetyTf= safetyTf;}}*/}
                      {/*          style={{height:this.state.safetyH?this.state.safetyH:"24px"}}*/}
                      {/*          onChange={this.onChangeSafety.bind(this)}*/}
                      {/*          onBlur={this.onSafetyCheckSave.bind(this)}*/}
                      {/*/>*/}
                      {dailySafetyReady && <Input type={"textarea"}
                                             value={dailySafety}
                                             autosize={{ minRows: 2, maxRows: 10}}
                                             onChange={this.onChangeSafetyR.bind(this)}
                                             onBlur={this.onSafetyCheckSave.bind(this)}
                      />}

                    </td>
                  </tr>
                  </tbody>
                </table>
                {/*主要施工内容*/}
                <table className={"rbTable"}>
                  <tbody>
                  <tr>
                    <td className="sectionHead">5-主要施工内容</td>
                  </tr>

                  {dailyConstructionContentList.map(this.renderConstructionLi.bind(this))}

                  <tr>
                    <td>
                      <div className="addRysbBtn" onClick={this.onClickAddSgnr.bind(this)}>
                        <img src={require("../res/1026/日报/add.png")} alt=""/>
                        添加施工内容
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>


              </div>

              <div className={"btnsRow"}>
                <div className="saveBtn" onClick={this.onShare.bind(this)}>
                  分享日报
                </div>
                <div className="saveBtn" onClick={this.onPdf.bind(this)}>
                  下载PDF
                </div>

              </div>
            </Loading>


            </div>

          {this.state.isShowSgnr &&
            <Dialog
              style={{maxWidth: '800px'}}
              title="添加施工内容"
              visible={true}
              onCancel={() => {
                this.setState({isShowSgnr: false});
              }}
            >
              <Dialog.Body>
                <SgnrPanel
                  onFinish={() => {
                    this.setState({isShowSgnr: false});
                  }}
                  data={editSgnrData}
                />
              </Dialog.Body>
            </Dialog>
          }

          {this.renderGalleryPanel()}


        </div>
      </PageWrapper>);
  }

  renderGalleryPanel(){
    let {dialogImgGalleryVisible,galleryImgList,galleryImg} = this.state;
    return dialogImgGalleryVisible && <GalleryPanel pictureList={galleryImgList}
                         picture={galleryImg}
                         onCancel={() => {
                           this.setState({dialogImgGalleryVisible: false})
                         }
                         }/>
  }
  onClickImg(url,constructionLi,e)
  {
    // console.log("DailyTablePage/onClickImg");
    let galleryImgList=constructionLi.pictures.split(",");
    let galleryImg = url;
    this.setState({dialogImgGalleryVisible: true ,galleryImgList,galleryImg})
    e.stopPropagation();
  }

  renderConstructionLi (li,i){
    return [
      <tr key={"const-title-"+i}>
        <td>
          <div className="sgnr-titlerow">
            <div className="sgnr-title" onClick={this.onClickEditSgnr.bind(this,li)}>{li.dailyConstructionTypeName}</div>
            <div className="sgnrDeleteBtn" onClick={this.onClickDelSgnr.bind(this,li.id)}>
              <img src={require("../res/1026/日报/delete.png")} alt=""/>
              删除
            </div>
          </div>
        </td>
      </tr>,
      <tr key={"const-article-"+i} onClick={this.onClickEditSgnr.bind(this,li)}>
        <td>
          <div className="sgnr-article" dangerouslySetInnerHTML={{__html: li.content}}>
          </div>
        </td>
      </tr>,
      <tr key={"const-gallery-"+i} onClick={this.onClickEditSgnr.bind(this,li)}>
        <td>
          <Row gutter={10} className="sgnr-gallery" >
            {
              li.pictures.split(",").map((picLi,picI)=>{
                return (<Col span={6} key={"galleryImg"+li.id+"-"+picI}>
                  <img className={"galleryImg"} src={picLi} alt="" onClick={this.onClickImg.bind(this,picLi,li)}/>
                </Col>)
              })
            }

          </Row>
        </td>
      </tr>];
  }

  onChangeInfluence(evt){
    let {dailyId} = this.state;
    let {dispatch,dailyBasic,dailyWeather } = this.props;
    let val = evt.target.value;
    Facade.gw.dailyIsInfluence(dailyId,val, ()=>{
      Message.success("天气影响保存成功");
      dispatch(setDailyWeather({...dailyWeather,isWeatherInfluence:val}))
    });
  }
  onChangeSafetyR(strVal)
  {
    let {dispatch,dailySafety } = this.props;
    dispatch(setDailySafety(strVal));
  }
  // onChangeSafety(evt)
  // {
  //   let {dispatch,dailySafety } = this.props;
  //   let strVal=evt.target.value;
  //   dispatch(setDailySafety(strVal));
  //   if (this.safetyTf)
  //   {
  //     if ( this.safetyTf.scrollHeight > this.safetyTf.clientHeight)
  //     {
  //       let safetyH = (this.safetyTf.scrollHeight) + "px";
  //       this.setState({safetyH});
  //     }
  //   }
  // }
  onSafetyCheckSave(evt)
  {
    let {dailyId} = this.state;
    let {dispatch,dailySafety } = this.props;
    Facade.gw.dailyEditSafetyMgmt(dailyId,dailySafety,()=>{
      Message.success("安全管理信息保存成功");
      // dispatch(setDailySafety(evt.target.value));
    });
  }

  onSaveEquipment(isNew ,id, name,model,amount){
    let {dailyId} = this.state;
    let { dispatch } = this.props;
    if ( isNew )
    {
      Facade.gw.dailyPostEquipment(dailyId , name,model,amount);
    }else{
      Facade.gw.dailyEditEquipment(id , name,model,amount, ()=>{
        Message.success("设备保存成功");
        Facade.gw.getDailyEquipment(dailyId,(equipList)=>{
          dispatch(setDailyEquipmentList(equipList));
        })

      })

    }
  }
  onDelEquipment(id){
    let {dailyId} = this.state;
    let {dispatch}=  this.props;
    Facade.gw.dailyDelEquipment(id,()=>{
      Message.success("设备删除成功");
      Facade.gw.getDailyEquipment(dailyId,(equipList)=>{
        dispatch(setDailyEquipmentList(equipList));
      })
    });
  }

  onClickAddEquipment(){
    let {dailyId} = this.state;
    Facade.gw.dailyPostEquipment(dailyId , "","",0,(vo)=>{
      this.props.dispatch(addDailyEquipment(vo));
    });

  }


  onClickAddSgnr(){
    this.setState({isShowSgnr:true,editSgnrData:null});
  }
  onClickEditSgnr(li){
    this.setState({isShowSgnr:true , editSgnrData:li})
  }
  onClickDelSgnr(id){
    let {dailyId} = this.state;
    let {dispatch} = this.props;
    this.setState({isDailyDetailFullLoading:true});
    Facade.gw.dailyDelDailyConstructionContent(id , ()=>{
      Message.success("施工内容删除成功");
      Facade.gw.getDailyConstructionContent(dailyId,(conList)=>{
        dispatch(setDailyConstructionList(conList));
        this.setState({isDailyDetailFullLoading:false});
      })
    });
  }

  onShare(){
    Utils.copyTXT(`${WEB_URL}/sharedday-${this.state.dailyId}`);
    Message.success("链接已复制到剪贴板");
  }

  onPdf(){
    Facade.customHistory.push("daypdf-"+this.state.dailyId);
  }

}


const mapStateToProps = (state) => {
  return {
    dailyBasic: state.dailyReportDetails.basic,
    dailyWeather: state.dailyReportDetails.weather,
    dailyPersonList: state.dailyReportDetails.personList,
    dailyPersonSum: state.dailyReportDetails.personSum,
    dailyEquipmentList: state.dailyReportDetails.equipmentList,
    dailyConstructionContentList: state.dailyReportDetails.constructionContent,
    dailySafety: state.dailyReportDetails.safety,
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(DailyTablePage);