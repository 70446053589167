import React, {Component} from 'react';
import {Select} from "element-react";
import Facade from "../Facade";
import PropTypes from "prop-types";

/**
 * 用户选择器；
 *  默认显示少数选项（props传入），随着用户输入可立即网络搜索获得更多选项。
 *  list : [{id,name,icon},...]
 */
export default class UserSelect extends Component
{
  static propTypes = {
    // 现有的用户列表 [{id,name,icon},...] （输入） 注意输入和输出不同
    list: PropTypes.array.isRequired,
    // 变更后的用户列表回调[uid,...] 或 uid 取决于是否多选 （输出）
    onChange: PropTypes.func.isRequired,
    single: PropTypes.bool
  }


  constructor(props)
  {
    super(props);
    //需要确保一开始有数据在userselect组建construct的时候就有值，后续会忽视list变化
    let {list,single} = props;
    //state list是运行时显示，默认值采用props传入的list，搜索后覆盖为搜索结果
    // console.log("UserSelect/constructor",list    );
    let value = single ? (list[0].id || null):list.map( li => li.id);
    this.state ={
      isLoading:false,
      list,
      value ,
      options: list
    }
  }

  // static getDerivedStateFromProps(props, state)
  // {
  //   if (props.list != state.list)
  //   {
  //     let {list} = props;
  //     return {
  //       list, value: list.map(li => li.id),
  //       options: list
  //     }
  //   }
  //
  // }

  componentDidMount()
  {
  }

  // 传出的是value 多选就是[uid,...]
  onChange (value)
  {    // list 应该是options中 value筛选后的；仅靠客户端当时数据，无法计算出来
//todo 要能正确还原list ，还需要此时options中根据value找到对象 , 打算第二个参数计算并传出，
    this.props.onChange(value);
  }

  onSearch(query){
    this.setState({isLoading: true});
    Facade.gw.getUserSearch(query,  (data) => {
      this.setState({options: data});
      this.setState({isLoading: false});
    })
  }
  // render(){
  //   return (
  //       <Select value={this.state.value} multiple={false} filterable={true} remote={true} remoteMethod={this.onSearch.bind(this)} loading={this.state.loading}>
  //         {
  //           this.state.options.map(el => {
  //             return <Select.Option key={el.value} label={el.label} value={el.value} />
  //           })
  //         }
  //       </Select>);
  // };

  render()
  {
    let {list,options,value} = this.state;
//此组建需有options 和value 才能显示
    return (
      <Select
        className="Select"
        placeholder="键入以搜索"
        value={value}
        multiple={!this.props.single} filterable={true}
        remote={true} remoteMethod={this.onSearch.bind(this)} loading={this.state.isLoading}
        onChange={this.onChange.bind(this)}>
        {
          options.map(el => {
            return <Select.Option key={el.id} label={el.nickName} value={el.id}/>
          })
        }
      </Select>
    )
  }

}

/*
export default class UserSelect extends Component
{constructor(props) {
  super(props);
let     options=[{
  value: '选项1',
  label: '黄金糕'
}, {
  value: '选项2',
  label: '双皮奶'
}, {
  value: '选项3',
  label: '蚵仔煎'
}, {
  value: '选项4',
  label: '龙须面'
}, {
  value: '选项5',
  label: '北京烤鸭'
}];
  this.state = {
    options,
    value: [options[1].value, options[2].value]
  };
}

  render() {
    return (
      <Select value={this.state.value} multiple={true}>
        {
          this.state.options.map(el => {
            return <Select.Option key={el.value} label={el.label} value={el.value} />
          })
        }
      </Select>
    )
  }


}
*/