
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar
  , VictoryLine
  , VictoryTooltip
  , VictoryVoronoiContainer, VictoryAxis, VictoryLabel
} from "victory";
import {VCHART_THEME} from "../consts";

const {Row,Col} =Layout;
class ChartAqrl extends Component
{
  constructor(props)
  {
    super(props);
    let       xLabels= ["桩机", "总包", "消防", "机电", "幕墙", "精装", "汇总"];
      let data =[
      [
      {x: "12月", y: 0},
      {x: "1月", y: 0},
      {x: "2月", y: 0},
      {x: "3月", y: 0},
      {x: "4月", y: 0},
      {x: "5月", y: 0},
    ]
      , [
        {x: "12月", y: 0},
        {x: "1月", y: 0},
        {x: "2月", y: 0},
        {x: "3月", y: 0},
        {x: "4月", y: 0},
        {x: "5月", y: 0},
      ]
      , [
        {x: "12月", y:0},
        {x: "1月", y: 920},
        {x: "2月", y: 2040},
        {x: "3月", y: 15540},
        {x: "4月", y: 43440},
        {x: "5月", y: 82440},
      ]
      , [
        {x: "12月", y: 0},
        {x: "1月", y: 0},
        {x: "2月", y: 0},
        {x: "3月", y: 0},
        {x: "4月", y: 0},
        {x: "5月", y: 0},
      ]
      , [
        {x: "12月", y: 17299},
        {x: "1月", y: 18659},
        {x: "2月", y: 20003},
        {x: "3月", y: 22115},
        {x: "4月", y: 24227},
        {x: "5月", y: 26435},
      ]
      , [
        {x: "12月", y: 87624},
        {x: "1月", y: 106283},
        {x: "2月", y: 126286},
        {x: "3月", y: 148401},
        {x: "4月", y: 172628},
        {x: "5月", y: 199063},
      ]
      , [
        {x: "12月", y: 104923},
        {x: "1月", y: 125862},
        {x: "2月", y: 148329},
        {x: "3月", y: 186056},
        {x: "4月", y: 240295},
        {x: "5月", y: 307938},
      ]
    ];
    data = data.map((li,index) => {
      return li.map((item,index2) => {
        return {x: item.x, y: item.y ,l : xLabels[index]+":"+item.y};
      });
    });

    this.state = { xLabels,data};
  }

  componentDidMount()
  {
  }


  render() {
    const getBarData = () => {
      return [1, 2, 3, 4, 5].map(() => {
        return [
          { x: 1, y: Math.random() },
          { x: 2, y: Math.random() },
          { x: 3, y: Math.random() }
        ];
      });
    };

    return (
      <div className={"chart"}>
        <VictoryChart domainPadding={{x: 0}} width={400} height={204}
                      padding={{top:20,bottom:35,left:32,right:24}}
                      animate={400}
                      containerComponent={
                        <VictoryVoronoiContainer
                          voronoiDimension="x"
                          labels={({datum}) => `${datum.l}`}
                          labelComponent={
                            <VictoryTooltip
                              cornerRadius={0}
                              flyoutStyle={{fill: "white"}}
                            />}
                        />}
        >
          <VictoryAxis dependentAxis
                       tickFormat={(t) => Math.round(t/10000)+"万"}
                       style={{
                         axis: {stroke: "transparent"},//y轴
                         // axisLabel: {fill: "#757c88"},

                         ticks: {size: 0,stroke:"transparent"},

                         tickLabels: {fill: "#757c88", fontSize: 10, padding: 5},//y轴刻度label
                         grid: {stroke: "#e1e1e1", strokeDasharray: "5 5"},//网格线 横线
                       }}
                       />
          <VictoryAxis
            style={{
              tickLabels: {fill: "#757c88",fontSize:10},//x轴刻度label
              ticks: {size: 0,stroke:"transparent"},
              axis: {strokeWidth:1,stroke:"rgba(225, 225, 225, 1)"},//x轴
              grid :{stroke:"transparent"}
            }}/>
          {this.state.data.map((data, index) => {
          return (
            <VictoryLine
              data={data}
              style={{
                data: {
                  stroke: VCHART_THEME.stack.colorScale[index],
                  strokeWidth: ({active}) => active ? 2 : 1
                },
                labels: {fill: VCHART_THEME.stack.colorScale[index]}
              }}
            />
          );

          })}

        </VictoryChart>
      </div>
    );
  }

  renderTuli(svgW,svgH, li, index,arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 90;
    let x = (svgW-arr.length* liW)/2 + index * liW;
    let x2 = x+12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }



}

const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartAqrl);