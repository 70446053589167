
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  DatePicker,
  Tabs,
  Icon, Message, Badge, Select,Tag
} from "element-react";
import 'element-theme-default';
import {
  customerOpen,
  loginRecovery,
  setAdminList,
  setWeekReportDetail,
  setWeekReportListFilter, setWeekReportTaskDetail,
  setWeekReportTaskFold, setWeekReportTaskList,
  setWeekReportTaskListFilter,
  setWeekReportTestData
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import Utils from "../Utils";
import QuickTimeTf from "../components/QuickTimeTf";
import {WORK_DATE_NATURE} from "../consts";
import PageWrapper from "./PageWrapper";
import UserSelect from "../components/UserSelect";
import runRefreshWeekly from "../controllers/runRefreshWeekly";
import WeekTasksTabBar from "../components/WeekTasksTabBar";
import WeekTasksTable from "../components/WeekTasksTable";
import runRefreshTasks from "../controllers/runRefreshTasks";
import RecentResultsUI from "../components/RecentResultsUI";
import runRefreshRecentResults from "../controllers/runRefreshRecentResults";
import WeekGraphCard from "../components/WeekGraphCard";
import ChartRfi from "../components/ChartRfi";
import ChartAqrl from "../components/ChartAqrl";
import ChartNcr from "../components/ChartNcr";
import ChartRisk from "../components/ChartRisk";
import runRefreshProjectList from "../controllers/runRefreshProjectList";
import BodyFooter from "../components/BodyFooter";
import "./WeekSharedPage.css";
import PdfHelper from "../PdfHelper";


const {Row,Col} =Layout;
class WeekSharedPage extends Component
{

  constructor(props)
  {
    super(props);
    this.state={
    };
  }




  componentWillReceiveProps(nextProps, nextContext)
  {
    let weeklyId = parseInt(this.props.match.params.weeklyId);
    if ( weeklyId!= this.weeklyId )
    {
      this.weeklyId = weeklyId;
      runRefreshWeekly(this.weeklyId , this.props.dispatch);
      runRefreshTasks(this.weeklyId ,"2", this.props.dispatch);
    }
    // 加载完毕生成pdf
    if ( this.props.pdf )
    {
      let {weekReportDetail,projectDetail ,isLoadingTaskList} = nextProps;
      // console.log("WeekSharedPage/componentWillReceiveProps",this.isInitLoad, isLoadingTaskList , weekReportDetail,projectDetail ,isLoadingTaskList);
      if (this.isInitLoad &&  !isLoadingTaskList && weekReportDetail && projectDetail)
      {
        // console.log("WeekSharedPage/componentWillReceiveProps");
        setTimeout(
          ()=>{
            this.onDownPdf();
          },500
        )
      }
    }
  }

  componentDidMount()
  {
    

    this.weeklyId = parseInt(this.props.match.params.weeklyId);
    runRefreshProjectList(this.props.dispatch);
    runRefreshWeekly(this.weeklyId , this.props.dispatch);
    runRefreshTasks(this.weeklyId ,"2", this.props.dispatch);

    this.isInitLoad=true;

  }



  render(){
    let {dispatch, weekReportDetail,projectDetail ,pdf } = this.props;
    //创建筛选器
    return (
        <div id={"WeekSharedPage"} className="WeekSharedPage pageWithPadding" style={{background:"rgba(242, 244, 245, 1)"}}>

          <div className="pageCard">
            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                  {projectDetail? projectDetail.name:""}周报 {weekReportDetail?weekReportDetail.dateBucket:""}
                </div>
              </Col>
              <Col span={12}>
              </Col>
            </Row>



            {this.weeklyId && <WeekTasksTabBar weeklyId={this.weeklyId} />}


            <Row >
              {this.weeklyId && <WeekTasksTable  weeklyId={this.weeklyId} editable={false}/>}
            </Row>



          </div>

          <Row>
            <BodyFooter/>
          </Row>
        </div>
        );
  }


  onDownPdf(){
    PdfHelper.exportPdf("#WeekSharedPage");
  }

  static propTypes = {
  }

}


const mapStateToProps=(state)=>
{
  return {
    projectDetail: state.projects.projectDetail,
    weekReportDetail: state.weekReports.weekReportDetail,
    isLoadingTaskList:state.general.isLoading.taskList,

  };
};
export default connect(
  mapStateToProps
)(WeekSharedPage);