
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  DatePicker,
  Tabs,
  Icon, Message, Badge, Select, Tag, Card
} from "element-react";
import "./WeekGraphCard.css";
import StepNum from "./StepNum";

const {Row,Col} =Layout;


export default class WeekGraphCard extends Component
{


  componentDidMount()
  {
  }
  render(){
    let {name,icon,iconname,val,delay} = this.props;
    return (
      <Col  span={4} xs={8} >
        <div className="WeekGraphCard">
          <div className="name">
            {name}
          </div>

          <img className="icon" src={icon} />

          <div className="val">
            <StepNum from={0} to={val} prefix="" postfix="" totalFrame={50} />
            {delay>0 && <div className="percent delay"><i className="fa fa-arrow-down"/> {delay}%</div>}
            {delay<0 && <div className="percent forward"><i className="fa fa-arrow-up"/> {delay}%</div>}
          </div>
          <div className="info">
            {delay>0 && <div  >计划延迟{delay}天</div>}
            {delay<0 && <div  > 计划提前{Math.abs(delay)}天</div>}
            {delay==0 && <div className="intime">按时完成</div>}
          </div>

        </div>

      </Col>);
  }

}