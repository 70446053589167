
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, DatePicker, Loading, Table, Radio, Message} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import runRefreshDeletedProjectList from "../controllers/runRefreshDeletedProjectList";
import PageWrapper from "./PageWrapper";
import runRefreshArchivedProjectList from "../controllers/runRefreshArchivedProjectList";
import runRefreshProjectList from "../controllers/runRefreshProjectList";

const {Row,Col} =Layout;
class ArchivePage extends Component
{
  constructor(props)
  {
    super(props)
    this.state={
      projectColumns: [
        {label: "已归档项目", prop: "name"},
        {label: "",  width:80, render: this.renderActions.bind(this)}
      ]
    }
  }

  renderActions(row){
    return (
      <span>
        <Button type="text" size="small" onClick={this.onClickUnarchive.bind(this,row)} > 恢复</Button>
      </span>
    )
  }
  onClickUnarchive(li)
  {

    Facade.gw.unarchiveProject(li.id ,()=>{
      runRefreshArchivedProjectList(this.props.dispatch);
      runRefreshProjectList(this.props.dispatch);
      Message.success("项目已激活");
    })
  }

  componentDidMount()
  {
    runRefreshArchivedProjectList(this.props.dispatch);
  }

  render(){

    let {archivedProjectList} = this.props;
    return (
      <PageWrapper activeNav="9-archived">
        <div className="ArchivePage pageWithPadding">
          <div className="pageCard">

            <Row>
                <Table
                  border={false}
                  style={{width: '100%', marginBottom: '20px'}}
                  columns={this.state.projectColumns}
                  onCellClick={(row, column, cell, event) => {
                    if (column.swallow)
                    {
                      if (event) event.stopPropagation();
                    }
                  }}
                  data={archivedProjectList}
                  stripe={false}
                  highlightCurrentRow={true}
                />
            </Row>



          </div>
        </div>
      </PageWrapper>);
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    archivedProjectList: state.projects.archivedProjectList,
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ArchivePage);