import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import "./LarkTaskAllocListPage.css";

const {Row, Col} = Layout;

class LarkTaskAllocListPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {};
  }

  componentDidMount()
  {
    let {projectId} = this.props.match.params;
    this.refreshData(projectId);
  }

  refreshData(projectId)
  {
    Facade.gw.getTaskAllocatingRecord(projectId, ({weeklyName, taskAllocatingRecord,projectName}) => {
      this.setState({weeklyName, taskAllocatingRecord,projectName});
    });
  }

  render()
  {
    let {alloc} = this.props;
    let {weeklyName, taskAllocatingRecord, projectName} = this.state;

    return (
      <div className="LarkTaskAllocList">

        <div className="title">
          {projectName} （{weeklyName}）
        </div>


        <div className="zbTableWrap">
          <table className={"zbTable"}>
            <thead>
            <th>任务计划节点</th>
            <th>节点负责人</th>
            <th>分配记录</th>
            {alloc==2 && <th>任务责任人</th>}
            {alloc==2 && <th>分配记录</th>}
            {alloc==2 && <th>项管/设计院/QS责任人</th>}
            {alloc==2 && <th>分配记录</th>}
            </thead>
            <tbody>
            {taskAllocatingRecord && taskAllocatingRecord.map((li) => {
              return (
                <TaskRecordLi key={"TaskRecordLi-" + li.id} alloc={alloc} vo={li}/>);
            })}

            {(!taskAllocatingRecord || taskAllocatingRecord.length == 0) ? (
              <tr>
                <td colSpan={alloc==2?7:3} className={"empty"}> 暂无内容 </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        </div>

      </div>);
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList: state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(LarkTaskAllocListPage);


/**************** li ******************/
//region li


class TaskRecordLi extends Component
{


  componentDidMount()
  {
  }

  render()
  {
    let {vo, alloc} = this.props;
    let {
      allocatingManagerUser, allocatingTaskUser, allocatingResponsibleDepartmentUser,
      allocatingManagerTime, allocatingResponsibleDepartmentTime, allocatingTaskTime,
      managerUserList, taskUserList, responsibleDepartmentUserList
    } = vo;
    return (
      <tr className="TaskRecordLi">
        <td>{vo.taskName}</td>
        <td>
          <div className="role">
            {managerUserList && managerUserList.map((li) => {
              return [
                <div className="head" key={"userm-avatar-" + li.id}>
                  <img src={li.avatar_url} alt=""/>
                </div>,
                <div className="name" key={"userm-nick-" + li.id}>{li.nickName}</div>]
            })}
          </div>
        </td>
        <td>
          <div className="role">
            <div className="name">{allocatingManagerUser && allocatingManagerUser.nickName}</div>
            {allocatingManagerTime}
          </div>
        </td>

        {alloc==2 && <td>
          <div className="role">
            {taskUserList && taskUserList.map((li) => {
              return [
                <div className="head" key={"user-avatar-" + li.id}>
                  <img src={li.avatar_url} alt=""/>
                </div>,
                <div className="name" key={"user-nick-" + li.id}>{li.nickName}</div>]
            })}
          </div>
        </td>}
        {alloc==2 && <td>
          <div className="role">
            <div className="name">{allocatingTaskUser && allocatingTaskUser.nickName}</div>
            {allocatingTaskTime}
          </div>
        </td>}

        {alloc==2 && <td>
          <div className="role">
            {responsibleDepartmentUserList && responsibleDepartmentUserList.map((li) => {
              return [
                <div className="head" key={"userr-avatar-" + li.id}>
                  <img src={li.avatar_url} alt=""/>
                </div>,
                <div className="name" key={"userr-nick-" + li.id}>{li.nickName}</div>]
            })}
          </div>
        </td>}
        {alloc==2 && <td>
          <div className="role">
            <div className="name">{allocatingResponsibleDepartmentUser && allocatingResponsibleDepartmentUser.nickName}</div>
            {allocatingResponsibleDepartmentTime}
          </div>
        </td>}


      </tr>);
  }

}

//endregion