
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {
  customerUpdate,
  loginLogin,
  setAdminList,
  setWeekReportTaskDetail,
  updateWeekReportTaskDetail
} from "../actions";
import Facade from "../Facade";
import "./OperationPanel.css";
import TimeUtil from "../TimeUtil";

const {Row,Col} =Layout;
/** 操作记录面板 */
class OperationPanel extends Component
{
  constructor(props)
  {
    super(props)
    this.state={list : [] };
  }

  componentDidMount()
  {
    let {id}=this.props;
    Facade.gw.getTaskOperatingRecord(id,({parentTaskName,taskOperatingRecord,taskName})=>{
      this.setState({list:taskOperatingRecord,taskName,parentTaskName});
    })
  }
  render(){
    let {taskName,parentTaskName,list} = this.state;
    return (
      <div className="OperationPanel">
        {parentTaskName && <div className="headtitle1">{parentTaskName}</div>}
          <div className="headtitle2">{taskName}</div>

        <div className="hr"></div>


        <div className="oprTitle">操作记录</div>
        <div className="oprList">
          {list.map ((li)=>{
            let t = TimeUtil.formatStringTime(li.createTime);
            return           <div className="oprLi" key={"opr-"+li.id}><span>{li.userName}</span> {li.content} {t}</div>
          })}
        </div>

      </div>);
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList:state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(OperationPanel);