import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme, Rect
  , VictoryAxis, VictoryTooltip, VictoryPie, VictoryAnimation
} from "victory";
import {VCHART_THEME} from "../consts";
import MBScrollViewHelper from "../MBScrollViewHelper";

const {Row, Col} = Layout;

class ChartDonut3 extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      pieData: [
        {labelVO:"",color:"#ffffff",x:0,y:0,num:0,percent:""},
        {labelVO:"",color:"#00dae4",x:1,y:999,num:0,percent:""}
      ]
    };
  }

  static propTypes = {
    data: PropTypes.array,
  }

  componentDidMount()
  {
    this.scrollViewHelper = new MBScrollViewHelper(this.ref, this.onReveal.bind(this));
  }
  onReveal(){
    this.scrollViewHelper.dispose();
    let {data} = this.props;
    let pieData = ChartDonut3.getData(data);
    this.setState({pieData});
  }

  componentWillUnmount()
  {
    this.scrollViewHelper.dispose();
  }

  static getData(rawData)
  {
    let total = 0;
    for (const rawDatum of rawData)
    {
      let {label,color ,num} = rawDatum;
      total+= num;
    }
    let data = [];
    for (const rawDatum of rawData)
    {
      let {label,color ,num} = rawDatum;
      let labelVO = [label, "数量：" + num, "占比：" + Math.round(num / total * 100) + "%"];
      data.push({y:num,x:label,labelVO,color ,num});
    }
    return data;
  }

  render()
  {

    let {pieData} = this.state;
    return (
      <div ref={(ref) => {this.ref = ref}}
        style={{position: "relative"}}
      >
        <svg viewBox="0 0 442 248" >
          <VictoryPie
            standalone={false}
            animate={{duration: 400}}
            width={210} height={210}
            data={pieData}
            innerRadius={60}
            radius={85}
            origin={{x: 442 / 2, y: 248 / 2}}
            // labels={() => ""}
            labelComponent={<CustomLabel radius={85} origin={{x: 442 / 2, y: 248 / 2}}
                                          />}
            style={{
              data: {
                fill: ({datum}) => {
                  return datum.color;
                }
              }
            }}
          />


          {pieData.map(this.renderTuli.bind(this,442,248))}

        </svg>


        {this.renderTooltip(pieData[0], false)}
        {this.renderTooltip(pieData[1],true)}

      </div>
    );
  }

  renderTooltip(li, isLeft,isBottom)
  {
    if ( !li.labelVO) return null;
    return (
      <div className={"tooltip"}
           style={{
             top: isBottom?"auto":10,
             left: isLeft ? 32 : "auto",
             right: isLeft ? "auto" : 24,
             bottom: isBottom ? 10:"auto" ,
             background: "#fff"
           }}>
        <div style={{color: li.color, fontWeight: 500, fontSize: '14px'}}>
          {li.labelVO[0]}
        </div>
        <div style={{fontSize: '12px', color: "#000"}}>{li.labelVO[1]}</div>
        <div style={{fontSize: '12px', color: "#000"}}>{li.labelVO[2]}</div>
      </div>
    );

  }

  renderTuli(svgW, svgH, li, index, arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 70;
    let x = (svgW - arr.length * liW) / 2 + index * liW;
    let x2 = x + 12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }



}

class CustomLabel extends React.Component
{

  render()
  {
    let {x, y, datum, radius, origin,isLeft,isBottom} = this.props;
    if ( !datum.x )return null;
    //判断左右
    let angle = 60;
    const color = datum.color;
//从orogin为圆心 往angle方向 radius距离的点
    let x1 = origin.x + radius * Math.cos(angle * Math.PI / 180);
    let y1 = origin.y - radius * Math.sin(angle * Math.PI / 180);
    let x2 = 304;
    let y2 = 30;
    let x3 = 442 - 32;
    if (x < 221)
    {
      //左侧出现
      x1 = origin.x - radius * Math.cos(angle * Math.PI / 180);
      x2 = 116;
      x3 = 32;
    }
    if ( isBottom )
    {
      y1 = origin.y + radius * Math.sin(angle * Math.PI / 180);
      y2 = 216;
    }

    return (
      <g>
        <line x1={x1} y1={y1} x2={x2} y2={y2} style={{stroke: color, strokeWidth: 1}}/>
        <line x1={x3} y1={y2} x2={x2} y2={y2} style={{stroke: color, strokeWidth: 1}}/>
      </g>);
  }

}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartDonut3);