
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Card,
  Tabs
} from "element-react";
import 'element-theme-default';
import * as V from 'victory';
import TopMenu from "../components/NavMenu";
import {
  customerUpdate,
  customerOpen,
  setAdminList,
  setCustomerFilter,
  setCustomerList,
  loginRecovery
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import HseAuditResultChart from "../components/hse/HseAuditResultChart";
import ManpowerChart from "../components/manpower/ManpowerChart";
import ProjectSumTable from "../components/ProjectSumTable/index";
import DesignCommentsChart from "../components/designcomments/DesignCommentsChart";
import ContractSnagChart from "../components/ContractSnag/ContractSnagChart";
import PageWrapper from "./PageWrapper";
import ChartRfi from "../components/ChartRfi";
import ChartAqrl from "../components/ChartAqrl";
import ChartNcr from "../components/ChartNcr";
import ChartRisk from "../components/ChartRisk";
import WeekGraphCard from "../components/WeekGraphCard";
import runRefreshTasks from "../controllers/runRefreshTasks";


const {Row,Col} =Layout;


class DashboardChartPage extends Component
{

  constructor(props)
  {
    super(props);
    this.state={
      isLoading: true,
      type :0
    };
  }




  componentDidMount()
  {


  }
  componentWillUnmount()
  {
  }


  render(){
    let { isLoading,type }= this.state;
    return (
      <PageWrapper>
      <div className="DashboardChartPage pageWithPadding">

        <Row gutter={10} justify={"space-around"} style={{marginBottom:"1em"}}>

          <Col xs="24" sm="24" md="12" lg="12">
            <div className={"chartCard"}>
              <div className="chartCardHead">安全/人力</div>
              <div className="chartCardBody">
                <ChartAqrl/>
              </div>
            </div>
          </Col>
          <Col xs="24" sm="24" md="12" lg="12">
            <div className={"chartCard"}>
              <div className="chartCardHead">工时汇总（本月）</div>
              <div className="chartCardBody">
                <ChartNcr/>
              </div>
            </div>

          </Col>

        </Row>
        <Row >
          <Col span={24}>
            <div className={"chartCard"}>
              <div className="chartCardHead">
                <div>风险<span style={{fontSize:"12px"}}>(意外和/或环境)</span></div>
              </div>
              <div className="chartCardBody overflowscroll">
                <ChartRisk/>
              </div>
            </div>

          </Col>
        </Row>


        <div className="groupborder">
          <Row style={{background:"#fff",marginBottom:"10px"}}>
            <Tabs activeName={"0"} className="shortTabs" onTabClick={this.onTabClick.bind(this)}>
              <Tabs.Pane label="近期计划" name="0">
              </Tabs.Pane>
              <Tabs.Pane label="基线计划" name="3"/>
              <Tabs.Pane label="基线计划-阶段" name="1"/>
              <Tabs.Pane label="基线计划-里程碑" name="2"/>
            </Tabs>
          </Row>

          <Row className="graphcards" gutter={10}>
            <WeekGraphCard name={"设计"} iconname={"fa fa-server"} val={848} delay={-16} />
            <WeekGraphCard name={"采购"} iconname={"fa fa-truck"} val={848} delay={16} />
            <WeekGraphCard name={"报建报批"} iconname={"fa fa-ticket"} val={516} delay={0} />
            <WeekGraphCard name={"施工"} iconname={"fa fa-road"} val={516} delay={0} />
            <WeekGraphCard name={"项目验收"} iconname={"fa fa-users"} val={516} delay={0} />
            <WeekGraphCard name={"移交入住"} iconname={"fa fa-suitcase"} val={516} delay={16} />
          </Row>


          <Row className="schedulechart" >
            <Col span={24}>
              <div id="chart_schedule_container">
              </div>
            </Col>
          </Row>
        </div>



      </div>
      </PageWrapper>);
  }

  onChange(v)
  {
    let type = v;
    this.showScheduleChart(type);
  }
  onTabClick(tab){
    let type = tab.props.name;
    this.showScheduleChart(type);
  }


}



const mapStateToProps=(state)=>
{
  return {
  };
};
export default connect(
  mapStateToProps
)(DashboardChartPage)