import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Select, Dialog} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerOpen, customerUpdate, loginLogin, setAdminList, setSearchList} from "../actions";
import Facade from "../Facade";
import {WORK_DATE_NATURE} from "../consts";

import "./TopSearch.css";


const {Row, Col} = Layout;
class TopSearch extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {isLoading: false, value: null, dialogVisible2: false};
  }

  componentDidMount()
  {
  }

  render()
  {
    let {list, dispatch} = this.props;
    return (
      <div className="TopSearch "  >
        <i className="el-icon-search searchicon"></i>
        <Select
          className="Select"
          placeholder="搜索任务"
          value={this.state.value} multiple={false} filterable={true}
          remote={true} remoteMethod={this.onSearch.bind(this)} loading={this.state.isLoading}
          onChange={(vo) => {
            this.setState({value: vo});
            dispatch(customerOpen(vo));
            this.setState({dialogVisible2: true})

          }}>
          {
            list.map(el => {
              return <Select.Option key={el.id} label={el.company } value={el}/>
            })
          }
        </Select>

      </div>);
  }


  onSearch(query)
  {
    // this.setState({isLoading: true});
    // Facade.gw.getCustomerSearch(query, 1, (data) => {
    //   this.setState({list: data.list});
    //   this.setState({isLoading: false});
    //   this.props.dispatch(setSearchList(data.list));
    // })
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    openCustomer: state.customers.openCustomer,
    list: state.customers.searchList
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(TopSearch);