/** 日报数据reducer
 * 日报列表；
 * */

/**************** 日报列表页 ******************/
//region 日报列表页

//设置列表
export const SET_DAILY_REPORT_LIST="SET_DAILY_REPORT_LIST";
export function setDailyReportList(list)
{
  return {type:SET_DAILY_REPORT_LIST,voArr:list}
}



export default function dailyReports(state = {
  dailyReportList:[]
}, action) {
  switch (action.type)
  {
    case SET_DAILY_REPORT_LIST:
      let {  voArr} = action;
      return {...state, dailyReportList: voArr.map(pollyfillDailyReport) };

    default :return state;
  }
}
//endregion

/**************** 公用 ******************/
//region 公用
/**
 * 填充客户端需要字段到daily
 * @param li
 * @param i
 * @return {*}
 */
function pollyfillDailyReport(li, i)
{
  return li;
}
//endregion