import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme, Rect
  , VictoryAxis, VictoryTooltip, VictoryPie, VictoryAnimation
} from "victory";
import {VCHART_THEME} from "../consts";
import MBScrollViewHelper from "../MBScrollViewHelper";

const {Row, Col} = Layout;

class ChartDonut2 extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      pieDataArr:
        [
          ChartDonut2.getData("", 0, 0, "#ffffff")
          , ChartDonut2.getData("", 0, 0, "#ffffff")
          , ChartDonut2.getData("", 0, 0, "#ffffff")
        ]
    };
  }

  static propTypes = {
    data: PropTypes.array,
  }


  componentDidMount()
  {
    this.scrollViewHelper = new MBScrollViewHelper(this.ref, this.onReveal.bind(this));

  }

  componentWillUnmount()
  {
    this.scrollViewHelper.dispose();
  }

  onReveal(){
    this.scrollViewHelper.dispose();
    let data = this.props.data;
    this.setState({pieDataArr: data.map((li) => ChartDonut2.getData(li.x, li.num, li.y, li.color))})
  }

  static getData(x, num, percent, color)
  {
    let label = [x, "\n" + "数量：" + num, "占比：" + percent + "%"];
    return [{x: 1, y: percent, label, color}, {x: 2, y: 100 - percent, label, color: "#ebecee"}];
  }

  render()
  {

    let {pieDataArr} = this.state;
    return (
      <div ref={(ref) => {this.ref = ref}}
        style={{position: "relative"}}
      >
        <svg viewBox="0 0 442 248">
          {
            pieDataArr.map((li, index) => {
              let d = (170 - index * 40);
              let radius = d / 2;
              let iRadius = radius - 10;
              let color = li[0].color;
              return (
                <VictoryPie
                  standalone={false}
                  animate={{duration: 400}}
                  width={d} height={d}
                  data={li}
                  innerRadius={iRadius}
                  radius={radius}
                  origin={{x: 442 / 2, y: 248 / 2}}
                  // events={[{
                  //   // childName: ["pie", "bar"],
                  //   // target: "data",
                  //   eventHandlers: {
                  //     onMouseOver:(e)=>{
                  //       console.log("ChartDonut2/onMouseOver",e.currentTarget);
                  //     },
                  //     onMouseOut: (e) => {
                  //       console.log("ChartDonut2/onMouseOut",e);
                  //       return [];
                  //     }
                  //   }
                  // }]}
                  // labelComponent={<VictoryTooltip
                  //   // constrainToVisibleArea
                  //   // pointWidth={1}
                  //   // labelComponent={<VictoryLabel
                  //   //   lineHeight={[2,2,2]}
                  //   //                               style={[
                  //   //                                 { fill: color,fontSize:14 },
                  //   //                                 { fill: "#000000" ,fontSize: 10},
                  //   //                                 { fill: "#000000" ,fontSize: 10 }
                  //   //                               ]}
                  //   //
                  //   //
                  //   // />}
                  //   labelComponent={<CustomLabel radius={radius} origin={{x: 442 / 2, y: 248 / 2}} />}
                  //   flyoutComponent={<CustomFlyout/>}
                  // />}
                  labelComponent={<CustomLabel radius={radius} origin={{x: 442 / 2, y: 248 / 2}}
                                               isLeft={index==1} isBottom={index==2}/>}
                  style={{
                    data: {
                      fill: ({datum}) => {
                        return datum.x === 1 ? datum.color : "#ebecee";
                      }
                    }
                  }}
                />
              );
            })
          }

          {pieDataArr.map(this.renderTuli.bind(this,442,248))}

        </svg>

        {this.renderTooltip(pieDataArr[0], false)}
        {this.renderTooltip(pieDataArr[1],true)}
        {this.renderTooltip(pieDataArr[2],false,true)}

      </div>
    );
  }

  renderTooltip(li, isLeft,isBottom)
  {
    return (
      <div className={"tooltip"}
           style={{
             top: isBottom?"auto":10,
             left: isLeft ? 32 : "auto",
             right: isLeft ? "auto" : 0,
             bottom: isBottom ? 10:"auto" ,
             background: "#fff"
           }}>
        <div style={{color: li[0].color, fontWeight: 500, fontSize: '14px'}}>
          {li[0].label[0]}
        </div>
        <div style={{fontSize: '12px', color: "#000"}}>{li[0].label[1]}</div>
        <div style={{fontSize: '12px', color: "#000"}}>{li[0].label[2]}</div>
      </div>
    );

  }

  renderTuli(svgW, svgH, li, index, arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 70;
    let x = (svgW - arr.length * liW) / 2 + index * liW;
    let x2 = x + 12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li[0].color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li[0].label[0]}
        style={{fontSize: 10}}
      />];

  }
}

class CustomLabel extends React.Component
{

  render()
  {
    let {x, y, datum, radius, origin,isLeft,isBottom} = this.props;
    if ( datum.x !==1)return null;
    //判断左右
    let angle = 60;
    const color = datum.color;
//从orogin为圆心 往angle方向 radius距离的点
    let x1 = origin.x + radius * Math.cos(angle * Math.PI / 180);
    let y1 = origin.y - radius * Math.sin(angle * Math.PI / 180);
    let x2 = 304;
    let y2 = 30;
    let x3 = 442 - 32;
    if (isLeft)
    {
      //左侧出现
      x1 = origin.x - radius * Math.cos(angle * Math.PI / 180);
      x2 = 116;
      x3 = 32;
    }
    if ( isBottom )
    {
      y1 = origin.y + radius * Math.sin(angle * Math.PI / 180);
      y2 = 216;
    }

    return (
      <g>
        <line x1={x1} y1={y1} x2={x2} y2={y2} style={{stroke: color, strokeWidth: 1}}/>
        <line x1={x3} y1={y2} x2={x2} y2={y2} style={{stroke: color, strokeWidth: 1}}/>
      </g>);
  }

  static calcLinePos1(datum)
  {

  }
}

class CustomFlyout extends React.Component
{
  render()
  {
    return (
      null
    );
  }
}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartDonut2);