
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Form, Message, Upload, MessageBox} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList, setProjectList} from "../actions";
import Facade from "../Facade";
import runRefreshProjectList from "../controllers/runRefreshProjectList";
import UserSelect from "./UserSelect";
import {EDIT_TASK_TYPE_managerUserList, EDIT_TASK_TYPE_taskUserList} from "../consts";

const {Row,Col} =Layout;
class ProjectEdit extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      form: {
        name: props.projectName,
      },
      formRules: {
        name: {required: true, message: "请输入项目名称", trigger: "blur,change"}
        ,filePath: {required: false, trigger: "change,blur", message: "请完成文件上传"}
      }
    };
  }

  static deriveStateFromProps(props, state)
  {
    let {form} = state;
    return {
     ...state,form:{...form,name : props.projectName}
    }
  }


  componentDidMount()
  {
  }
  render(){
    let {filePath} = this.state.form;

    return (
      <div className="ProjectEdit" style={{width: "80%",margin:"auto"}}>
        <Form ref="form" model={this.state.form} rules={this.state.formRules} labelPosition="top">
          <Form.Item label="项目名称" prop="name">
            <Input type="text" name="name" value={this.state.form.name} onChange={this.onChange.bind(this,"name")} placeholder="输入项目名称"/>
          </Form.Item>
          <Form.Item label="上传替换文件(若不需要更改则留空)" prop="filePath">
            <Upload ref={"upload"}
                    className="form-upload"
                    drag
                    action={Facade.gw.getUploadGateway()}
                    headers={Facade.gw.getUploadHeaders()}
                    name={"uploadFile"}
                    multiple={false}
                    tip={<div className="el-upload__tip">只支持project文件</div>}
                    onSuccess={this.onUploadSucc.bind(this)}
            >
              {!filePath && <i className="el-icon-upload"></i>}
              {filePath && <i className="el-icon-circle-check icon"></i>}
              {!filePath && <div className="el-upload__text">如需更换project文件，则将文件拖到此处，或<em>点击上传</em></div>}
              {filePath && <div className="el-upload__text">已收到您上传的文件，如需更改，则将文件拖到此处，或点击上传</div>}
            </Upload>

          </Form.Item>
          
          <Button onClick={this.onSubmit.bind(this)} type={"primary"}>提交</Button>
        </Form>
      </div>);
  }

  onChange(key ,val)
  {
    this.state.form[key] = val;
    this.forceUpdate();
  }

  onUploadSucc(res, file, fileList)
  {
    // console.log("ProjectEdit/onUploadSucc",res.data);
    if ( res.error!=0)
    {
      Facade.gw.gwAlert(res.error +":"+res.message)
      return;
    }
    this.onChange("filePath", res.data);
    this.refs.form.validateField("filePath", () => {});
  }
  onUserChange( field, uids)
  {
    this.onChange(field,uids);
    // console.log("ProjectEdit/onUserChange",field,uids);
  }


  onSubmit()
  {
    this.refs.form.validate((valid) => {
      if ( valid )
      {
        let {projectId} = this.props;
        let {name, filePath} = this.state.form;
        //允许不传文件
        // if (!filePath)
        // {
        //   Message.warning("请上传文件");
        // }
        if (filePath)
        {
          MessageBox.confirm('重新关联Project文件，周报任务将于下周更新？', '确认要修改此项目吗？', {
            type: 'warning'
          }).then(() => {
            this.confirmSubmit(projectId, name, filePath);
          }).catch(() => {
          })

        } else
        {
          this.confirmSubmit(projectId, name, filePath);

        }
      }
    });
  }
  confirmSubmit(projectId,name, filePath){


    this.setState({fullscreenLoading:true});
    Facade.gw.recreateProject(projectId,name, filePath,() => {
      this.setState({fullscreenLoading:false});
      Message.success("提交成功");
      this.resetForm();
      //刷新
      runRefreshProjectList(this.props.dispatch);

      //
      this.props.onFinish != null && this.props.onFinish();
    });
  }
  resetForm(){
    this.refs.upload.clearFiles();
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    projectList : state.projects.projectList,
    nickName: state.login.nickName,
    avatarUrl: state.login.avatarUrl,
    mineId : state.login.id
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ProjectEdit);