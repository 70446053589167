
const MIN = 60000;
const FIVE_MINS = 300000;
const HOUR = 3600000;
export const DAY = 86400000;
const DAY2 = 86400000 * 2;
const WEEK = DAY*7;
export const WEEK2 = WEEK*2;
export const MONTH = 2592000000;
export const MONTH2 = MONTH*2;
const YEAR = 31536000000;

export default class TimeUtil  {

  static formatDateOrTime( timeStr )
  {
    if ( !timeStr ) return "";
    let date = this.parseTimeString(timeStr);
    return this.formatBrefDate(date)
  }


  static formatStringTime(timeString) {
    if(!timeString) return "";
    return TimeUtil.formatTime(TimeUtil.parseTimeString(timeString));
  }
  static parseTimeString(timeString,safe=true) {
    if (safe && !timeString) return new Date();
    /*
    WebKit不支持 new Date("2019-06-14 15:12:40") 这种创建
     */
    let [ymd, his] = timeString.split(" ");
    let [y, m, d] = ymd.split("-");
    if ( his )
    {
      let [h, i, s] = his.split(":");
      return new Date(y, m - 1, d, h, i, s);
    }else{
      return new Date(y,m-1,d);
    }
  }
  static formatTime(date) {
    let now = new Date();
    let delta = (now.valueOf() - date.valueOf());
    let d = date.getDate();
    if ( delta < MIN)
    {
      return "刚刚";
    } else if (delta <= HOUR) {
      //1小时内
      return Math.floor (delta / MIN)+"分钟前";
    } else if (HOUR < delta && delta <= DAY) {
      let deltaH = Math.floor(delta/HOUR);
      return deltaH+"小时前"
    } else if (DAY < delta && delta <= DAY2) {
      return "1天前";
    } else if (now.getFullYear() == date.getFullYear()) {
      // 同年
      return (date.getMonth() + 1) + "月" + d + "日";// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    } else {
      // 隔年
      return (date.getFullYear()) + "年" + (date.getMonth() + 1) + "月" + d + "日";// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    }
  }

  //Y-m-d H:i:s
  static formatDBDate(date)
  {
    let m=date.getMinutes();
    let h =date.getHours();
    return (date.getFullYear()) + "-" + this.padStr ((date.getMonth() + 1).toString(),2,'0') + "-" + this.padStr( (date.getDate()).toString(),2,'0')+" "+ (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString()) +":00";
  }
  //Y-m-d
  static formatDBDate2(date)
  {
    return (date.getFullYear()) + "-" + this.padStr ((date.getMonth() + 1).toString(),2,'0') + "-" + this.padStr( (date.getDate()).toString(),2,'0');
  }
  //Y-m
  static formatDBDate3(date)
  {
    return (date.getFullYear()) + "-" + this.padStr ((date.getMonth() + 1).toString(),2,'0') ;
  }

  /**
   * 格式化为尽可能短的日期  如 12.31
   * @param date
   * @returns {string}
   */
  static formatShortDate(date)
  {
    if ( date.getFullYear()==1970) return '';
    let now = new Date();
    const tomorrow = new Date();
    tomorrow.setTime(now.getTime() + DAY);

    let d = date.getDate();
    let nowD = now.getDate();
    if (now.getMonth()==date.getMonth() && now.getFullYear() == date.getFullYear()) {
      if ( nowD==d )
        return '今天';
      else if ( d-nowD ==1)
        return "明天";
      else if ( d-nowD ==2)
        return '后天';
      else if ( d-nowD ==-1 )
        return '昨天';
    }

    if (now.getFullYear() == date.getFullYear()) {
      // 同年
      return (date.getMonth() + 1) + "." + d;// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    } else {
      // 隔年
      return (date.getFullYear()) + "年" + (date.getMonth() + 1) + "." + d;// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    }

  }
  // 12月31日
  static formatBrefDate(date)
  {
    if ( date.getFullYear()==1970) return '';
    let now = new Date();
    const tomorrow = new Date();
    tomorrow.setTime(now.getTime() + DAY);

    let d = date.getDate();
    let nowD = now.getDate();
    if (now.getMonth()==date.getMonth() && now.getFullYear() == date.getFullYear()) {
      if ( nowD==d )
        return '今天';
      else if ( d-nowD ==1)
        return "明天";
      else if ( d-nowD ==2)
        return '后天';
      else if ( d-nowD ==-1 )
        return '昨天';
    }

    if (now.getFullYear() == date.getFullYear()) {
      // 同年
      return (date.getMonth() + 1) + "月" + d+"日";// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    } else {
      // 隔年
      return (date.getFullYear()) + "年" + (date.getMonth() + 1) + "月" + d;// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());
    }

  }
  static formatYmd(date)
  {
    let d = date.getDate();
    return (date.getFullYear()) + "年" + (date.getMonth() + 1) + "月" + d+"日";// + (h < 10 ? ("0" + h) : h.toString()) + ":" + (m < 10 ? ("0" + m) : m.toString());

  }

  /** 返回两个时间之差 单位：毫秒 */
  static getTimeDiff(date1,date2)
  {
    let time1 = date1.getTime();
    let time2 = date2.getTime();
    return time1-time2;
  }

  // 返回两个时间之差 单位：天
  static getDayDiff(date1,date2)
  {
    let time1 = date1.getTime();
    let time2 = date2.getTime();
    return Math.floor((time1-time2)/DAY);
  }


  static padStr(str, len, pad) {
    if (str.length >= len) {
      return str;
    }
    return pad.repeat(len - str.length) + str;
  }

  static getWeeklyEndDay(startDay){
    let end= new Date(startDay.valueOf()+WEEK2-DAY);
    end.setHours(0,0,0,0);
    return end;
  }

}