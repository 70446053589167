import {openProjectId, setProjectList, setWeekReportDetail} from "../actions";
import Facade from "../Facade";

/** 加载周报基础信息 ，同时会打开所属项目 */
export default function runRefreshWeekly(weeklyId,dispatch){
  Facade.gw.getWeeklyDetail(weeklyId ,(weekReportDetail)=>{

    dispatch(setWeekReportDetail(weekReportDetail));
    let {projectId} = weekReportDetail;
    dispatch(openProjectId(projectId));
  });
}
