import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme, Rect
  , VictoryAxis, VictoryTooltip
} from "victory";
import {VCHART_THEME} from "../consts";
import MBScrollViewHelper from "../MBScrollViewHelper";

const {Row, Col} = Layout;

class ChartBars2 extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      data: [
        {x: "", y: 0.00, color: "#4eecbb"},
      ]
    };
  }

  static propTypes = {
    data: PropTypes.array,
  }

  componentDidMount()
  {
    this.scrollViewHelper = new MBScrollViewHelper(this.ref, this.onReveal.bind(this));

  }

  componentWillUnmount()
  {
    this.scrollViewHelper.dispose();
  }

  onReveal(){
    this.scrollViewHelper.dispose();
    this.setState({data: this.props.data});
  }


  render()
  {

    let {data} = this.state;

    return (
      <div ref={(ref) => {this.ref = ref}}
        // style={{width: "442px",height:"162px",background:"red"}}
      >
        <VictoryChart domainPadding={{x: 32, y: 0}}
                      width={442} height={248}
                      padding={{top: 22, bottom: 50, left: 75, right: 50}}
                      animate={{duration: 400}}
          // theme={VCHART_THEME}
        >
          <VictoryAxis dependentAxis
                       domain={[0, 1]}
                       style={{


                         axis: {stroke: "transparent"},//x轴
                         // axisLabel: {fill: "#757c88"},

                         // ticks: {stroke: "red", size: 0,fill:"red"},

                         tickLabels: {fill: "#757c88", fontSize: 10, padding: 5},//x轴刻度label
                         grid: {stroke: "#e1e1e1", strokeDasharray: "5 5"},//网格线 竖线
                       }}
                       tickFormat={(t) => Math.round(t * 100) + "%"}
          />
          <VictoryAxis
            style={{
              axis: {stroke: "#e1e1e1"},//y轴
              tickLabels: {fill: "#8e9093",fontSize:10,padding:5},//y轴刻度label
            }}
          />
          <VictoryBar horizontal
            style={{
              data: {fill: (({datum}) => datum.color), width: 16},
              labels:{fill:"#8e9093",fontSize:10,padding:5}
            }}
            data={data}
            labels={({datum}) => Math.round(datum.y * 100) + "%"}
          />

          {/*{data.map(this.renderTuli.bind(this,442,248))}*/}
          {data && data.length && (this.renderTuli(442,248 , {...data[0],x:"完成度"},0,[data[0]]))}

        </VictoryChart>

      </div>
    );
  }

  renderTuli(svgW,svgH, li, index,arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 50;
    let x = (svgW-arr.length* liW)/2 + index * liW;
    let x2 = x+12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }
}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartBars2);