import {SET_LOADING,SET_VIEW_PREF} from "../actions";

export default function general(state = {
  // 用的时候 props.general.isLoading.taskList
  isLoading :{
    taskList:false,
    dailyList:false,
  }
  // 视图设定
  ,viewPref:{
    //项目页面-tab选中
    projectTab:"1"//1周，2日
  }

}, action) {
  switch (action.type) {
    case SET_LOADING:
      let isLoading = {...state.isLoading ,[action.key]:action.value};
      return {
        ...state,isLoading
      };
    case SET_VIEW_PREF:
      return {...state,viewPref :{...state.viewPref,[action.key]:action.value}};
    default:
      return state;
  }
}