
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, AutoComplete, Select, MessageBox, Message,Upload,Loading,Dialog} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import RtfEditor from "./RtfEditor";
import {
  addDailyConstruction,
  setDailyConstructionList,
  setDailyConstructionTypeList
} from "../reducers/dailyReportDetails";
import draftToHtml from 'draftjs-to-html';
import CosHelper from "../CosHelper";


const {Row,Col} =Layout;
/** 施工内容面板 */
class SgnrPanel extends Component
{
  static propTypes = {
    /*
    "id": 2,
    "dailyId": 5,
    "dailyConstructionTypeId": 1,
    "dailyConstructionTypeName": "土建结构",
    "content": "内容。。。",
    "pictures": "[1.jpg,2.jpg]"*/
    data : PropTypes.any,//<-修改已有时传入 则为修改
    onFinish : PropTypes.func.isRequired
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props)
    this.state={ dailyConstructionTypeId : 0 ,
      content :"",//<- htmlString 入都是，修改后需要用rtfEditor.exportHtml()获得
      pictures:"",pictureList:[],
      editId :null,isLoading:false
    };

  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    if (nextProps.data && nextProps.data.id != prevState.editId)
    {
      //修改施工内容； 初始化默认数据
      let {id , dailyConstructionTypeId,content,pictures} = nextProps.data;
      let pictureList= pictures?pictures.split(",").map((li)=>{return {url:li,name:""}}) : [];
      let editId = id;
      return {editId ,dailyConstructionTypeId,content,pictures,pictureList};
    }
  }


  componentDidMount()
  {
  }

  render(){
    let { constructionTypeList,dailyBasic} = this.props;
    let {dailyConstructionTypeId,pictureList,content,isLoading} = this.state;
    return (
      <div className="SgnrPanel">
<Loading loading={isLoading}>

        <Select value={dailyConstructionTypeId} placeholder="选择施工类型" onChange={this.onConTypeChange.bind(this)}>
          {
            constructionTypeList.map(el => {
              return <Select.Option key={el.id} label={el.name} value={el.id} />
            })
          }
          <Select.Option key={"other"} label={"添加"} value={-1} >
            <span style={{float: 'left'}}>其它</span>
            <span style={{float: 'right', color: '#8492a6', fontSize: 13}}>添加</span>
          </Select.Option>
        </Select>


        <div  style={{marginTop:"20px"}}  >
          <RtfEditor
            ref={(rtfEditor)=>{this.rtfEditor= rtfEditor;}}
            onEditorBlur={this.onRtfBlur.bind(this)}
            onEditorChanged={this.onRtfChanged.bind(this)}
            initialContent={content}
          />
        </div>

        <Row style={{marginTop:"20px"}} ><div className="prompt">添加施工照片</div>
        </Row>
        <Row style={{marginTop:"20px",marginBottom:"20px"}}>

          <Upload
            drag
            httpRequest={this.uploadRequest.bind(this)}
            listType="picture-card"
            // showFileList={true}

            onPreview={file => this.handlePictureCardPreview(file)}
            onRemove={(file, fileList) => this.handleRemove(file, fileList)}
            fileList={pictureList}
          >
            <i className="el-icon-plus"></i>
          </Upload>


        </Row>
        <div className={"btnsRow"}>
          <div className="saveBtn" onClick={this.onSave.bind(this)}>
            保存
          </div>
        </div>
</Loading>
        <Dialog
          visible={this.state.dialogVisible}
          size="small"
          onCancel={() => this.setState({ dialogVisible: false })}
        >
          <div className={"dialogImgWrapper"} >
          <img className={"dialogImg"}   src={this.state.dialogImageUrl} alt="" />
          </div>
        </Dialog>
      </div>);
  }

  handleRemove(file, fileList) {
    this.setState({pictureList:fileList});
  }

  handlePictureCardPreview(file) {
    this.setState({
      dialogImageUrl: file.url,
      dialogVisible: true,
    })
  }
  uploadRequest(options){
    let {onProgress,onSuccess,onError ,file,filename}=options;
    CosHelper.upload(file,(webUrl)=>{
      let pictureList =this.state.pictureList.concat({name:"",url:webUrl});
      //回调 @see /node_modules/element-react/typings/index.d.ts
      //     onProgress?(event?, file?, fileList?: any[]): void
      //     onSuccess?(response?, file?, fileList?: any[]): void
      //     onError?(err?, response?, file?): void
      onSuccess("1",file,pictureList);
      this.setState({pictureList});
    },(e)=>{
        onProgress(e,file);
      },
      (err)=>{
        onError(err,null,file)
      }
      );
  }

  onRtfBlur(){
    // this.state.content = this.rtfEditor.exportHtml();
  }
  onRtfChanged(){
    //如果每次都导出 会耗费性能 所以只有在保存的时候导出
    // this.state.content = this.rtfEditor.exportHtml();
  }
  onConTypeChange(v){
    if ( v == -1)
    {
      // 新增
      MessageBox.prompt("请输入类型名称", "添加施工类型", {}).then(({value}) => {
        if(value)
        {
          Facade.gw.dailyPostConstructionType( value, (vo) => {
            Message.success("添加施工类型成功");

            Facade.gw.getDailyConstructionTypeList( (conTypeList)=>{
              this.props.dispatch(setDailyConstructionTypeList(conTypeList));
              // 并且自动设置为最新
              let foundLi = conTypeList.find((li)=>{
                return li.name ==value;
              });
              this.setState({dailyConstructionTypeId:foundLi.id});
            });
          });
        }
      }).catch(()=>{});
    }else {
      // 设置 直接改变
      this.setState({dailyConstructionTypeId:v});
    }
  }


  onSave(){
    let exportHtml=this.rtfEditor.exportHtml();
    // console.log("SgnrPanel/onSave",exportHtml);
    let {dailyBasic ,dispatch,onFinish} = this.props;

    let {dailyConstructionTypeId,pictureList,editId} = this.state;
    let pictures = pictureList.map((li)=>{return li.url}).join(",");
    let dailyId =dailyBasic.id;

    if( dailyConstructionTypeId<1 ){
      Message.warning ({message :"未选择施工类型",customClass:"topWarning"});
      return;
    }
    if( exportHtml.length <10 ){
      Message.warning ({message :"需填写内容",customClass:"topWarning"});
      return;
    }
    this.setState({isLoading:true});
    if ( editId )
    {
      // 修改
      Facade.gw.dailyEditDailyConstructionContent(editId,dailyConstructionTypeId,exportHtml,pictures, (vo)=>{
        Facade.gw.getDailyConstructionContent(dailyId,(conList)=>{
          dispatch(setDailyConstructionList(conList));
          this.setState({isLoading:false});
          onFinish();
        })
      });
    }else{
      // 新增
      Facade.gw.dailyPostDailyConstructionContent(dailyId,dailyConstructionTypeId,exportHtml,pictures, (vo)=>{
        dispatch(addDailyConstruction(vo));
        this.setState({isLoading:false});
        onFinish();
      });
    }

  }


}
const mapStateToProps=(state)=>
{
  return {    dailyBasic: state.dailyReportDetails.basic,
    constructionTypeList:state.dailyReportDetails.constructionTypeList
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(SgnrPanel);