import {
  SET_COUNT_ALL,
  SET_COUNT_GROWTH,
  SET_COUNT_GROWTH_FILTER,
  SET_COUNT_RANKTOP10,
  SET_COUNT_RANKTOP10_FILTER
} from "../actions";
import {STATS_DIRECTION_UP, STATS_VIEW_ALL} from "../consts";

export default function stats(state = {
  countAllVO:{},
  growthDataFilter:{ uid:0 , view: STATS_VIEW_ALL},
  growthData: [],
  initData:{},
  rankTop10DataFilter:{ direction: STATS_DIRECTION_UP , view:STATS_VIEW_ALL},
  rankTop10: []
}, action) {

  switch( action.type)
  {
    case SET_COUNT_ALL:
      /*
    "data": {
        "total": 224, //企业数
        "total_certification": 47, //认证数量
        "total_account": "6338", //账号数
        "total_activated_account": "3957", //激活账号数
        "total_dau": "1708" //日活
    }*/
      return {...state , countAllVO:{...action.vo}};
    case SET_COUNT_GROWTH:
/*"data": {
          "initData": {  //客户分配时初始数据
              "init_dau": "5173",
              "init_account": "1802",
              "init_activated": "4221"
          },
          "growthData": [ 增长数据
              {
                  "st": "2020-05-15", //周期开始时间
                  "et": "2020-05-22",//周期结束时间
                  "data1": { //周期开始初始数据
                      "total_dau": "399",
                      "total_account": "1008",
                      "total_activated": "291"
                  },
                  "data2": {} //周期结束数据
              },
              {
                  "st": "2020-05-08",
                  "et": "2020-05-15",
                  "data1": {
                      "total_dau": "1441",
                      "total_account": "6109",
                      "total_activated": "3756"
                  },
                  "data2": {
                      "total_dau": "399",
                      "total_account": "1008",
                      "total_activated": "291"
                  }
              }
          ]
      }*/
      return {...state,growthData:action.growthData,initData:action.initData};
    case SET_COUNT_RANKTOP10:
      /*[
          {
              "st": "0000-00-00", //起始时间，如果是all的话，起始时间为0000-00-00
              "et": "2020-05-08", //结束时间
              "uid": "3", //负责人uid
              "name": "柳闽泉", //负责人
              "customer_no": "F168726013", //企业编号
              "company": "上海万脉信息技术有限公司", //企业名字
              "init": { //客户分配时初始数据
                  "account": "113",
                  "activated": "365",
                  "dau": "1008"
              },
              "inc": { //增长数据
                  "account": -74,
                  "activated": 895,
                  "dau": -990
              },
              "data1": { //起始时间初始数据
                  "account": "113",
                  "activated": "365",
                  "dau": "1008"
              },
              "data2": { //结束时间初始数据
                  "account": "1008",
                  "activated": "291",
                  "dau": "18"
              }
          },

      ]*/
      return {...state, rankTop10: action.arr};

    case SET_COUNT_GROWTH_FILTER:
      return {...state, growthDataFilter:{uid : action.uid,view:action.view}};
    case SET_COUNT_RANKTOP10_FILTER:
      return {...state, rankTop10DataFilter:{direction : action.direction,view:action.view}}

  }
  return state;
}