import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar, VictoryLabel, VictoryTheme, Rect
  , VictoryAxis, VictoryTooltip, VictoryPie, VictoryAnimation
} from "victory";
import {VCHART_THEME} from "../consts";
import MBScrollViewHelper from "../MBScrollViewHelper";

const {Row, Col} = Layout;

class ChartDonut1 extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      pieData: this.getData(0)
    };
  }

  static propTypes = {
    data: PropTypes.array,
  }

  componentDidMount()
  {
    this.scrollViewHelper = new MBScrollViewHelper(this.ref, this.onReveal.bind(this));

  }

  componentWillUnmount()
  {
    this.scrollViewHelper.dispose();
  }

  onReveal(){
    this.scrollViewHelper.dispose();
    let {data} = this.props;
    let {label,color , percent } = data;
    let pieData = this.getData(percent*100);
    this.setState({pieData});
  }

  getData(percent)
  {
    return [{x: 1, y: percent}, {x: 2, y: 100 - percent}];
  }

  render()
  {

    let {data} = this.props;
    let {label,color , percent } = data;
    let {pieData} = this.state;
    return (
      <div ref={(ref) => {this.ref = ref}}
        // style={{width: "442px",height:"162px",background:"red"}}
      >
        <svg viewBox="0 0 442 248" >
          <VictoryPie
            standalone={false}
            animate={{duration: 400}}
            width={210} height={210}
            data={pieData}
            innerRadius={75}
            cornerRadius={0}
            labels={() => null}
            style={{
              data: {
                fill: ({datum}) => {
                  return datum.x === 1 ? color : "#ebecee";
                }
              }
            }}
          />

          <VictoryAnimation duration={400} data={pieData[0]}>
            {(pieData) => {
              return (
                <VictoryLabel
                  textAnchor="middle" verticalAnchor="middle"
                  x={105} y={95}
                  text={`${Math.round(pieData.y)}%`}
                  style={{fontSize: 20,fill:"#3e71ff"}}
                />
              );
            }}
          </VictoryAnimation>

          <VictoryLabel
            textAnchor="middle" verticalAnchor="middle"
            x={105} y={115}
            text={`${label}`}
            style={{fontSize: 12,fill:"#bcbec2"}}
          />

          <g  >
            <rect x={75} y={226} width="8" height="8" fill={color}/>
          </g>
          ,
          <VictoryLabel

            x={90}
            y={230}
            text={label}
            style={{fontSize: 10}}
          />

        </svg>

      </div>
    );
  }

  renderTuli(svgW,svgH, li, index,arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 50;
    let x = (svgW-arr.length* liW)/2 + index * liW;
    let x2 = x+12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }
}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartDonut1);