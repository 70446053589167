
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  MessageBox, Message,Icon,
  DatePicker, Tabs, Dropdown
} from "element-react";
import 'element-theme-default';
import {
  openProjectId, SET_WEEK_REPORT_LIST,
  setAdminList, setViewPref, setWeekReportList,
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import Utils from "../Utils";
import QuickTimeTf from "../components/QuickTimeTf";
import {WEB_URL, WORK_DATE_NATURE} from "../consts";
import PageWrapper from "./PageWrapper";
import WeekCreate from "../components/WeekCreate";
import "./ProjectPage.css";
import runRefreshDailyReportList from "../controllers/runRefreshDailyReportList";
import ProjectMemberEdit from "../components/ProjectMemberEdit";
import runRefreshProjectMembers from "../controllers/runRefreshProjectMembers";

const {Row,Col} =Layout;
class ProjectPage extends Component
{
  constructor(props)
  {
    super(props);
    this.state={
      monthActive:new Date(),
      customerColumns: [
        {label: "周报", prop: "weekly_name"},
        {label: "更新时间", prop: "updateTime"},
        {label: "",  width: 100, render: this.renderActions.bind(this)}

      ],
      dailyColumns: [
        {label: "日报", prop: "date"},
        {label: "更新时间", prop: "updateTime"},
        {label: "", width:200,render: this.renderDailyActions.bind(this)}

      ],
      dialogWeek:false,
      dialogMembers : false
    };
  }

  renderActions(row){
    let {hoverRow} = this.state;
    let isOwner = this.calcIsProjectOwner();
    if (hoverRow==row && isOwner)
    {
      return (
        <span>
        <Button type="text" className="delete" size="small" onClick={this.onClickDelete.bind(this, row)}
                icon="delete"> 删除</Button>
      </span>
      )
    }
  }
  renderDailyActions(row){
    let {hoverRow} = this.state;
    if ( hoverRow==row && row.projectAddress)//
    {
      return (<div className={"actionBtns"}>
        <div className="actionBtn" onClick={this.onShare.bind(this,row)}>
          <img src={require("../res/1026/日报/share.png")}/>
          分享链接
        </div>
      <div className="actionBtn primary" onClick={this.onPdf.bind(this,row)}>
        <Icon name={"document"}/>
         下载PDF
      </div></div>
      )
    }
  }

  onShare(row,e){
    Utils.copyTXT(`${WEB_URL}/sharedday-${row.id}`);
    Message.success("链接已复制到剪贴板");
    e.stopPropagation();
  }

  onPdf(row,e){
    Facade.customHistory.push("daypdf-"+row.id);
    e.stopPropagation();
  }

  onClickDelete(row,e){
    e.stopPropagation();
    MessageBox.confirm('此操作将删除该周报, 是否继续?', '提示', {
      type: 'warning'
    }).then(() => {
      Facade.gw.deleteWeekly(row.id ,(data)=>{
        Message({
          type: 'success',
          message: '删除成功!'
        });
        this.refreshData(this.projectId,1,this.state.monthActive,this.props.tabActive);
      });
    }).catch(() => {
    });

  }

  componentDidMount()
  {
    this.projectId = this.props.match.params.id;
    this.refreshData(this.projectId,1,this.state.monthActive,this.props.tabActive);
    runRefreshProjectMembers(this.projectId,this.props.dispatch);
  }

  componentWillReceiveProps(nextProps, nextContext)
  {// 首次构造完毕不会触发，只有后续变化才会触发
    let nextProjectId =nextProps.match.params.id;
    if ( nextProjectId>0 && nextProjectId != this.projectId)
    {
      this.projectId = nextProps.match.params.id;
      this.refreshData(this.projectId,1,this.state.monthActive,nextProps.tabActive);
      runRefreshProjectMembers(this.projectId,this.props.dispatch);
    }
  }


  // month:月份 ；null表示不筛选
  refreshData(projectId,toPage, monthActive,tabActive)
  {
    //按最新筛选
    let {dispatch} = this.props;

    if ( tabActive == 2)
    {
      //日
      runRefreshDailyReportList(this.projectId,monthActive ,dispatch)

    }else if ( tabActive==1){//周

      let dateStart="1990-01-01";
      let dateEnd="2099-01-01";
      if (monthActive!= null)
      {
        dateEnd = TimeUtil.formatDBDate2(new Date(monthActive.getFullYear(), monthActive.getMonth()+1, 0));
        monthActive.setDate(1);
        dateStart = TimeUtil.formatDBDate2(monthActive);
      }
      this.setState({isLoading: true});
      Facade.gw.getProjectWeeklyList( this.projectId,dateStart,dateEnd,(list)=>{
        dispatch(openProjectId(projectId));
        dispatch(setWeekReportList(list));
        this.setState({isLoading: false});
      } );
    }
    this.setState({monthActive});
    this.props.dispatch(setViewPref("projectTab",tabActive));


  }

  //用户选择月份
  onFiltMonth(date){
    this.refreshData(this.projectId,1,date,this.props.tabActive);
  }

  onCellMouseEnter(row, column, cell, event){
    this.setState({hoverRow : row });
  }
  onCellMouseLeave(row, column, cell, event){
    this.setState({hoverRow : null });
  }
  onTabClick(tab){
    let tabActive = tab.props.name;
    this.refreshData(this.projectId,1,this.state.monthActive,tabActive);
  }

  render(){
    let {dispatch, weekReportList,  projectDetail,dailyLoading,dailyReportList,tabActive} = this.props;
    let {isLoading, monthActive} = this.state;
    let isProjectOwner=this.calcIsProjectOwner();
    //创建筛选器
    return (
      <PageWrapper >
        <div className="ProjectPage pageWithPadding">
          <div className="pageCard">

            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                  {projectDetail? projectDetail.name:""}
                </div>
              </Col>
              <Col span={12}>
                {/*<div className="tableTitleRight">*/}
                {/*  <DatePicker*/}
                {/*    value={month} placeholder="筛选月份" selectionMode="month"*/}
                {/*    onChange={this.onFiltMonth.bind(this)}/>*/}
                {/*  <Button className={"peopleBtn"} onClick={this.onClickPeople.bind(this)}>*/}
                {/*    <img src={require("../res/1026/周报/2.0项目/2_people.png")} className="peopleIcon"/> 项目成员</Button>*/}
                {/*</div>*/}
              </Col>
            </Row>
            <div className="ProjectTabBar cardWithPadding" style={{backgroundColor: "#fff"}}>

              <Tabs activeName={tabActive?tabActive.toString():"1"} onTabClick={this.onTabClick.bind(this)}>
                <Tabs.Pane label="周报" name="1"></Tabs.Pane>
                <Tabs.Pane label="日报" name="2"></Tabs.Pane>
              </Tabs>

            </div>
            <div className="ProjectToolbar cardWithPadding">
              <DatePicker
                value={monthActive} placeholder="筛选月份" selectionMode="month"
                onChange={this.onFiltMonth.bind(this)}/>
              <Button className={"peopleBtn"} onClick={this.onClickPeople.bind(this)}>
                <img src={require("../res/1026/周报/2.0项目/2_people.png")} className="peopleIcon"/> 项目成员
              </Button>
              {tabActive==1 && isProjectOwner &&
                <div className="createBtn" onClick={this.onClickCreateWeekly.bind(this)}>
                创建周报 <img src={require("../res/1026/周报/2.0项目/2_add.png")} className="createBtnIcon"/>
              </div>}
              {/*{tabActive==1 &&*/}
              {/*  <Button className={"peopleBtn"} onClick={this.onClickCreateWeekly.bind(this)} icon={"plus"}>*/}
              {/*    创建周报*/}
              {/*  </Button>}*/}

            </div>
            {tabActive == 1 && <Row>
              <Loading loading={isLoading}>
                <Table
                  style={{
                    width: 'auto',
                    marginTop: "20px",
                    marginBottom: '20px',
                    marginLeft: '24px',
                    marginRight: '24px'
                  }}
                  columns={this.state.customerColumns}
                  onCellClick={(row, column, cell, event) => {
                    if (column.swallow)
                    {
                      if (event) event.stopPropagation();
                    }
                  }}
                  data={weekReportList}
                  stripe={false}
                  highlightCurrentRow={true}
                  onCurrentChange={this.onRowWeekChange.bind(this)}
                  onCellMouseEnter={this.onCellMouseEnter.bind(this)}
                  onCellMouseLeave={this.onCellMouseLeave.bind(this)}
                />
              </Loading>
            </Row>
            }
            {tabActive==2 && <Row>
              <Loading loading={dailyLoading}>
                <Table
                  style={{width: 'auto', marginTop:"20px",marginBottom: '20px',marginLeft:'24px',marginRight:'24px'}}
                  columns={this.state.dailyColumns}
                  onCellClick={(row, column, cell, event) => {
                    if (column.swallow)
                    {
                      if (event) event.stopPropagation();
                    }
                  }}
                  data={dailyReportList}
                  stripe={false}
                  highlightCurrentRow={true}
                  onCurrentChange={this.onRowDailyChange.bind(this)}
                  onCellMouseEnter={ this.onCellMouseEnter.bind(this) }
                  onCellMouseLeave={ this.onCellMouseLeave.bind(this) }
                />
              </Loading>
            </Row>
            }



          </div>
        </div>
        <Dialog
          style={{width:'460px'}}
          title="新建周报"
          visible={this.state.dialogWeek}
          onCancel={() => {
            this.setState({dialogWeek: false});
          }}
        >
          <Dialog.Body>
            {this.state.dialogWeek && <WeekCreate projectId={this.projectId} onFinish={(id) => {
              this.setState({dialogWeek: false});
              //自动跳转过去
              setTimeout(()=>{
                Facade.customHistory.push(`/week-${id}`);
              },1);
            }}/>}
          </Dialog.Body>
        </Dialog>
        <Dialog
          title="项目成员"
          visible={this.state.dialogMembers}
          onCancel={() => {
            this.setState({dialogMembers: false});
          }}
        >
          <Dialog.Body>
            {this.state.dialogMembers && <ProjectMemberEdit projectId={this.projectId} onFinish={(id) => {
              this.setState({dialogMembers: false});
            }}/>}
          </Dialog.Body>
        </Dialog>
      </PageWrapper>
        );
  }
  onClickPeople(){
    this.setState({dialogMembers: true});
  }

  onRowWeekChange(currentRow, oldCurrentRow)
  {
    let {dispatch} = this.props;
    let li = currentRow;
    if (li)
    {
      Facade.customHistory.push(`/week-${li.id}`);
    }
  }
  onRowDailyChange(currentRow, oldCurrentRow)
  {
    let {dispatch} = this.props;
    let li = currentRow;
    if (li)
    {
      let hasEditPermission=this.calcDailyPermission();
      if ( hasEditPermission)
      {
        //日报编辑页，基础信息未填写的进入基础信息，填写基础信息的默认切换到日报编辑
        if ( li.projectAddress )
          Facade.customHistory.push(`/daytable-${li.id}`);
        else
          Facade.customHistory.push(`/day-${li.id}`);
      }else{
        if ( li.projectAddress )
          Facade.customHistory.push(`/sharedday-${li.id}`);
        else
          Message.success("日报尚未填写");

      }
    }
  }
  onClickCreateWeekly(){
    this.setState({dialogWeek: true});
  }

  calcDailyPermission(){
    let { mineId ,projectUser,  dailyUserList} = this.props;
    //项目负责人可编辑全部
    if ( mineId == projectUser.id) return true;
    //日报负责人可编辑日报

    if ( dailyUserList && dailyUserList.find(({id})=>id==mineId) )
    {
      return true;
    }
    return false;
  }

  calcIsProjectOwner(){
    let { mineId ,projectUser,  dailyUserList} = this.props;
    return mineId == projectUser.id;
  }


}


const mapStateToProps=(state)=>
{
  return {
    projectDetail: state.projects.projectDetail,
    openedProjectId: state.projects.openedProjectId,

    weekReportList: state.weekReports.weekReportList,
    weekReportDetail: state.weekReports.weekReportDetail,

    dailyReportList: state.dailyReports.dailyReportList,
    dailyLoading : state.general.isLoading.dailyList,
    tabActive : state.general.viewPref.projectTab,

    mineId : state.login.id,
    projectUser : state.projectMembers.projectUser,
    dailyUserList : state.projectMembers.dailyUserList,
    memberUserList : state.projectMembers.memberUserList,

  };
};
export default connect(
  mapStateToProps
)(ProjectPage);