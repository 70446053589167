
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import "./ChartRisk.css";
import StepNum from "./StepNum";
import {VictoryAnimation, VictoryLabel} from "victory";

const {Row,Col} =Layout;
class ChartRisk extends Component
{
  constructor(props)
  {
    super(props)
    this.state={};
  }

  componentDidMount()
  {
  }
  render(){
    return (
      <div className="ChartRisk">
        <svg viewBox="0 0 400 204" width="100%" height="100%">
          <defs>
            <filter id="drop-shadow">
              <feGaussianBlur in="SourceAlpha" result="blur" stdDeviation="2"  />
              <feGaussianBlur in="SourceAlpha" result="blur2" stdDeviation="2" />
              <feGaussianBlur in="SourceAlpha" result="blur3" stdDeviation="2" />
              <feMerge>
                <feMergeNode in="blur" mode="normal"/>
                {/*<feMergeNode in="blur2" mode="normal"/>*/}
                {/*<feMergeNode in="blur3" mode="normal"/>*/}
                <feMergeNode in="SourceGraphic" mode="normal"/>
                {/*<feComposite operator="in" in2="SourceGraphic"/>*/}
              </feMerge>
            </filter>
          </defs>

          {this.renderSvgLi(1,"20220315","延误",100,"建筑方案设计线上征询完成" ,0)}
          {/*{this.renderSvgLi(1,"2022-01-26","延误",93,"建筑方案设计线上征询完成")}*/}
          {this.renderSvgLi(1,"20220126","延误",45,"地下室南扩用地确权",1)}
          {this.renderSvgLi(1,"20220101","延误",41,"桩基中标通知书",2)}
          {this.renderSvgLi(2,"20220126","延误",12,"主体施工许可证",3)}
        </svg>

      </div>);
  }

  renderLi(level,date,type,num,reason){
    return (
      <div className="RiskLi">
        <div className={"level"+level}>{level==1 ? "高": (level==2?"中":"低")}</div>
        <div className="date">{date}</div>
        <div className="type">{type}</div>
        <div className="num"><StepNum from={0} to={num} prefix="" postfix="" totalFrame={num} /></div>
        <div className="reason">{reason}</div>
      </div>
    )
  }

  renderSvgLi (level,date,type,num,reason ,index){
    let levelStr = level==1 ? "高": (level==2?"中":"低");
    const marginY = 12;
    const liH = 40;
    const liW = 400-14*2;
    let rectL = 14;
    let rectT = (liH+marginY) * index + 5;
    let circleY = 20 + rectT;
    const circleX = rectL +12 +9;
    const circleTfX = rectL + 12 + 3;
    const circleTfY = rectT +20 + 4;
    const dateTfX = rectL + 50;
    const typeTfX = rectL + 125;
    const typeColor = level==1 ? "#ff3e2d": (level==2?"#f9c913":"#00ff00");
    const reasonTfX = rectL +214;
    const reasonTfW = liW-214 -12;
    let numTfX = 170;
    let numTfY =rectT +19;
    return (
      <g>
        <rect x={rectL} y={rectT} rx="4" ry="4" width={liW} height={liH} style={{fill:'#f2f4f5'}}
              // filter="url(#drop-shadow)"
        />
        <circle  cx={circleX} cy={circleY} r="10" style={{fill:typeColor}}/>
        <text x={circleTfX} y={circleTfY} style={{fill:"white",fontSize:12}} >
          {levelStr}
        </text>
        <text x={dateTfX} y={circleTfY}  style={{fill:"#757c88" ,fontSize:12}} >
          {date}
        </text>
        <text x={typeTfX} y={circleTfY}  style={{fill:"#757c88" ,fontSize:12}} >
          {type}
        </text>
        <text x={reasonTfX} y={circleTfY} textLength={reasonTfW} style={{fill:"#000000",fontWeight:500 ,fontSize:13,lengthAdjust :"spacing"}} >
          {reason}
        </text>
        <VictoryAnimation duration={400} data={num}>
          {(pieData) => {
            return (
              <VictoryLabel
                textAnchor="left" verticalAnchor="middle"
                x={numTfX} y={numTfY}
                text={num}
                style={{fontSize: 20,fill:typeColor,fontWeight:600}}
              />
            );
          }}
        </VictoryAnimation>

      </g>

    )
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList:state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartRisk);
