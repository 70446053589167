import {
  openProjectId, resetProjectMemebrs, setLoading,
  setProjectList, setProjectMembers,
  setWeekReportDetail,
  setWeekReportTaskFold, setWeekReportTaskList,
  setWeekReportTaskListFilter, setWeekReportTypeNum
} from "../actions";
import Facade from "../Facade";

/** 加载项目成员，用于后续操作权限判定
 * 包括周报任务修改权限
 * */
export default function runRefreshProjectMembers(projectId, dispatch) {

  dispatch(setLoading('projectMembers', true));
  dispatch(resetProjectMemebrs());
  Facade.gw.getProjectMembers(projectId, (data) => {
    let {projectUser, dailyUser, memberUserList} = data;
    dispatch(setProjectMembers(projectUser, dailyUser, memberUserList));
    dispatch(setLoading('projectMembers', false));

  });


}
