
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Loading, Dialog, Message} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import {
  setDailyBasic, setDailyConstructionList, setDailyConstructionTypeList,
  setDailyEquipmentList,
  setDailyPersonList, setDailySafety,
  setDailyWeather
} from "../reducers/dailyReportDetails";
import DailyTabBar from "../components/DailyTabBar";
import SgnrPanel from "../components/SgnrPanel";
import DailyPersonLi from "../components/DailyPersonLi";
import DailyEquipmentLi from "../components/DailyEquipmentLi";
import GalleryPanel from "../components/GalleryPanel";
import Utils from "../Utils";
import {WEB_URL} from "../consts";
import PdfHelper from "../PdfHelper";

const {Row,Col} =Layout;
class DailySharedPage extends Component
{
  static propTypes = {
    pdf: PropTypes.bool,
  }

  constructor(props)
  {
    super(props)
    this.state={};
  }
  static getDerivedStateFromProps(nextProps, prevState)
  {
    let dailyId = parseInt(nextProps.match.params.dailyId);
    return {
      dailyId
    }
  }


  componentDidMount()
  {
    this.refreshByDailyId(this.state.dailyId);

  }

  refreshByDailyId(dailyId)
  {
    let {dispatch} = this.props;
    this.setState({isDailyDetailFullLoading:true});
    Facade.gw.getDailyBasicInfo(dailyId,(vo)=>{
      dispatch(setDailyBasic(vo));
      Facade.gw.getDailyWeather(dailyId,(weather)=>{
        dispatch(setDailyWeather(weather));
        Facade.gw.getDailyPerson(dailyId,(personList)=>{
          dispatch(setDailyPersonList(personList));
          Facade.gw.getDailyEquipment(dailyId,(equipList)=>{
            dispatch(setDailyEquipmentList(equipList));
            Facade.gw.getDailySafetyMgmt(dailyId,(safety)=>{
              dispatch(setDailySafety(safety));
              Facade.gw.getDailyConstructionContent(dailyId,(conList)=>{
                dispatch(setDailyConstructionList(conList));
                Facade.gw.getDailyConstructionTypeList( (conTypeList)=>{
                  dispatch(setDailyConstructionTypeList(conTypeList));
                  this.setState({isDailyDetailFullLoading:false});
                  if ( this.props.pdf)
                  {
                    this.onDownPdf();
                  }
                });
              });

            });
          })
        });
      });
    });

    this.setState({isDailyDetailFullLoading:false});
  }


  render(){

    let {dailyId,isDailyDetailFullLoading,editSgnrData,isGenPdf} = this.state;
    let {dispatch,dailyBasic,dailyWeather,dailyPersonList,dailyPersonSum,dailyEquipmentList,dailyConstructionContentList,dailySafety} = this.props;
    let isShowBtns = !isGenPdf;
console.log("DailySharedPage/render",isShowBtns);
    return (
      <div id={"DailyPage"} className="DailyPage shared pageWithPadding">

        <Loading loading={isDailyDetailFullLoading}  >
          <div className="pageCard">

            <div className="sharedTitleRow">
              <div className={"logo"}>
                <img src={require("../res/1026/日报/logo.png")}/>
              </div>

              <div className="tableTitle">
                {dailyBasic.projectName} 施工日报 {dailyBasic.date}
              </div>

              {isShowBtns && <div className={"btns"}>
                <div className="topbtn" onClick={this.onShare.bind(this)}>
                  <img src={require("../res/1026/日报/share.png")}/>
                  分享日报
                </div>
                <div className="topbtn primary" onClick={this.onDownPdf.bind(this)}>
                  <img src={require("../res/1026/日报/download.png")}/>
                  下载PDF
                </div>
              </div>}
            </div>

            <div className="sharedHeadTitle">
              施工日报
            </div>

            <div className="rbTableWrap">
              <table className={"rbTable"}>

                <tr>
                  <td className="sectionHead first" colSpan={2}>1-项目基本情况</td>
                </tr>
                <tr>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>建设单位：</div>
                      {dailyBasic.organization}
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>项目名称：</div>
                      {dailyBasic.projectName}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>项目编号：</div>
                      {dailyBasic.projectNumber}
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>地址：</div>
                      {dailyBasic.projectAddress}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="inputTfRow">
                      <div className={"label"}>报告编号：</div>
                      {dailyBasic.reportNumber}
                    </div>
                  </td>
                </tr>
              </table>

              <table className={"rbTable"}>

                <tr>
                  <td className="sectionHead" colSpan={4}>2-天气情况</td>
                </tr>
                <tr>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>天气：</div>
                      {dailyWeather.textDay}
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>最高温度：</div>
                      {dailyWeather.tempMax}
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>最低温度：</div>
                      {dailyWeather.tempMin}
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>风力：</div>
                      {dailyWeather.windScaleDay}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <div className="inputTfRow">
                      <div className={"label"}>受天气影响工作：</div>
                      {dailyWeather.isWeatherInfluence==1?"有":"无"}

                    </div>
                  </td>
                </tr>

              </table>

              <table className={"rbTable"}>
                <tbody>
                <tr>
                  <td className="sectionHead" colSpan={3}>3-人员设备</td>
                </tr>
                <tr>
                  <td>
                    <div className="th">
                      单位
                    </div>
                  </td>
                  <td>
                    <div className="th">
                      工种
                    </div>
                  </td>
                  <td>
                    <div className="th">
                      人员数量
                    </div>
                  </td>
                </tr>
                {dailyPersonList.map( li=> {
                  return (<tr>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.organization}</div>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.department}</div>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.personNum}</div>
                      </div>
                    </td>
                  </tr>)
                })}
                <tr>
                  <td colSpan={2} className={"th"}>
                    <div className="sum">
                      现场总人数
                    </div>
                  </td>
                  <td>
                    <div className="sum">
                      上述汇总：{dailyPersonSum}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="th">
                      常驻大型机械设备
                    </div>
                  </td>
                  <td>
                    <div className="th">
                      型号
                    </div>
                  </td>
                  <td>
                    <div className="th">
                      数量
                    </div>
                  </td>
                </tr>
                {dailyEquipmentList.map( li=> {
                  return (<tr>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.model}</div>
                      </div>
                    </td>
                    <td>
                      <div className="inputTfRow">
                        <div className={"val"}>{li.amount}</div>
                      </div>
                    </td>
                  </tr>)
                })}

                </tbody>
              </table>

              {/*安全管理：*/}
              <table className={"rbTable"}>
                <tbody>
                <tr>
                  <td className="sectionHead">4-安全管理</td>
                </tr>
                <tr>
                  <td >
                    <div className={"val"}>{dailySafety}</div>
                  </td>
                </tr>
                </tbody>
              </table>
              {/*主要施工内容*/}
              <table className={"rbTable"}>
                <tbody>
                <tr>
                  <td className="sectionHead">5-主要施工内容</td>
                </tr>

                {dailyConstructionContentList.map(this.renderConstructionLi.bind(this))}


                </tbody>
              </table>


            </div>



          </div>
        </Loading>

        {this.renderGalleryPanel()}


      </div>);
  }

  renderConstructionLi (li,i){
    return [
      <tr key={"const-title-"+i}>
        <td>
          <div className="sgnr-titlerow">
            <div className="sgnr-title" >{li.dailyConstructionTypeName}</div>
          </div>
        </td>
      </tr>,
      <tr key={"const-article-"+i} >
        <td>
          <div className="sgnr-article" dangerouslySetInnerHTML={{__html: li.content}}>
          </div>
        </td>
      </tr>,
      <tr key={"const-gallery-"+i} >
        <td>
          <Row gutter={10} className="sgnr-gallery" >
            {
              li.pictures.split(",").map((picLi,picI)=>{
                return (<Col span={6} key={"galleryImg"+li.id+"-"+picI}>
                  <img className={"galleryImg"} src={picLi} alt="" onClick={this.onClickImg.bind(this,picLi,li)}/>
                </Col>)
              })
            }

          </Row>
        </td>
      </tr>];
  }
  renderGalleryPanel(){
    let {dialogImgGalleryVisible,galleryImgList,galleryImg} = this.state;
    return dialogImgGalleryVisible && <GalleryPanel pictureList={galleryImgList}
                                                    picture={galleryImg}
                                                    onCancel={() => {
                                                      this.setState({dialogImgGalleryVisible: false})
                                                    }
                                                    }/>
  }

  onClickImg(url,constructionLi,e)
  {
    // console.log("DailyTablePage/onClickImg");
    let galleryImgList=constructionLi.pictures.split(",");
    let galleryImg = url;
    this.setState({dialogImgGalleryVisible: true ,galleryImgList,galleryImg})
    e.stopPropagation();
  }


  onShare(){
    Utils.copyTXT(`${WEB_URL}/sharedday-${this.state.dailyId}`);
    Message.success("链接已复制到剪贴板");
  }


  onDownPdf(){
    this.setState({isGenPdf:true});
    setTimeout(()=>{
      PdfHelper.exportPdf("#DailyPage",()=>{
        this.setState({isGenPdf:false});
      });
    },10);
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    dailyBasic: state.dailyReportDetails.basic,
    dailyWeather: state.dailyReportDetails.weather,
    dailyPersonList: state.dailyReportDetails.personList,
    dailyPersonSum: state.dailyReportDetails.personSum,
    dailyEquipmentList: state.dailyReportDetails.equipmentList,
    dailyConstructionContentList: state.dailyReportDetails.constructionContent,
    dailySafety: state.dailyReportDetails.safety,
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(DailySharedPage);