import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

const {Row,Col} =Layout;

/**
 * 登录-跳转回调页面 (获得token)
 */
class LoginPage extends Component
{

  constructor(props)
  {
    super(props);

  }

  componentDidMount()
  {
    // 检测是发起还是返回
    let s= window.document.location.href;//因为域名可能是 .../#/xx?token=xxx 所以不会在window.document.location.search中，需要自行裁剪
    let indexOf=s.indexOf("?")
    if ( indexOf >-1)
    {
      let query = s.substr(indexOf + 1);
      // 有可能 ?token=xxx#/xx 要截取#前面的
      let indexOfSharp=query.indexOf("#");
      if ( indexOfSharp>-1)
      {
        query = query.substr(0,indexOfSharp);
      }
      // console.log("LoginPage/componentDidMount 带参数",query);
      let queryArr = query.split("&");
      for(let i = 0;i<queryArr.length;i++)
      {
        let [k,v]=queryArr[i].split("=");
        if ( k=="token")
        {
          let token = v;
          Facade.oauth.loginSucc(token);
          return;
        }
      }
    }else{
      // console.log("LoginPage/componentDidMount 无参数 加载并跳转首页"      );
      // 如果没有token传入的话 默认处理： 假定是登录的，则请求个人信息并跳转；其中如果无登录态会被弹走
      Facade.gw.getMine(({id,nickName,avatarUrl})=>{
        this.props.dispatch(loginLogin(id,nickName,avatarUrl));
        //跳转
        Facade.customHistory.push("/home");
      })
      // // 发起登录
      // console.log("LoginPage/componentDidMount 发起登录");
      // Facade.oauth.gotoOAuth();
    }
  }

  render()
  {
    let {dispatch}  = this.props;

    return (
      <div className="LoginPage"  style={{marginTop:'20%' }}>
        正在跳转...
      </div>);
  }
  
  static propTypes = {
    customerList: PropTypes.array.isRequired,
    onAddClick: PropTypes.func,
  }
}



const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {customerList:state.customers.customerList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(LoginPage);