import React, {Component} from "react";
import PropTypes from "prop-types";
import {Dropdown, Message} from "element-react";
import Facade from "../Facade";
import {setDailyEquipmentList} from "../reducers/dailyReportDetails";

export default class DailyEquipmentLi extends Component
{
  static propTypes = {
    isNew: PropTypes.bool.isRequired, //是否新建的
    onSave:PropTypes.func.isRequired,
    onDel :PropTypes.func.isRequired,
    //height :PropTypes.number,//高度
  };

  state ={showActions:false}

  componentDidMount()
  {
    let {name,model,amount} = this.props.data;
    this.setState({name,model,amount});
  }

  render()
  {
    let {name,model,amount,showActions} = this.state;
    return (<tr className="equipmentTr"
                onMouseOver={this.onMouseOverMe.bind(this)}
                onMouseOut={this.onMouseOutMe.bind(this)}>
      <td >
        {showActions&&<div
          onMouseOver={this.onMouseOverMe.bind(this)}
          onClick={(e)=>{e.stopPropagation()}} className={"dropDownWrapper"}>
          <Dropdown className="equipmentDropdown" menuAlign="end"  onCommand={this.onDropDownCommand.bind(this)}
                    menu={
                      <Dropdown.Menu className={"equipmentDropdownMenu"}>
                        <Dropdown.Item command={"del"} className="dropDownLi">
                          删除</Dropdown.Item>
                      </Dropdown.Menu>
                    }>
            <img src={require("../res/1026/周报/2.3/2_3_more.png")} className="dropDownIcon" />
          </Dropdown>
        </div>}
        <div className="inputTfRow">
          <input className={"val"} value={name}  onChange={this.onChange.bind(this,"name")} onBlur={this.onBlur.bind(this)}/>
        </div>
      </td>
      <td>
        <div className="inputTfRow">
          <input className={"val"} value={model} onChange={this.onChange.bind(this,"model")} onBlur={this.onBlur.bind(this)}/>
        </div>
      </td>
      <td>
        <div className="inputTfRow">
          <input className={"val"} value={amount} onChange={this.onChange.bind(this,"amount")} onBlur={this.onBlur.bind(this)}/>
        </div>
      </td>
    </tr>);
  }
  onDropDownCommand(cmd){
    if ( cmd == "del")
    {
      let {id } = this.props.data;
      this.props.onDel(id);
    }
  }
  onMouseOverMe(){
    this.setState({showActions:true});
    clearTimeout(this.timer);
  }
  onMouseOutMe(){
    //delay to hide
    this.timer = setTimeout (()=>{
      this.setState({showActions:false});
    },100);
  }
  onChange(key ,evt)
  {
    this.state[key] = evt.target.value;
    this.forceUpdate();
  }

  onBlur(){

    let {name,model,amount} = this.state;
    let { isNew } = this.props;
    let {id} = this.props.data;
    this.props.onSave(isNew, id,name,model,amount)
  }

}
