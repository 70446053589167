import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Card,
  Tabs
} from "element-react";
import 'element-theme-default';
import * as V from 'victory';
import TopMenu from "../components/NavMenu";
import {
  customerUpdate,
  customerOpen,
  setAdminList,
  setCustomerFilter,
  setCustomerList,
  loginRecovery
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import HseAuditResultChart from "../components/hse/HseAuditResultChart";
import ManpowerChart from "../components/manpower/ManpowerChart";
import ProjectSumTable from "../components/ProjectSumTable/index";
import DesignCommentsChart from "../components/designcomments/DesignCommentsChart";
import ContractSnagChart from "../components/ContractSnag/ContractSnagChart";
import PageWrapper from "./PageWrapper";
import ChartRfi from "../components/ChartRfi";
import ChartAqrl from "../components/ChartAqrl";
import ChartNcr from "../components/ChartNcr";
import ChartRisk from "../components/ChartRisk";
import WeekGraphCard from "../components/WeekGraphCard";
import "./Dashboard2Page.css";
import StepNum from "../components/StepNum";
import SimpleTable from "../components/SimpleTable";
import ChartBars1 from "../components/ChartBars1";
import ChartBars2 from "../components/ChartBars2";
import ChartDonut1 from "../components/ChartDonut1";
import ChartDonut2 from "../components/ChartDonut2";
import ChartDonut3 from "../components/ChartDonut3";
import ChartBars3 from "../components/ChartBars3";


const {Row, Col} = Layout;


class Dashboard2Page extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      isLoading: true,
      type: 0,

    }
  }


  componentDidMount()
  {

  }

  componentWillUnmount()
  {
  }


  render()
  {
    let {isLoading, type} = this.state;
    return (
      <PageWrapper activeNav={"1-dashboard2"}>
        <div className="Dashboard2Page pageWithPadding">

          <Row>
            <Col span={24}>
              <div className="tableTitle">
                <img src={require("../res/0810/a1.png")}/>
                西岸智慧谷 周报总览
              </div>
            </Col>
          </Row>


          <div className="dashboard2-card">
            <Row>
              <Col span={24}>
                <div className="cardtitle ">
                  <img src={require("../res/0810/b1.png")}/>
                  基础信息
                </div>
              </Col>
            </Row>


            <Row style={{paddingBottom: "20px"}}>
              <Col span={8}>
                {this.renderJcxx("项目编号", "DOUSH-01")}
                {this.renderJcxx("项目名称", "西岸智慧谷")}
                {this.renderJcxx("项目面积", "40000㎡/地上30000㎡/地下10000㎡/10F")}

              </Col>
              <Col span={8}>
                {this.renderJcxx("项目经理", "李菲菲")}
                {this.renderJcxx("工位数", "4800")}
                {this.renderJcxx("项目阶段", "规划/立项/设计/采购/施工/竣工")}
              </Col>
              <Col span={8}>
                {this.renderJcxx("土地获取时间", "2018-07-06")}
                {this.renderJcxx("项目施工许可", "2019-11-30")}
                {this.renderJcxx("项目竣工验收", "2020-03-31")}
              </Col>
            </Row>
          </div>


          <div className="dashboard2-card">
            <Row>
              <Col span={24}>
                <div className="cardtitle ">
                  <img src={require("../res/0810/b1.png")}/>
                  整体进展
                </div>
              </Col>
            </Row>

            <Row style={{paddingBottom: "20px"}} className="dashboard2-ztjz-row" gutter={20}>
              <Col span={7} lg={8}>
                {this.renderZtjz("项目总结", "项目顺利开展 历时2年6个月完成工 期间各施工单位配合密切 众志成城 排除一切困难  顺利按时完成项目\n")}
                {this.renderZtjz("进展说明", "项目顺利开展 历时2年6个月完成工 期间各施工单位配合密切 众志成城 排除一切困难 \n")}

              </Col>
              <Col span={7} lg={8}>
                {this.renderZtjz("项目计划", "设计需求对接 电量BMS方案确认 图纸整理 总包清标\n")}
                {this.renderZtjz("变更说明", "设计需求对接 电量BMS方案确认 图纸整理 总包清标\n")}
              </Col>
              <Col span={10} lg={8}>
                <div style={{marginLeft: "24px"}}>
                  <div className="coltitle">
                    <img src={require("../res/0810/c1.png")}/>
                    成本情况
                  </div>
                  <div className="yusuan">
                    预算
                    <div className="yusuan-content">
                      <StepNum from={0} to={326401462} prefix="" postfix="" totalFrame={50}/>
                    </div>
                    CNY
                  </div>

                  {this.renderCbqk("已签订合同", 216220231, {background: "rgba(0, 140, 255, 1)"})}
                  {this.renderCbqk("待签订合同", 100000000, {background: "rgba(0, 218, 228, 1)"})}
                  {this.renderCbqk("批准变更", 965886, {background: "rgba(43, 231, 177, 1)"})}
                  {this.renderCbqk("待批复变更", 0, {background: "rgba(153, 234, 102, 1)"})}
                  {this.renderCbqk("预计变更", 0, {background: "rgba(255, 148, 59, 1)"})}
                  {this.renderCbqk("预计结算价", 0, {background: "rgba(86, 91, 254, 1)"})}
                  {this.renderCbqk("已支付金额", 0, {background: "rgba(67, 20, 231, 1)"})}

                  <div className="coltitle">
                    <img src={require("../res/0810/c2.png")}/>
                    现金流
                  </div>
                  {this.renderCbqk("本周支付金额", 0, {background: "rgba(0, 140, 255, 1)"})}
                  {this.renderCbqk("累计支付金额", 0, {background: "rgba(0, 218, 228, 1)"})}
                  {this.renderCbqk("预计支付金额", 0, {background: "rgba(43, 231, 177, 1)"})}
                </div>
              </Col>
            </Row>


            <Row style={{paddingBottom: "20px"}} className="dashboard2-ztjz-row" gutter={20}>
              <Col span={14} lg={16}>

                <div className="coltitle">
                  <img src={require("../res/0810/c3.png")}/>
                  项目问题&风险
                </div>

                <SimpleTable/>


              </Col>
              <Col span={10} lg={8}>
                <div style={{marginLeft: "24px"}}>

                  <div className="coltitle">
                    <img src={require("../res/0810/c4.png")}/>
                    现场安全
                  </div>

                  {this.renderXcaq("人身伤亡", 0, {background: "rgba(0, 140, 255, 1)"})}
                  {this.renderXcaq("大型事故", 1, {background: "rgba(0, 218, 228, 1)"})}
                  {this.renderXcaq("小型事故", 2, {background: "rgba(43, 231, 177, 1)"})}
                  {this.renderXcaq("失误", 36, {background: "rgba(153, 234, 102, 1)"})}
                  <div style={{height: "20px"}}></div>
                  {this.renderXcaq("人力资源", 288, {background: "rgba(255, 148, 59, 1)"},"小时")}
                  {this.renderXcaq("风险项", 24, {background: "rgba(86, 91, 254, 1)"},"个")}
                  {this.renderXcaq("风险减轻", 16, {background: "rgba(67, 20, 231, 1)"},"个")}
                  {this.renderXcaq("HSE事故", 18, {background: "rgba(0, 140, 255, 1)"},"个")}
                </div>
              </Col>
            </Row>




            {/*图表开始*/}
            <Row  className="dashboard2-chart-row">
              <Col span={12} className={"dashboard2-chartcol1"}>

                <div className="coltitle">
                  <img src={require("../res/0810/c5.png")}/>
                  采购完成度
                </div>
                <div className="chart-center-wrap">

                  <ChartBars1 data={[
                    {x: "计划", y: 0.50, color: "#4376ff"},
                    {x: "实际", y: 1.00, color: "#4eecbb"},
                  ]
                  }/>
                </div>

              </Col>
              <Col span={12} className={"dashboard2-chartcol2"}>
                <div className="coltitle">
                  <img src={require("../res/0810/c6.png")}/>
                  施工完成度
                </div>

                <div className="chart-center-wrap">
                  <ChartBars1 data={[
                    {x: "计划", y: 0.50, color: "#fada61"},
                    {x: "实际", y: 1.00, color: "#ffa356"},
                  ]
                  }/>
                </div>

              </Col>
            </Row>
            <Row  className="dashboard2-chart-row">
              <Col span={12} className={"dashboard2-chartcol1"}>

                <div className="coltitle">
                  <img src={require("../res/0810/c7.png")}/>
                  进展情况
                </div>

                <div className="chart-center-wrap">
                  <ChartBars2 data={[
                    {x: "总体", y: 0.75, color: "#4376ff"},
                    {x: "施工阶段", y: 0.24, color: "#4376ff"},
                    {x: "设计阶段", y: 0.98, color: "#4376ff"},
                    {x: "报批阶段", y: 0.60, color: "#4376ff"},
                  ]
                  }/>
                </div>


              </Col>
              <Col span={12} className={"dashboard2-chartcol2"}>
                <div className="coltitle">
                  <img src={require("../res/0810/c8.png")}/>
                  设计情况
                </div>

                <div className="chart-center-wrap">
                  <ChartDonut1 data={{label: "已完成", percent: 0.65, color: "#3661ee"}}/>
                </div>

              </Col>
            </Row>
            <Row  className="dashboard2-chart-row">
              <Col span={12} className={"dashboard2-chartcol1"}>

                <div className="coltitle">
                  <img src={require("../res/0810/c9.png")}/>
                  材料样板
                </div>

                <div className="chart-center-wrap">
                  <ChartDonut2 data={[
                  {x: "样板批复", num:24,y: 24, color: "#4376ff"},
                  {x: "材料到场", num:98,y: 98, color: "#00dae4"},
                  {x: "样板批准", num:60,y: 60, color: "#2be7b1"},
                ]
                }/>
                </div>


              </Col>
              <Col span={12} className={"dashboard2-chartcol2"}>
                <div className="coltitle">
                  <img src={require("../res/0810/c10.png")}/>
                  质量缺陷整改
                </div>

                <div className="chart-center-wrap">
                  <ChartDonut3 data={[
                  {label: "整改完成项", num:24, color: "#4376ff"},
                  {label: "待整改项", num:98, color: "#00dae4"},
                ]} />
                </div>

              </Col>
            </Row>

          </div>

          {/*最下面的大图表*/}
          <div className="dashboard2-card">
            <Row>
              <Col span={24}>
                <div className="cardtitle ">
                  <img src={require("../res/0810/d1.png")}/>
                  项目进展
                </div>
              </Col>
            </Row>

            {/*tabbar*/}
            <div className="dashboard2-tabbarWrapper" style={{backgroundColor: "#fff"}}>

              <div className="dashboard2-tabbarRightFloat">
                <div className="btn ">年视图</div>
                <div className="btn active">月视图</div>

              </div>

              <Tabs  onTabClick={this.onTabClick.bind(this)} className={"shortTabs"}>
                <Tabs.Pane label="项目全部阶段" name="0"></Tabs.Pane>
                <Tabs.Pane label="项目子阶段" name="1"></Tabs.Pane>
              </Tabs>

            </div>

            <Row style={{paddingBottom: "20px"}}>
              <div className="chartbig-center-wrap">
                <ChartBars3 data={[
                  {name: "项目阶段", bold:true , end: "2022-01-14",start:'2022-10-01', baselineEnd: "2022-01-14",baselineStart:'2022-10-01'},
                  {name: "设计阶段", bold:true, end: "2022-02-01",start:'2022-07-01', baselineEnd: "2022-02-14",baselineStart:'2022-02-11'},
                  {name: "需求收集", bold:false, end: "2022-02-14",start:'2022-07-01', baselineEnd: "2022-02-14",baselineStart:'2022-03-12'},
                  {name: "平面图纸", bold:false, end: "2022-02-14",start:'2022-07-01', baselineEnd: "2022-02-14",baselineStart:'2022-04-13'},
                  {name: "概念设计", bold:false, end: "2022-03-14",start:'2022-07-01', baselineEnd: "2022-03-18",baselineStart:'2022-04-14'},
                  {name: "报批图纸", bold:false, end: "2022-03-14",start:'2022-07-01', baselineEnd: "2022-03-14",baselineStart:'2022-04-15'},
                  {name: "施工图纸", bold:false, end: "2022-03-14",start:'2022-07-01', baselineEnd: "2022-03-14",baselineStart:'2022-03-16'},
                  {name: "报批阶段", bold:true, end: "2022-06-14",start:'2022-07-01', baselineEnd: "2022-06-14",baselineStart:'2022-07-11'},
                  {name: "物业报批", bold:false, end: "2022-06-14",start:'2022-07-01', baselineEnd: "2022-06-18",baselineStart:'2022-07-16'},
                  {name: "消防报批", bold:false, end: "2022-06-14",start:'2022-07-01', baselineEnd: "2022-06-18",baselineStart:'2022-07-12'},
                  {name: "建委报批", bold:false, end: "2022-07-14",start:'2022-07-01', baselineEnd: "2022-07-19",baselineStart:'2022-07-19'},
                  {name: "其它", bold:false, end: "2022-07-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                  {name: "施工阶段", bold:true, end: "2022-08-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                  {name: "收楼", bold:false, end: "2022-08-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                  {name: "验线", bold:false, end: "2022-08-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                  {name: "天花隐藏", bold:false, end: "2022-08-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                  {name: "竣工验收", bold:false, end: "2022-08-14",start:'2022-07-01', baselineEnd: "2022-08-14",baselineStart:'2022-07-01'},
                ]}/>
              </div>

            </Row>
          </div>


        </div>
      </PageWrapper>);
  }
  onTabClick(){}

  renderJcxx(label, value)
  {
    return (
      <div className="dashboard2-jcxx-item">
        <div className="dashboard2-jcxx-label">{label}：</div>
        <div className="dashboard2-jcxx-value">{value}</div>
      </div>
    );
  }

  renderZtjz(label, value)
  {
    return (
      <div className="dashboard2-ztjz-item">
        <div className="dashboard2-ztjz-label">
          <div className={"Rectangle115"}></div>
          {label}：
        </div>
        <div className="dashboard2-ztjz-value">{value}</div>
      </div>
    );
  }
  renderCbqk( label, value ,color)
  {
    return (
      <div className="dashboard2-cbqk-item">
        <div className={"dashboard2-cbqk-ellipse"} style={color}></div>
        <div className="dashboard2-cbqk-label">
          {label}：
        </div>
        <div className="dashboard2-cbqk-value">
          <StepNum from={0} to={value} prefix="" postfix="" totalFrame={50}/>
        </div>
        <div>CNY</div>
      </div>
    );
  }

  renderXcaq(label, value, color,postfix="")
  {
    return (
      <div className="dashboard2-cbqk-item">
        <div className={"dashboard2-cbqk-ellipse"} style={color}></div>
        <div className="dashboard2-cbqk-label">
          {label}：
        </div>
        <div className="dashboard2-cbqk-value">
          <StepNum from={0} to={value} prefix="" postfix="" totalFrame={50}/>
        </div>
        <div>{postfix}</div>
      </div>
    );
  }


}


const mapStateToProps = (state) => {
  return {
    customerList: state.customers.customerList,
    customerFilter: state.customers.customerFilter,
    total: state.customers.total,
    page: state.customers.page,
    totalPage: state.customers.totalPage,
    adminList: state.admin.adminList
  };
};
export default connect(
  mapStateToProps
)(Dashboard2Page)