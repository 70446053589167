import {
  SET_WEEK_REPORT_LIST_FILTER,
  SET_WEEK_REPORT_LIST,
  SET_WEEK_REPORT_DETAIL,
  SET_WEEK_REPORT_TEST_DATA,
  SET_DELETED_WEEK_REPORT_LIST
} from "../actions";

export default function weekReports(state = {
  weekReportList:[]
  , total: 0, page: 1, pageSize: 2
  , weekReportDetail: null
  , weekReportFilter: {month : new Date()}//month表示某个月 筛选用，null表示全部
  ,deletedWeeklyList:[]
}, action) {
  switch (action.type)
  {
    case SET_WEEK_REPORT_LIST:
      let {total, page, pageSize, voArr} = action;
      return {...state, weekReportList: voArr.map(pollyfillWeekReport), total, page, pageSize};
    case SET_WEEK_REPORT_DETAIL:
      return {...state , weekReportDetail:pollyfillWeekReport(action.vo)};
    case SET_WEEK_REPORT_LIST_FILTER:
      return {...state, weekReportFilter : action.vo};
    case SET_WEEK_REPORT_TEST_DATA:
      return {...state, weekReportList: action.voArr.map(pollyfillWeekReport),total:5,page:1,pageSize:2,
        weekReportFilter: {month : new Date(2022,6,1)}
      , weekReportDetail: action.voArr[0]
      };
    case SET_DELETED_WEEK_REPORT_LIST:
      return {...state, deletedWeeklyList: action.voArr.map(pollyfillWeekReport), total:action.total,page:action.page ,pageSize:action.pageSize};

    default :return state;
  }
}

function pollyfillWeekReport(li, i)
{
  let {dateStart ,dateEnd} = li;
  li. dateBucket= `${dateStart} ~ ${dateEnd}`;
  return li;
}
