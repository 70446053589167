import * as echarts from 'echarts';
import React, {Component} from 'react';

import {connect} from "react-redux";
class ManpowerChart extends Component
{


  componentDidMount()
  {

this.initChart2();

  }

  render()
  {
    return (

      <div className="manpower-chart">
        <div className="manpower-chart-content" style={{height: 300}}>
          <div id="manpower-chart" style={{width: '100%', height: '100%'}}></div>
        </div>
      </div>
    );
  }


  initChart2()
  {

    var chartDom = document.getElementById('manpower-chart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      legend: {},
      tooltip: {},
      dataset: {
        source: [
          ['product', '2015', '2016', '2017','2018'],
          ['Daily average', 8,45,93,130],
          ['Rolling Workhours', 2040,15540,43440,82440],
        ]
      },
      xAxis: { type: 'category' },
      yAxis: {},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [{ type: 'bar',label: {
          show: true,
          position: 'inside'
        } }, { type: 'bar' ,label: {
          show: true,
          position: 'inside'
        }}, { type: 'bar',label: {
          show: true,
          position: 'inside'
        } }, { type: 'bar',label: {
          show: true,
          position: 'inside'
        }
         }]
    };

    option && myChart.setOption(option);
  }
}


const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {vo: state.customers.openCustomer};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ManpowerChart);