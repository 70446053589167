
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {
  Button, Layout, Input, Form, Upload, Select
  , Message, DatePicker,DateRangePicker,Loading
} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import TimeUtil from "../TimeUtil";

const {Row,Col} =Layout;
class WeekCreate extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      form: {
        project: null, date: null, filePath: ""
      }
      , formRules: {
        date: {required: true, message: "请选择日期"}
      },
      dateEnd:null,
      fullscreenLoading:false
    };
  }

  componentDidMount()
  {
  }
  render(){
    let { projectId} = this.props;
    let {filePath} = this.state.form;
    return (
      <div className="WeekCreate" style={{width: "80%",margin:"auto"}}>
        <Form ref="form" model={this.state.form} labelPosition="top" rules={this.state.formRules}>


          <Form.Item label="选择周报日期" prop="date">
            <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>

            <DatePicker
              className="form-input Select"
              placeholder="选择日期"
              disabledDate={(date,selectionMode)=>date.getDay()!=1}
              format={"yyyy-MM-dd"}
              firstDayOfWeek={1}
              value={this.state.form.date}
              onChange={this.onChange.bind(this,"date")}
            />
            <img src={require("../res/1026/周报/2.0项目/2_to.png")} alt="" style={{width:'24px',height:'24px',marginLeft:"12px",marginRight:'12px'}}/>
            <DatePicker
              isDisabled={true}
              className="form-input Select"
              placeholder="选择日期"
              format={"yyyy-MM-dd"}
              firstDayOfWeek={1}
              value={this.state.dateEnd}
            />
            </div>

          </Form.Item>


          <Button onClick={this.onSubmit.bind(this)} type={"primary"} style={{width:"186px",height:'46px'}}>提交</Button>
        </Form>
        {
          this.state.fullscreenLoading && <Loading fullscreen={true} />
        }
      </div>);
  }

  onChange(key ,val)
  {
    this.state.form[key] = val;
    console.log("WeekCreate/onChange",this.state.form);
    if ( key =="date")
    {
      let dateEnd = val? TimeUtil.getWeeklyEndDay(val) : null;
      console.log("WeekCreate/onChange",dateEnd);
      this.setState({dateEnd})
    }
    this.forceUpdate();
  }

  onSubmit(){
    this.refs.form.validate((valid) => {
      if (valid){
        let { date} = this.state.form;
        let {projectId} = this.props;
        let dateStart = TimeUtil.formatDBDate2(date);
        this.setState({fullscreenLoading:true});
        Facade.gw.weeklyPost( projectId,dateStart,  (data) => {
          this.setState({fullscreenLoading:false});
          Message.success("提交成功");
          this.resetForm();
          this.props.onFinish != null && this.props.onFinish(data);
        },()=>{
          this.setState({fullscreenLoading:false});
        });
      }
    });
  }

  resetForm(){
    this.refs.form.resetFields();
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {projectList:state.projects.projectList};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(WeekCreate);