import {openProjectId, setLoading} from "../actions";
import Facade from "../Facade";
import {setDailyReportList} from "../reducers/dailyReports";
import TimeUtil from "../TimeUtil";

/** 加载一个项目的日报列表 ，同时会打开所属项目 */
export default function runRefreshDailyReportList(projectId,month,dispatch){
  dispatch(setLoading("dailyList", true));

  //month 客户端用的是Date，服务端此接口用的是 yyyy-mm
  if ( month==null)
  {
    month = new Date();
  }
  let serverMonthArg = TimeUtil.formatDBDate3(month);
  Facade.gw.getDailyList( projectId,serverMonthArg,(list)=>{
    dispatch(openProjectId(projectId));
    dispatch(setDailyReportList(list));
    dispatch(setLoading("dailyList", false));

  } );
}
