import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  MessageBox,Message,
  DatePicker
  ,Tag
} from "element-react";
import "./SimpleTable.css";

export default class SimpleTable extends Component
{
  static propTypes = {
    //height :PropTypes.number,//高度
  };

  constructor(props)
  {
    super(props);
    this.state = {

        problemColumns: [
            {label: "类型", prop: "type"},
            {label: "紧急程度", prop: "urgency"},
            {label: "描述", prop: "description"},

        ],
        problemList: [
            {
                "type": "土地合同",
                "urgency": "紧急",
              "urgencyType":"danger",
                "description": "土地合同未在规定日期的签订，延期作废"
            },
            {
                "type": "契税",
                "urgency": "中等",
              "urgencyType":"warning",
              "description": "10个工作日内完成税务缴纳"
            },
            {
                "type": "取消规划",
                "urgency": "一般",
              "urgencyType":"success",
              "description": "在施工前重新标注规划图纸"
            },
        ]
    };
  }

  componentWillUnmount()
  {
  }

  componentWillMount()
  {
  }

  componentDidMount()
  {
  }

  render()
  {
    return (
      <table className={"SimpleTable"}>
        <thead>
        <tr>
          <th>类型</th>
          <th>紧急程度</th>
          <th>描述</th>
        </tr>
        </thead>
        <tbody>
        {this.state.problemList.map(this.renderTr.bind(this))}
        </tbody>
      </table>);
  }

  renderTr (li){
    let {type,urgency,urgencyType,description} = li;
    return (
      <tr>
        <td>{type}</td>
        <td><Tag type={urgencyType}>{urgency}</Tag></td>
        <td>{description}</td>
      </tr>
    );
  }

}