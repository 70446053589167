
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

import {
  VictoryChart, VictoryGroup, VictoryStack, VictoryBar
  , VictoryPie, VictoryAnimation, VictoryLabel,Rect
} from "victory";
import {VCHART_THEME} from "../consts";

const {Row,Col} =Layout;
class ChartNcr extends Component
{
  constructor(props)
  {
    super(props)
    this.categoryList = ["桩机", "总包", "消防", "机电", "幕墙", "装修"];
    this.tuli = [
      {x:"桩机",color:VCHART_THEME.stack.colorScale[0]},
      {x:"总包",color:VCHART_THEME.stack.colorScale[1]},
      {x:"消防",color:VCHART_THEME.stack.colorScale[2]},
      {x:"机电",color:VCHART_THEME.stack.colorScale[3]},
      {x:"幕墙",color:VCHART_THEME.stack.colorScale[4]},
      {x:"装修",color:VCHART_THEME.stack.colorScale[5]},
    ];
    this.rawDataList = [{done: 3, total: 5}
      , {done: 7, total: 9}
      , {done: 3, total: 4}
      , {done: 2, total: 2}
      , {done: 1, total: 3}
      , {done: 2, total: 3}
    ];
    this.dataIndex = 0;
    this.state = this.switchData(this.rawDataList[0],this.dataIndex);
  }

  componentDidMount()
  {
    this.setStateInterval = window.setInterval(this.switchNextData.bind(this), 2000);
  }

  componentWillUnmount()
  {
    window.clearInterval(this.setStateInterval);
  }

  switchNextData(){

    if ( ++this.dataIndex > this.rawDataList.length - 1 )
    {
      this.dataIndex = 0;
    }
    let newStates = this.switchData(this.rawDataList[this.dataIndex],this.dataIndex);
    this.setState ({...this.state ,...newStates});

  }
  switchData ( li ,index)
  {
    this.dataIndex = index;
    let percent = Math.round(li.done / li.total * 100);
    li.left = li.total - li.done;
    li.color = VCHART_THEME.stack.colorScale[index];
    return {li ,percent, data : this.getData(percent) ,label: this.categoryList[this.dataIndex]+`${percent}%`};
  }
  getData(percent)
  {
    return [{x: 1, y: percent}, {x: 2, y: 100 - percent}];
  }

  render()
  {
    let centerLabel = this.categoryList[this.dataIndex];
    let centerColor = this.tuli[this.dataIndex].color;
    let {li} = this.state;
    return (
      <div className={"chart"}>
        {/*<VictoryChart domainPadding={{x: 50}} width={400} height={400}>*/}
        <svg viewBox="0 0 400 204" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate={{duration: 400}}
            width={210} height={210}
            data={this.state.data}
            innerRadius={80}
            cornerRadius={0}
            origin={{x: 400 / 2, y: (204-22) / 2}}
            labels={() => null}
            style={{
              data: {
                fill: ({datum}) => {
                  const color = datum.y > 30 ? li.color : "red";
                  return datum.x === 1 ? color : "black";
                }
              }
            }}
          />

          <VictoryLabel
            textAnchor="middle" verticalAnchor="middle"
            x={200} y={105}
            text={centerLabel}
            style={{fontSize: 12,fill:centerColor}}
          />
          <VictoryAnimation duration={500} data={this.state}>
            {(newProps) => {
              return (
                <VictoryLabel
                  textAnchor="middle" verticalAnchor="middle"
                  x={200} y={85}
                  text={`${Math.round(newProps.percent)}%`}
                  style={{fontSize: 20,fill:centerColor}}
                />
              );
            }}
          </VictoryAnimation>

          <Rect x={340} y={0} width={60} height = {54} fill={"#ECEFF1"} />
          <VictoryLabel
            textAnchor="left" verticalAnchor="middle"
            x={350} y={12}
            text={"已关闭:"+li.done}
            style={{fontSize:12}}
          /><VictoryLabel
            textAnchor="left" verticalAnchor="middle"
            x={350} y={26}
            text={"累计:"+li.total}
            style={{fontSize:12}}
          /><VictoryLabel
            textAnchor="left" verticalAnchor="middle"
            x={350} y={40}
            text={"剩余:"+li.left}
            style={{fontSize:12}}
          />


          {this.tuli.map(this.renderTuli.bind(this,400,204))}

        </svg>
        {/*</VictoryChart>*/}
      </div>
    );
  }

  renderTuli(svgW,svgH, li, index,arr)
  {
    let y = svgH - 22;
    let y2 = svgH - 18;
    let liW = 50;
    let x = (svgW-arr.length* liW)/2 + index * liW;
    let x2 = x+12;
    return [
      <g key={"tuli" + index}>
        <rect x={x} y={y} width="8" height="8" fill={li.color}/>
      </g>
      ,
      <VictoryLabel
        key={"tuliTf" + index}
        x={x2}
        y={y2}
        text={li.x}
        style={{fontSize: 10}}
      />];

  }



}

const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(ChartNcr);