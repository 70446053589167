import {SET_ADMIN_LIST} from "../actions";

export default function admin( state={adminList:[]} ,action)
{
  switch(action.type)
  {
    case SET_ADMIN_LIST:
      return {adminList : action.list};
  }
  return state;
}