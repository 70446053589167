import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Tabs} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";

const {Row, Col} = Layout;

class DailyTabBar extends Component
{
  static propTypes = {
    dailyId: PropTypes.number.isRequired,
    activeIndex : PropTypes.number.isRequired
  }
  constructor(props)
  {
    super(props)
    this.state = {};
  }

  componentDidMount()
  {
  }

  render()
  {
    let { activeIndex} = this.props;
    return (
      <div className="DailyTabBar" style={{backgroundColor: "#fff"}}>

        <Tabs activeName={activeIndex.toString()} onTabClick={this.onTabClick.bind(this)}>
          <Tabs.Pane label="基本信息" name="1"></Tabs.Pane>
          <Tabs.Pane label="编辑日报" name="2"></Tabs.Pane>
        </Tabs>

      </div>);
  }

  onTabClick(tab){
    let type = tab.props.name;
    let { dailyId} = this.props;
    if ( type == 1)
    {
      Facade.customHistory.push("day-" + dailyId);
    }else{
      Facade.customHistory.push("daytable-" + dailyId);
    }
  }

}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return { }
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(DailyTabBar);