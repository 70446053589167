import {
  SET_USER_SEARCH_LIST
} from "../actions";

export default function users(state = {
  searchedListAll:[]
}, action) {
  switch (action.type)
  {
    case SET_USER_SEARCH_LIST:
      return {...state, searchedListAll: action.voArr.map(pollyfillUser)};

    default :return state;
  }
}

function pollyfillUser(li, i)
{
  return li;
}
