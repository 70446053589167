export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const SET_SEARCH_LIST = "SET_SEARCH_LIST";
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";
export const CUSTOMER_OPEN = "CUSTOMER_OPEN";
export const SET_CUSTOMER_FILTER = "SET_CUSTOMER_FILTER";
export const SET_WORK_FILTER = "SET_WORK_FILTER";
export const SET_WORK_NUM = "SET_WORK_NUM";
export const SET_ADMIN_LIST = "SET_ADMIN_LIST";
export const LOGIN_LOGIN = "LOGIN_LOGIN";
export const SET_COUNT_ALL = "SET_COUNT_ALL";
export const SET_COUNT_GROWTH = "SET_COUNT_GROWTH";
export const SET_COUNT_GROWTH_FILTER = "SET_COUNT_GROWTH_FILTER";
export const SET_COUNT_RANKTOP10 = "SET_COUNT_RANKTOP10";
export const SET_COUNT_RANKTOP10_FILTER = "SET_COUNT_RANKTOP10_FILTER";


export function setSearchList ( voArr )
{
  return {
    type : SET_SEARCH_LIST,
    voArr
  }
}

export function setCustomerList ( total,page , totalPage , voArr )
{
  return {
    type : SET_CUSTOMER_LIST,
    total,page,totalPage, voArr
  }
}
export function customerUpdate(vo)
{
  return {  type: UPDATE_CUSTOMER, vo  }
}
export function customerOpen(vo)
{
  return { type : CUSTOMER_OPEN, vo}
}

/*
- uid：负责人id
- label：跟进状态 (可选）          //0新客户 1未联系上 2需转化 3已转化 4无效客户
- keyCustomer：重点（可选）    //1为重点客户
- criticalCustomer：危重（可选）//1为为重客户
- page：页码，1开始
- limit：每页个数，默认20*/
export function setCustomerFilter( uid,label,keyCustomer,criticalCustomer,page )
{
  return {
    type : SET_CUSTOMER_FILTER,
    vo: {uid,label,keyCustomer,criticalCustomer,page }
  }
}
export function setWorkFilter( month , date,overdue , undistributed)
{
  return {
    type : SET_WORK_FILTER,
    vo: { month , date,overdue , undistributed}
  }
}
export function setWorkNum (   vo )
{
  return {type : SET_WORK_NUM ,  vo }
}



export function loginLogin(  id,nickName,avatarUrl){
  return {
    type : LOGIN_LOGIN,
    id,nickName,avatarUrl
  }
}

export function setAdminList(list)
{
  return {type:SET_ADMIN_LIST,list}
}

export function setCountAll(vo)
{
  return {type :SET_COUNT_ALL , vo}
}
export function setCountGrowth(initData,growthData)
{
  return {type : SET_COUNT_GROWTH , initData,growthData};
}
export function setCountGrowthFilter(view,uid)
{
  return {type : SET_COUNT_GROWTH_FILTER , view,uid};
}
export function setCountRankTop10(arr)
{
  return {type : SET_COUNT_RANKTOP10,arr};
}
export function setCountRankTop10Filter(direction,view)
{
  return {type : SET_COUNT_RANKTOP10_FILTER,direction,view};
}
/**************** 项目 ******************/
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export function setProjectList(voArr)
{
  return {
    type : SET_PROJECT_LIST,
    voArr
  }
}
export const SET_PROJECT_DETAIL = "SET_PROJECT_DETAIL";
// 从url进入后，指定进入的项目id
export const OPEN_PROJECT_ID = "OPEN_PROJECT_ID";
export function setProjectDetail(vo){
  return {
    type : SET_PROJECT_DETAIL,
    vo
  }
}
export function openProjectId(id){
  return {
    type : OPEN_PROJECT_ID,
    id
  }
}

export const SET_ARCHIVED_PROJECT_LIST = "SET_ARCHIVED_PROJECT_LIST";
export const SET_DELETED_PROJECT_LIST = "SET_DELETED_PROJECT_LIST";
export function setArchivedProjectList(voArr)
{
  return {
    type : SET_ARCHIVED_PROJECT_LIST,
    voArr
  }
}
export function setDeletedProjectList(voArr)
{
  return {
    type : SET_DELETED_PROJECT_LIST,
    voArr
  }
}

/**************** 周报列表 ******************/
//设置周报列表
export const SET_WEEK_REPORT_LIST = "SET_WEEK_REPORT_LIST";

export function setWeekReportList(list)
{
  return {type:SET_WEEK_REPORT_LIST,voArr:list}
}


//设置周报列表用户筛选 @deprecated
export const SET_WEEK_REPORT_LIST_FILTER = "SET_WEEK_REPORT_LIST_FILTER";

export function setWeekReportListFilter(month)
{
  return {type : SET_WEEK_REPORT_LIST_FILTER, vo :{month}};
}

//设置当前查看的周报详情
export const SET_WEEK_REPORT_DETAIL = "SET_WEEK_REPORT_DETAIL";
export function setWeekReportDetail(vo)
{
  return {type : SET_WEEK_REPORT_DETAIL,vo};
}

// 设置一组测试数据
export const SET_WEEK_REPORT_TEST_DATA = "SET_WEEK_REPORT_TEST_DATA";
export function setWeekReportTestData(list)
{
  return {type : SET_WEEK_REPORT_TEST_DATA,voArr:list};
}


export const SET_DELETED_WEEK_REPORT_LIST = "SET_DELETED_WEEK_REPORT_LIST";

export function setDeletedWeekReportList(total,list ,page,pageSize)
{
  return {type:SET_DELETED_WEEK_REPORT_LIST,voArr:list,total,page,pageSize}
}

//region 周报-任务列表
/**************** 周报-任务列表 ******************/
//设置周报任务列表
export const SET_WEEK_REPORT_TASK_LIST = "SET_WEEK_REPORT_TASK_LIST";
export function setWeekReportTaskList(list)
{
  return {type:SET_WEEK_REPORT_TASK_LIST,voArr:list}
}

//设置周报任务列表用户筛选
export const SET_WEEK_REPORT_TASK_LIST_FILTER = "SET_WEEK_REPORT_TASK_LIST_FILTER";
export function setWeekReportTaskListFilter(type)
{
  return {type : SET_WEEK_REPORT_TASK_LIST_FILTER, vo :{type}};
}

//设置当前查看的周报任务详情
export const SET_WEEK_REPORT_TASK_DETAIL = "SET_WEEK_REPORT_TASK_DETAIL";
export function setWeekReportTaskDetail(vo)
{
  return {type : SET_WEEK_REPORT_TASK_DETAIL,vo};
}
export const UPDATE_WEEK_REPORT_TASK_DETAIL = "UPDATE_WEEK_REPORT_TASK_DETAIL";
export function updateWeekReportTaskDetail(vo)
{
  return {type : UPDATE_WEEK_REPORT_TASK_DETAIL,vo};
}
export const SET_WEEK_REPORT_TYPENUM="SET_WEEK_REPORT_TYPENUM";
export function setWeekReportTypeNum(vo)
{
  return {type:SET_WEEK_REPORT_TYPENUM,vo};
}
//endregion

/**************** 近期成果 ******************/
export const SET_RECENT_RESULT_LIST = "SET_RECENT_RESULT_LIST";
export function setRecentResultList(type,list)
{
  return {type:SET_RECENT_RESULT_LIST,listType : type ,voArr:list}
}


//region 全局
/**************** 全局 ******************/
/** 全局-加载信息 */
export const SET_LOADING = "SET_LOADING";
export function setLoading(name , value)
{
  return {type : SET_LOADING,key:name,value};
}
/** 全局-更改视图设定 */
export  const SET_VIEW_PREF="SET_VIEW_PREF";
export  function setViewPref(name,value)
{
  return {type : SET_VIEW_PREF,key:name,value};
}
//endregion

/**************** 日报 ******************/
//region 日报
// 日报直接放在日报reducer里了
//endregion

/**************** 用户搜索 ******************/
//region 用户搜索
export  const SET_USER_SEARCH_LIST="SET_USER_SEARCH_LIST";
export function setUserSearchList(voArr)
{
  return {type : SET_USER_SEARCH_LIST , voArr };
}
//endregion

/**************** 项目成员 ******************/
//region 项目成员
export const SET_PROJECT_MEMBERS = "SET_PROJECT_MEMBERS";
export function setProjectMembers(projectUser  ,dailyUserList , memberUserList)
{
  return {type : SET_PROJECT_MEMBERS, projectUser  ,dailyUserList , memberUserList};
}
export const RESET_PROJECT_MEMBERS="RESET_PROJECT_MEMBERS";
export function resetProjectMemebrs(){
  return {type: RESET_PROJECT_MEMBERS}
}
//endregion