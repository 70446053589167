import {
  openProjectId, setLoading,
  setProjectList,
  setWeekReportDetail,
  setWeekReportTaskFold, setWeekReportTaskList,
  setWeekReportTaskListFilter, setWeekReportTypeNum
} from "../actions";
import Facade from "../Facade";

/** 加载周报下的所有周报任务
 * @param type `<Tabs.Pane label="我的任务" name="1"></Tabs.Pane><Tabs.Pane label="全部任务" name="2"></Tabs.Pane>`
 * */
export default function runRefreshTasks(weeklyId, type,dispatch){

  dispatch(setLoading( 'taskList',true ));

  Facade.gw.getProjectTasks(weeklyId, type ,( {task,typeNum,weekly})=>{
    //weekly就是周报详情，这里不处理；之后看要不要合并过来
    //typeNum是这些任务的各种情况的数量；
    dispatch ( setWeekReportTypeNum(typeNum));
    //task就是之前的列表
    dispatch(setWeekReportTaskListFilter(type));
    dispatch(setWeekReportTaskList(task));
    dispatch(setLoading( 'taskList',false ));

  });


}
