export default class MBScrollViewHelper{

  //todo 优化为合并一个计算
  constructor(viewRef,callback)
  {
    this.callback = callback;
    this.ref = viewRef;
    this.onScrollHandler = this.onWinScroll.bind(this);
    this.itv = setInterval(this.onScrollHandler.bind(this),500);
    // window.addEventListener('scroll', this.onScrollHandler, false);

  }

  dispose(){
    // window.removeEventListener('scroll', this.onScrollHandler, false);
    clearInterval(this.itv);
  }


  onWinScroll(){

    if (this.ref) {
      var seeHeight = document.documentElement.clientHeight;
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let offsetPageTop = this.getElementTop(this.ref);
      if ( offsetPageTop< seeHeight + scrollTop )
      {
        this.callback();
        console.log("ChartBars1/onWinScroll hit me",offsetPageTop,seeHeight + scrollTop);
      }
    }
  }
  getElementTop(element){
    var actualTop = element.offsetTop;
    var current = element.offsetParent;
    while (current !== null){
      actualTop += current. offsetTop;
      current = current.offsetParent;
    }
    return actualTop;
  }


}