/** 日报内页数据reducer
 *
 * 日报-基础信息
 * 日报-天气
 * 日报-人员
 * 日报-设备
 * 日报-安全
 * 日报施工类型
 * 日报-施工内容
 * */


/**************** 日报详情页 ******************/
//region 日报详情页
export  default function dailyReportDetails(state = {
  basic:{},
  weather:{},
  personList:[], personSum:0,
  equipmentList:[],
  constructionContent:[],
  safety:"",
  constructionTypeList : []
}, action) {
  switch (action.type)
  {
    case SET_DAILY_REPORT_BASIC:
      return {...state, basic: action.vo };
    case SET_DAILY_REPORT_WEATHER:
      return {...state, weather: action.vo };
    case SET_DAILY_REPORT_PERSONLIST:
      let personSum=0;
      for (const voElement of action.list)
      {
        personSum+= parseInt(voElement.personNum);
      }
      return {...state, personList: action.list,personSum};
    case SET_DAILY_REPORT_EQUIPMENTLIST:
      return {...state, equipmentList: action.list};
    case ADD_DAILY_REPORT_EQUIPMENT:
      return {...state, equipmentList: state.equipmentList.concat(action.vo)};

    case SET_DAILY_REPORT_CONSTRUCTIONLIST:
      return {...state, constructionContent: action.list};
    case SET_DAILY_CONSTRUCTION_TYPE_LIST:
      return {...state , constructionTypeList: action.list}
    case ADD_DAILY_CONSTRUCTION:
      return {...state, constructionContent: state.constructionContent.concat(action.vo)};

    case SET_DAILY_REPORT_SAFETY:
      return {...state, safety: action.str };
    case RESET_DAILY_DETAIL:
      console.log("default/dailyReportDetails");
      return {...state,
        basic:{},
        weather:{},
        personList:[], personSum:0,
        equipmentList:[],
        constructionContent:[],
        safety:"",
        constructionTypeList : []
      };
    default :return state;
  }
}
//endregion
export const RESET_DAILY_DETAIL="RESET_DAILY_DETAIL";
export function resetDailyDetail(){
  return {type:RESET_DAILY_DETAIL};
}

export const SET_DAILY_REPORT_BASIC = "SET_DAILY_REPORT_BASIC";
export function setDailyBasic(vo)
{
  return {type:SET_DAILY_REPORT_BASIC,vo}
}
export const SET_DAILY_REPORT_WEATHER = "SET_DAILY_REPORT_WEATHER";
export function setDailyWeather(vo)
{
  return {type:SET_DAILY_REPORT_WEATHER,vo}
}
export const SET_DAILY_REPORT_PERSONLIST = "SET_DAILY_REPORT_PERSONLIST";
export function setDailyPersonList(list)
{
  return {type:SET_DAILY_REPORT_PERSONLIST,list}
}
export const SET_DAILY_REPORT_EQUIPMENTLIST = "SET_DAILY_REPORT_EQUIPMENTLIST";
export function setDailyEquipmentList(list)
{
  return {type:SET_DAILY_REPORT_EQUIPMENTLIST,list}
}
//客户端增量追加一个equipment
export const ADD_DAILY_REPORT_EQUIPMENT = "ADD_DAILY_REPORT_EQUIPMENT";
export function addDailyEquipment(vo)
{
  return {type:ADD_DAILY_REPORT_EQUIPMENT,vo}
}
export const SET_DAILY_REPORT_CONSTRUCTIONLIST = "SET_DAILY_REPORT_CONSTRUCTIONLIST";
export function setDailyConstructionList(list)
{
  return {type:SET_DAILY_REPORT_CONSTRUCTIONLIST,list}
}
export const SET_DAILY_REPORT_SAFETY = "SET_DAILY_REPORT_SAFETY";
export function setDailySafety(str)
{
  return {type:SET_DAILY_REPORT_SAFETY,str}
}

export const SET_DAILY_CONSTRUCTION_TYPE_LIST = "SET_DAILY_CONSTRUCTION_TYPE_LIST";
export function setDailyConstructionTypeList(list)
{
  return {type:SET_DAILY_CONSTRUCTION_TYPE_LIST,list}
}
/** 增量加入施工内容 */
export const ADD_DAILY_CONSTRUCTION = "ADD_DAILY_CONSTRUCTION";
export function addDailyConstruction(vo)
{
  return {type:ADD_DAILY_CONSTRUCTION,vo}
}