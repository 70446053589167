
import React, {Component} from 'react';
import {
  Button,
  Layout,
  Input,
  Radio,
  Switch,
  Pagination,
  Loading,
  Dialog,
  Table,
  Checkbox,
  Notification,
  DatePicker,
  Tabs,
  Icon, Message, Badge, Select, Tag, Card
} from "element-react";
import 'element-theme-default';
import {
  customerOpen,
  loginRecovery,
  setAdminList,
  setWeekReportDetail,
  setWeekReportListFilter, setWeekReportTaskDetail,
  setWeekReportTaskFold, setWeekReportTaskList,
  setWeekReportTaskListFilter,
  setWeekReportTestData
} from "../actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Facade from "../Facade";
import TimeUtil from "../TimeUtil";
import Utils from "../Utils";
import QuickTimeTf from "../components/QuickTimeTf";
import {WEB_URL, WORK_DATE_NATURE} from "../consts";
import PageWrapper from "./PageWrapper";
import "./WeekGraphPage.css";
import UserSelect from "../components/UserSelect";
import runRefreshWeekly from "../controllers/runRefreshWeekly";
import WeekTasksTabBar from "../components/WeekTasksTabBar";
import WeekTasksTable from "../components/WeekTasksTable";
import runRefreshTasks from "../controllers/runRefreshTasks";
import RecentResultsUI from "../components/RecentResultsUI";
import runRefreshRecentResults from "../controllers/runRefreshRecentResults";
import ChartRfi from "../components/ChartRfi";
import ChartAqrl from "../components/ChartAqrl";
import ChartNcr from "../components/ChartNcr";
import ChartRisk from "../components/ChartRisk";
import WeekGraphCard from "../components/WeekGraphCard";
import BodyFooter from "../components/BodyFooter";


const {Row,Col} =Layout;
class WeekGraphPage extends Component
{

  constructor(props)
  {
    super(props);
    console.log("WeekGraphPage/constructor");
    this.state={
    };
  }




  componentWillReceiveProps(nextProps, nextContext)
  {
    let weeklyId = parseInt(this.props.match.params.weeklyId);
    if ( weeklyId!= this.weeklyId )
    {
      this.weeklyId = weeklyId;
      runRefreshWeekly(this.weeklyId , this.props.dispatch);
      runRefreshTasks(this.weeklyId ,0, this.props.dispatch);
      runRefreshRecentResults( this.weeklyId,this.props.dispatch);
    }
  }

  componentDidMount()
  {

    this.weeklyId = parseInt(this.props.match.params.weeklyId);
    runRefreshWeekly(this.weeklyId , this.props.dispatch);
    runRefreshTasks(this.weeklyId ,1, this.props.dispatch);
    runRefreshRecentResults( this.weeklyId,this.props.dispatch);

    // 图

    var datasrc = {"title":"","defaultZoomBeginDate":"2022-03-08","defaultZoomEndDate":"2029-05-08",
      "dataset":[{"id":"3.0","name":"采购","start":"2021-07-21","end":"2022-05-15","baseline_start":"2021-07-21","baseline_end":"2022-05-15"},{"id":"3.1","name":"精装设计招标","start":"2021-07-21","end":"2021-08-06","baseline_start":"2021-07-21","baseline_end":"2021-08-06"},{"id":"3.2","name":"RFP-合同签署","start":"2022-01-28","end":"2022-05-15","baseline_start":"2022-01-16","baseline_end":"2022-05-15"},{"id":"3.3","name":"中标通知书发出","start":"2022-04-15","end":"2022-04-29","baseline_start":"2022-04-15","baseline_end":"2022-04-15"},{"id":"3.4","name":"精装合同签署","start":"2022-04-29","end":"2022-05-15","baseline_start":"2022-04-15","baseline_end":"2022-05-15"}]};
    window.initScheduleCanvas(datasrc,"chart_schedule_container",1920,800);

  }
  componentWillUnmount()
  {
    window.disposeScheduleCanvas();
  }



  onFiltView(value){
    if ( value =="表格视图")
    {
      Facade.customHistory.push(`/week-${this.weeklyId}`);
    }
  }


  render(){
    let {dispatch, weekReportDetail,projectDetail} = this.props;
    //创建筛选器
    return (
      <PageWrapper>
        <div className="WeekGraphPage pageWithPadding">

          <div className="pageCard">
            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                  {projectDetail? projectDetail.name:""} 周报 {weekReportDetail?weekReportDetail.dateBucket:""}
                </div>
              </Col>
              <Col span={12}>
                <div className="tableTitleRight">
                  <Radio.Group value={"周报视图"} onChange={this.onFiltView.bind(this)}>
                    <Radio.Button value="表格视图"/>
                    <Radio.Button value="周报视图"/>
                  </Radio.Group>

                </div>
              </Col>
            </Row>

            <Row gutter={10} justify={"space-around"} style={{marginBottom:"1em"}}>

              <Col xs="12" sm="12" md="12" lg="6">
                <div className={"chartCard"}>
                  <div className="chartCardHead">
                    <img src={require("../res/0818/1.png")}/>
                    成本RFI</div>
                  <div className="chartCardBody">
                    <ChartRfi/>
                  </div>
                </div>

              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <div className={"chartCard"}>
                  <div className="chartCardHead">
                    <img src={require("../res/0818/2.png")}/>
                    安全/人力</div>
                  <div className="chartCardBody">
                  <ChartAqrl/>
                  </div>
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <div className={"chartCard"}>
                  <div className="chartCardHead">
                    <img src={require("../res/0818/3.png")}/>
                    质量NCR</div>
                  <div className="chartCardBody">
                    <ChartNcr/>
                  </div>
                </div>

              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <div className={"chartCard"}>
                  <div className="chartCardHead">
                    <img src={require("../res/0818/4.png")}/>
                    <div>风险<span style={{fontSize:"12px"}}>(意外和/或环境)</span></div>
                  </div>
                  <div className="chartCardBody overflowscroll">
                    <ChartRisk/>
                  </div>
                </div>

              </Col>
            </Row>

            <Row className="graphcards" gutter={10}>
              <WeekGraphCard name={"设计"} icon={require("../res/0818/Frame 283.png")} iconname={"fa fa-server"} val={848} delay={-16} />
              <WeekGraphCard name={"采购"} icon={require("../res/0818/Frame 283-1.png")} iconname={"fa fa-truck"} val={848} delay={16} />
              <WeekGraphCard name={"报建报批"} icon={require("../res/0818/Frame 283-2.png")} iconname={"fa fa-ticket"} val={516} delay={0} />
              <WeekGraphCard name={"施工"} icon={require("../res/0818/Frame 283-3.png")} iconname={"fa fa-road"} val={516} delay={0} />
              <WeekGraphCard name={"项目验收"} icon={require("../res/0818/Frame 283-4.png")} iconname={"fa fa-users"} val={516} delay={0} />
              <WeekGraphCard name={"移交入住"} icon={require("../res/0818/Frame 283-5.png")} iconname={"fa fa-suitcase"} val={516} delay={16} />
            </Row>


            <Row className="schedulechart" >

              <div className="chartCardHead">
                <img src={require("../res/0810/d1.png")}/>
                计划图表
              </div>
            </Row>
            <Row className="schedulechart">
              <div id="chart_schedule_container">
              </div>
            </Row>


            {/*近期成果*/}
            {this.weeklyId && <RecentResultsUI weeklyId={this.weeklyId} editable={true} />}


            {this.weeklyId && <WeekTasksTabBar weeklyId={this.weeklyId} />}


            <Row >
              {this.weeklyId && <WeekTasksTable weeklyId={this.weeklyId} editable={false}/>}
            </Row>


          </div>

          <Row className="pageFooter">
            <Col span={24} style={{textAlign:"center"}}>
              <Button type="primary" onClick={()=>{
                Utils.copyTXT(`${WEB_URL}/shared-${this.weeklyId}`);
                Message.success("链接已复制到剪贴板");
              }}>分享-复制链接</Button>
            </Col>
          </Row>



          <Row>
            <BodyFooter/>
          </Row>

        </div>
      </PageWrapper>
        );
  }



  static propTypes = {
  }

}


const mapStateToProps=(state)=>
{
  return {
    projectDetail: state.projects.projectDetail,
    weekReportDetail: state.weekReports.weekReportDetail

  };
};
export default connect(
  mapStateToProps
)(WeekGraphPage);