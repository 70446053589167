
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, DatePicker, Loading, Table, Radio, Pagination, Message} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import runRefreshDeletedProjectList from "../controllers/runRefreshDeletedProjectList";
import PageWrapper from "./PageWrapper";
import runRefreshDeletedWeeklyList from "../controllers/runRefreshDeletedWeeklyList";
import runRefreshProjectList from "../controllers/runRefreshProjectList";

const {Row,Col} =Layout;

const pageSize=10;

class TrashPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state={
      view :"项目",
      weekColumns: [
        {label: "周报", prop: "dateBucket"},
        {label: "所属项目", prop: "projectName"},
        {label: "",  width:80, render: this.renderActions.bind(this)}
      ],
      projectColumns: [
        {label: "项目回收站", prop: "name"},
        {label: "",  width:80, render: this.renderActions.bind(this)}
      ]
    }
  }

  renderActions(row){
    return (
      <span>
        <Button type="text" size="small" onClick={this.onClickRestore.bind(this,row)} > 恢复</Button>
      </span>
    )
  }
  onClickRestore(li)
  {
    let {view} = this.state;
    if ( view =="项目")
    {
      Facade.gw.restoreProject(li.id ,()=>{
        runRefreshDeletedProjectList(this.props.dispatch);
        runRefreshProjectList(this.props.dispatch);
        Message.success("项目已恢复");
      })
    }else if ( view == "周报")
    {
      Facade.gw.restoreWeekly(li.id,()=>{
        runRefreshDeletedWeeklyList(this.props.deletedWeeklyPage,pageSize,this.props.dispatch)
        Message.success("周报已恢复");
      });
    }
  }

  componentDidMount()
  {
    runRefreshDeletedProjectList(this.props.dispatch);
  }
  onFiltView(value){
    if ( value =="项目")
    {
      runRefreshDeletedProjectList(this.props.dispatch);
    }else if ( value == "周报")
    {
      runRefreshDeletedWeeklyList(1,pageSize,this.props.dispatch);
    }
    this.setState({view :value});
  }

  render(){

    let {deletedWeeklyList,deletedProjectList,deletedWeeklyTotal,deletedWeeklyPage } = this.props;
    let {view} = this.state;
    const isZhoubao=view=="周报";
    return (
      <PageWrapper activeNav="9-trashcan">
        <div className="TrashPage pageWithPadding">
          <div className="pageCard">
            <Row className="tableTitleRow">
              <Col span={12}>
                <div className="tableTitle">
                </div>
              </Col>
              <Col span={12}>
                <div className="tableTitleRight">
                  <Radio.Group value={view} onChange={this.onFiltView.bind(this)}>
                    <Radio.Button value="项目"/>
                    <Radio.Button value="周报"/>
                  </Radio.Group>

                </div>
              </Col>
            </Row>
            <Row>
                <Table
                  border={false}
                  style={{width: '100%', marginBottom: '20px'}}
                  columns={isZhoubao ? this.state.weekColumns : this.state.projectColumns}
                  onCellClick={(row, column, cell, event) => {
                    if (column.swallow)
                    {
                      if (event) event.stopPropagation();
                    }
                  }}
                  data={isZhoubao ?deletedWeeklyList : deletedProjectList}
                  stripe={false}
                  highlightCurrentRow={true}
                />
            </Row>
            {isZhoubao && <Row justify={"center"} type={"flex"}>
              <Pagination layout="prev, pager,next, jumper, ->,total" total={deletedWeeklyTotal} pageSize={pageSize} currentPage={deletedWeeklyPage} onCurrentChange={(p)=>{
                runRefreshDeletedWeeklyList( p,pageSize,this.props.dispatch);
              }}/>
            </Row>}



          </div>
        </div>
      </PageWrapper>);
  }

}
const mapStateToProps=(state)=>
{
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    deletedProjectList: state.projects.deletedProjectList
    ,deletedWeeklyList : state.weekReports.deletedWeeklyList
    ,deletedWeeklyTotal : state.weekReports.total
    ,deletedWeeklyPage : state.weekReports.page
  };
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(TrashPage);