import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Dropdown,Loading,Cascader} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, openProjectId, setAdminList} from "../actions";
import Facade from "../Facade";
import WeekTasksTable from "../components/WeekTasksTable";
import DailyTabBar from "../components/DailyTabBar";
import runRefreshTasks from "../controllers/runRefreshTasks";
import runRefreshWeekly from "../controllers/runRefreshWeekly";
import PageWrapper from "./PageWrapper";
import "./DailyPage.css";
import {setDailyBasic} from "../reducers/dailyReportDetails";
import CityAddrSelector from "../components/CityAddrSelector";

const {Row, Col} = Layout;

class DailyBasicPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {dailyId: 0,isDailyDetailFullLoading:true, form : {},modified:false};
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    let dailyId = parseInt(nextProps.match.params.dailyId);
    let form = prevState.form;
    let {dailyBasic}=nextProps;
    if ( dailyBasic && dailyBasic.id==dailyId)
    {
      //id变化时全部覆盖；id未变化时仅合并
      if ( !form.organization) form.organization= dailyBasic.organization;
      if ( !form.projectName) form.projectName= dailyBasic.projectName;
      if ( !form.projectNumber) form.projectNumber= dailyBasic.projectNumber;
      if ( !form.projectAddress) form.projectAddress= dailyBasic.projectAddress;
      if ( !form.reportNumber) form.reportNumber= dailyBasic.reportNumber;
    }

    return {
      dailyId,      form,
    }
  }


  componentDidMount()
  {
    this.refreshByDailyId(this.state.dailyId);
  }

  refreshByDailyId(dailyId)
  {
    let {dispatch} = this.props;
    this.setState({isDailyDetailFullLoading:true});
    Facade.gw.getDailyBasicInfo(dailyId,(vo)=>{
      dispatch(setDailyBasic(vo));
      dispatch(openProjectId(vo.projectId));
    });

    this.setState({isDailyDetailFullLoading:false});
  }

  render()
  {
    let {dailyId,isDailyDetailFullLoading,form,modified,addressOptions} = this.state;
    let {dispatch,dailyBasic} = this.props;
    return (
      <PageWrapper>
        <div className="DailyPage pageWithPadding">

            <Loading loading={isDailyDetailFullLoading}  >
          <div className="pageCard">

            <Row className="tableTitleRow">
              <Col span={24}>
                <div className="tableTitle">
                  {dailyBasic.projectName} 施工日报 {dailyBasic.date}
                </div>
              </Col>
            </Row>


            <div className="cardWithPadding">
              {dailyId && <DailyTabBar dailyId={dailyId} activeIndex={1}/>}
            </div>

            <div className={"logo"}>
              <img src={require("../res/1026/日报/logo.png")}/>
            </div>

            <div className="rbTableWrap">
              <table className={"rbTable"}>
                <tr>
                  <td className="sectionHead first" colSpan={2}>1-项目基本情况</td>
                </tr>
                <tr>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>建设单位：</div>
                      <input type={"text"} className={"val"} value={form.organization} onChange={this.onChange.bind(this, "organization")}/>
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>项目名称：</div>
                      <input type={"text"} className={"val"} value={form.projectName} onChange={this.onChange.bind(this, "projectName")}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>项目编号：</div>
                      <input type={"text"} className={"val"} value={form.projectNumber} onChange={this.onChange.bind(this, "projectNumber")}/>
                    </div>
                  </td>
                  <td>
                    <div className="inputTfRow">
                      <div className={"label"}>地址：</div>
                      <CityAddrSelector value={form.projectAddress} onChange={this.onChange.bind(this, "projectAddress")}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="inputTfRow">
                      <div className={"label"}>报告编号：</div>
                      <input type={"text"} className={"val"} value={form.reportNumber} onChange={this.onChange.bind(this, "reportNumber")}/>
                    </div>
                  </td>
                </tr>

              </table>
            </div>

            <div className={"btnsRow"}>

              <div className={"saveBtn "+(modified?"":"disabledBtn")} onClick={modified?this.onSave.bind(this):null}>
                保存
              </div>

            </div>


          </div>
            </Loading>

        </div>
      </PageWrapper>);
  }


  onChange(key ,evt)
  {
    this.state.form[key] = evt.target.value;
    // console.log("DailyBasicPage/onChange",this.state.form);
    // this.forceUpdate();
    this.setState({modified:true});
  }

  onSave(){
    //合并本地改过的数据
    let {dispatch ,dailyBasic} = this.props;
    let {dailyId} = this.state;
    let {organization,projectName,projectNumber,projectAddress,reportNumber,}=this.state.form;
    if ( !organization) organization= dailyBasic.organization;
    if ( !projectName) projectName= dailyBasic.projectName;
    if ( !projectNumber) projectNumber= dailyBasic.projectNumber;
    if ( !projectAddress) projectAddress= dailyBasic.projectAddress;
    if ( !reportNumber) reportNumber= dailyBasic.reportNumber;
    this.setState({isDailyDetailFullLoading:true,modified:false});

    Facade.gw.dailyPostBasicInformation(dailyId,organization,projectName,projectNumber,projectAddress,reportNumber,()=>{
      this.setState({isDailyDetailFullLoading:false});
this.refreshByDailyId(dailyId);
    });
  }

}



const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {dailyBasic: state.dailyReportDetails.basic};
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(DailyBasicPage);