import React, {Component} from 'react';
import {EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";

export default class RtfEditor extends Component
{
  static propTypes = {
    onEditorBlur : PropTypes.func,
    onEditorChanged : PropTypes.func,
    initialContent : PropTypes.string,
  }

  constructor(props)
  {
    super(props);
    let editorState = EditorState.createEmpty();
    if (props.initialContent)
    {
      editorState = this.recoveryFromHtml(props.initialContent);
    }

    this.state = {
      editorState
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState)
  // {
  // }


  onEditorStateChange(editorState)
  {
    this.setState({
      editorState,
    });
    if(this.props.onEditorChanged!=null) this.props.onEditorChanged();
  };

  componentDidMount()
  {

  }

  render()
  {
    const {editorState} = this.state;
    return (

      <Editor
        editorState={editorState}
        toolbarClassName="rtfToolbar"
        wrapperClassName="rtfWrapper"
        editorClassName="rtfEditor"
        onEditorStateChange={this.onEditorStateChange.bind(this)}
        onBlur={this.onEditorBlur.bind(this)}
      />
    );
  }

  onEditorBlur(s)
  {
    // console.log("RtfEditor/onEditorBlur");
    if(this.props.onEditorBlur!=null) this.props.onEditorBlur();
  }

  exportHtml(){
    let exportHtml = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
    return exportHtml;
  }
  exportJson(){
    let exportJsonStr = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
    return exportJsonStr;
  }

  /**************** 恢复数据 ******************/
  //region 恢复数据

  recoveryData(jsonStr)
  {
    const contentState = convertFromRaw(JSON.parse(jsonStr));
    return EditorState.createWithContent(contentState);
  }

  recoveryFromHtml(htmlStr)
  {

    const blocksFromHTML = convertFromHTML(htmlStr);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );


    return EditorState.createWithContent(state);
  }

  //endregion

}

