
import React, {Component} from 'react';
import {Cascader} from "element-react";
import PropTypes from "prop-types";


export default class CityAddrSelector extends Component
{
  static propTypes ={
    onChange:PropTypes.func,
    value : PropTypes.string
  }

  constructor(props) {
    super(props);
    let addressOptions=require("./citydata.json");

    this.state = {
      addressOptions,
      selectedOptions: [],
      value:''
    }
  }

  componentWillReceiveProps(nextProps, nextContext)
  {
    // 根据string
    if ( this.state.value != nextProps.value )
    {
      //value变化 ，则重新计算
      // 凡推算出selectedOptions
      var selectedOptions = [];
      this.state.addressOptions.forEach(this.dealOptions.bind (this,nextProps.value,selectedOptions ))
      this.setState({value : nextProps.value ,selectedOptions});
    }

  }

  // 已知label拼接的字符串，递归反推算数组值
  dealOptions(expectValueStr,matchResult,li,index)
  {
    if ( expectValueStr && expectValueStr.length>=li.label.length && li.label== expectValueStr.substr(0,li.label.length))
    {
      //找到一部分
      matchResult.push(li.value);
      if ( li.children)
      {
        //继续往下找
        li.children.forEach(this.dealOptions.bind(this,expectValueStr.substr(li.label.length),matchResult));
      }
    }
  }

  // 已知value数组，拼接字符串
  optionsValToStr(arrValue,lv,li,index)
  {
    if ( li.value==arrValue[lv] )
    {
      this.joinedStr += li.label;
      if ( arrValue.length>lv && li.children)
      {
        li.children.forEach(this.optionsValToStr.bind(this,arrValue,lv+1));
      }
    }
  }

  joinedStr="";
  handleChange(key, arrValue) {
    this.joinedStr="";
    this.state.addressOptions.forEach(this.optionsValToStr.bind(this,arrValue,0));
    let value = this.joinedStr;
    this.setState({ [key]: arrValue ,value });

    console.log(arrValue,value);
    this.props.onChange({target:{value}});
  }

  render() {
    return (
          <Cascader
            options={this.state.addressOptions}
            expandTrigger="hover"
            value={this.state.selectedOptions}
            onChange={this.handleChange.bind(this, 'selectedOptions')} />
    )
  }

}