import Facade from "./Facade";
import {Message} from "element-react";

// 请求用到的参数
var Bucket = 'assertcenter-10019656';
var Region = 'ap-shanghai';
var protocol = "https:";
var prefix = protocol + '//' + Bucket + '.cos.' + Region + '.myqcloud.com/';

var cdnReadPrefix = protocol + '//' + Bucket + ".image.myqcloud.com/";

var COS = require('cos-js-sdk-v5');
var cos = new COS({
  // getAuthorization 必选参数
  getAuthorization: function (options, callback) {
    // 异步获取临时密钥
    // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
    // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
    // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
    Facade.gw.getStsAuth((data)=>{
      var credentials = data.credentials;
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        SecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
      })
    })

  }
});


export default class CosHelper
{

  static getCos()
  {
    return cos;
  }


  static genFileKey (file){
    return "/bdbim/ugc/"+((new Date()).valueOf())+""+file.name;
// let localFile = file.name;
//     let indexOf=localFile.lastIndexOf("/");
//     let baseFilename = localFile.substr(indexOf+1);
//     let asFile = this._batchUploadAsFolder + "/" + file.name;

  }
  static upload(file,succCb,progressCb,errCb)
  {
    // console.log("CosHelper/upload",cos);
    // return;
    let key = this.genFileKey(file);
    let webUrl = cdnReadPrefix +key;
    cos.uploadFile({
      Bucket: Bucket, /* 填写自己的bucket，必须字段 */
      Region: Region,     /* 存储桶所在地域，必须字段 */
      Key: key,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
      Body: file, // 上传文件对象
      SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
      onProgress: function (progressData) {
        // console.log(JSON.stringify(progressData));
        let e={percent : progressData.percent,loaded:progressData.loaded,total : progressData.total};
        progressCb(e);
      }
    }, function (err, data) {
      if (err)
      {
        Facade.gw.gwAlert("上传失败"+err.toString());
        console.warn('上传失败', err);
        errCb(err);
      } else
      {
        console.log('上传成功');
        Message.success("上传成功");
        succCb(webUrl);
      }
    });
  }
}