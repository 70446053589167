
export default class Utils
{

  static copyTXT2(txt)
  {
    var copyDOM = document.querySelector('#copy-txt'); //指定的DOM元素
    copyDOM.innerHTML=txt;
    copyDOM.style.display='none';
    var range = document.createRange(); // 创建容器
    range.selectNode(copyDOM); // 选中需要复制的节点
    window.getSelection().addRange(range); // 执行选中元素
    var successful = document.execCommand('Copy');// 执行 copy 操作
    try
    {
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copy was ' + msg);
    } catch (err)
    {
      console.log('unable to copy');
    }
    window.getSelection().removeAllRanges(); // 移除选中的元素
  }

  static copyTXT(txt)
  {

    let oInput = window.document.createElement('input');
    // oInput.type='textarea';
    oInput.value = txt;
    // oInput.innerHTML = txt;
    window.document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    window.document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display='none';

    window.getSelection().removeAllRanges(); // 移除选中的元素
  }

  static encodeWeekName(str){
    //替换周报里面的 - 和 /
    return str.replace("-", 'B').replace("/", 'A').replace("~",'C');
  }
  static decodeWeekName(str){
    //反替换周报里面的 - 和 / 解析成我的规则是 Y-m-d~Y-m-d
    return str.replace("B", '-').replace("A", '/').replace("C",'~');
  }

  //字符串格式化为用逗号分割千位
  static formatCashStr(currentValue)
  {// copilot写的
    // 为数值 currentValue 格式化为用逗号分割千位
    var currentValue = currentValue.toFixed(0);
    var currentValue = currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return currentValue;
  }
}