import {setUserSearchList} from "../actions";
import Facade from "../Facade";

/** 加载一次默认的空搜索（全部人的结果） */
export default function runSearchAllUsers(dispatch){
  Facade.gw.getUserSearch("" ,(voArr)=>{
/*
*  [
        {
            "id": 1,
            "openid": "ou_7c71eb56c6dfaee5f74fc150235e8adb",
            "tenantKey": "2d5150c6258f175d",
            "nickName": "柳闽泉",
            "nickNamePinyin": "liuminquan",
            "avatarUrl": "https://s1-imfile.feishucdn.com/static-resource/v1/v2_662f5bab-737c-4867-b13e-f71f00f07c5g~?image_size=72x72&cut_type=&quality=&",
            "gender": 1,
            "phone": "",
            "isResponsiblePerson": 1,
            "registerTime": "2021-05-13 16:41:56"
        }
    ]*/
    dispatch(setUserSearchList(voArr));
  });
}
