import customers from "./customers";
import {combineReducers} from "redux";
import admin from "./admin";
import login from "./login";
import stats from "./stats";
import weekReports from "./weekReports";
import weekReportTasks from "./weekReportTasks";
import projects from "./projects";
import general from "./general";
import recentResults from "./recentResults";
import dailyReports from "./dailyReports";
import dailyReportDetails from "./dailyReportDetails";
import users from "./users";
import projectMembers from "./projectMembers";


const reducer = combineReducers({users,dailyReports,dailyReportDetails,recentResults,general,projects,weekReportTasks ,weekReports,customers , admin,stats,login ,projectMembers});

export default reducer;