/**
 * 近期成果 展示UI
 *  首次数据由外部请求，后续数据由redux管理
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Button, Layout, Input, Tabs, Checkbox, Dropdown, Dialog, Form, Message, MessageBox} from "element-react";
import 'element-theme-default';
import {connect} from "react-redux";
import {customerUpdate, loginLogin, setAdminList} from "../actions";
import Facade from "../Facade";
import recentResults from "../reducers/recentResults";
import runRefreshRecentResults from "../controllers/runRefreshRecentResults";
import "./RecentResultsUI.css";
import ProjectCreate from "./ProjectCreate";
import runRefreshProjectList from "../controllers/runRefreshProjectList";

const {Row, Col} = Layout;

class RecentResultsUI extends Component
{
  static propTypes = {
    weeklyId: PropTypes.number.isRequired,
    editable : PropTypes.bool.isRequired,
  }

  constructor(props)
  {
    super(props)
    this.state = {

      form: {
        name: "",
      },
      formRules: {
        name: {required: true, message: "请输入名称", trigger: "blur,change"}
      },
      resultActiveTab: "0"
    };
  }

  componentDidMount()
  {
  }

  render()
  {
    let {list1, list2 ,editable } = this.props;
    let {dialog ,dialogType} = this.state;
    let str = dialogType=='todo'?"近期计划":"近期成果";
    return (<div className="recentPanes">
      <Dialog
        title={"添加"+str}
        visible={dialog}
        onCancel={() => {
          this.setState({dialog: false});
        }}
      >
        <Dialog.Body>
          <Form ref="formAdd" model={this.state.form} rules={this.state.formRules} labelPosition="top">
            <Form.Item label="名称" prop="name">
              <Input type="text" name="name" value={this.state.form.name} onChange={this.onChange.bind(this, "name")}
                     placeholder={str}/>
            </Form.Item>
            <Button onClick={this.onSubmit.bind(this)} type={"primary"}>提交</Button>
          </Form>
        </Dialog.Body>
      </Dialog>

      {editable &&
        <div className="recentPanes_right">
          <Dropdown
            className="createBtn"
            menuAlign="end" menu={
            <Dropdown.Menu style={{width: "120px"}}>
              <Dropdown.Item index="3-1" command="done">添加近期成果</Dropdown.Item>
              <Dropdown.Item index="3-1" command="todo">添加近期计划</Dropdown.Item>
            </Dropdown.Menu>
          } onCommand={this.onCommand.bind(this)}>
            <Button type="primary"
                    size={"text"}
                    icon={"plus"}></Button>
          </Dropdown>
        </div>
      }


      <Tabs activeName={this.state.resultActiveTab}>
        <Tabs.Pane label="近期成果" name="0">
          {list1.map(this.renderLi1.bind(this))}
        </Tabs.Pane>
        <Tabs.Pane label="近期计划" name="1">
          {list2.map(this.renderLi2.bind(this))}
        </Tabs.Pane>
      </Tabs>

    </div>);
  }

  renderLi1(li)
  {
    let {editable} = this.props;
    return (
      <div className="chengguoLi" key={li.id} >
        <span>{li.resultPlan}</span>
        <div className="chengguoDate">{li.createTime}
          {editable &&
            <Button type="text" className="delBtn" onClick={this.onClickDelete.bind(this, li.id)} icon="delete"/>
          }
        </div>
      </div>
    )
  }

  renderLi2(li)
  {
    let {editable} = this.props;
    return (
      <div className="jihuaLi" key={li.id}>
        <Checkbox checked={li.tick == 1} disabled={!editable} onChange={this.onClickDone.bind(this,li.id)}/>
        {li.resultPlan}
        <div className="chengguoDate">{li.createTime}
          {editable && <Button type="text" className="delBtn" onClick={this.onClickDelete.bind(this,li.id)} icon="delete"/>}
        </div>
      </div>
    )
  }

  onClickDelete(id)
  {
    MessageBox.confirm('此操作将删除该项, 是否继续?', '提示', {
      type: 'warning'
    }).then(() => {
      Facade.gw.recentDelete(id ,(data)=>{
        Message({
          type: 'success',
          message: '删除成功!'
        });
        runRefreshRecentResults( this.props.weeklyId , this.props.dispatch);
      });
    }).catch(() => {
    });
  }
  onClickDone(id)
  {
    Facade.gw.recentEdit(id,() => {
      Message.success("计划已完成，移动到近期成果列表中");
      //刷新
      runRefreshRecentResults( this.props.weeklyId , this.props.dispatch);
    });
  }

  onCommand(command)
  {
    this.setState({
      dialogType: command,
      dialog: true
    });
    // console.log("RecentResultsUI/onCommand",command);

  }
  onChange(key ,val)
  {
    this.state.form[key] = val;
    this.forceUpdate();
  }
  onSubmit(){
    this.refs.formAdd.validate((valid) => {
      if ( valid ){
        let {name} = this.state.form;
        let type = this.state.dialogType == 'todo'?2 : 1;
        let resultActiveTab = this.state.dialogType == 'todo'?"1":"0";
        Facade.gw.postRecent(this.props.weeklyId,type , name,() => {
          Message.success("提交成功");
          //刷新
          runRefreshRecentResults( this.props.weeklyId , this.props.dispatch);
          //创建近期成果/近期计划后，显示对应页签内容。
          //
          this.setState({dialog: false,resultActiveTab});
          this.refs.formAdd.resetFields();
        });
      }
    });
  }
}

const mapStateToProps = (state) => {
  // console.log("loginPage/mapStateToProps",state.customers1.customerList);
  return {
    list1: state.recentResults.list1,
    list2: state.recentResults.list2
  }
};
// export default LoginPage;
export default connect(
  mapStateToProps
)(RecentResultsUI);